import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mylocalrx-contentcontainer-service',
  templateUrl: './mylocalrx-contentcontainer-service.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-service.component.scss']
})
export class MylocalrxContentcontainerServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
