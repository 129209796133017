import { DatePipe } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { de, th, tr } from 'date-fns/locale';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DynamicConditionValidationService } from 'src/app/services/dynamic-condition-validation.service';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { Appointment } from 'src/app/shared/models/Appointment';
import { Pharmacy } from 'src/app/shared/models/pharmacy.model';
import { PharmacyScheduleResponseForClient } from 'src/app/shared/models/PharmacyScheduleResponseForClient.model';
import { Address } from 'src/app/shared/models/prescription.model';
import { Questionnaire } from 'src/app/shared/models/QuestionnaireModel';
import { PharmacySchedule, Timeslot } from 'src/app/shared/models/schedual.modal';
import { User } from 'src/app/shared/models/user.model';
import { LocaltDateTimePipe } from 'src/app/shared/pipes/localt-date-time.pipe';
declare var $: any;
@Component({
  selector: 'app-covid-appointment',
  templateUrl:'./covid-appointment.component.html',
  styleUrls: ['./covid-appointment.component.scss']
})
export class CovidAppointmentComponent implements OnInit {
  isThisCovidService = false;
  currentPageURL = "";
  pharmacies: Pharmacy[] = [];
  services: PharmacySchedule[] = [];
  servicesForClient: PharmacyScheduleResponseForClient[] = [];
  appointments: any;
  currentUser = GlobalConstants.getcurrentUser();
  listUsers: User[] = [];
  qProgress = "0%";
  newAppointment: Appointment = {
    id: "",
    dateOfBirth: "",
    status: 0,
    "pharmacyID": "-1",
    "serviceID": "-1",
    note: "",
    patientID: this.currentUser?.id!,
    patientName: this.currentUser?.firstName + " " + this.currentUser?.lastName, timeslot: "",
    timeslotStrClient: "",
    phoneNumber: this.currentUser?.phoneNumber,
    slotName: "",
    appointment_type: '',
    appointment_note: "",
    appointment_status: "",
    appointment_mob: 0,
    minorAilment_type:"",
       address: {
      firstName: '',
      lastName: '',
      unitNo: '',
      buildingName: '',
      streetAddress: '',
      city: '',
      country: '',
      postalCode: ''
    },
    questionnaire: null,
   
  
  };
  selectedDateFor = "";
  isAddressNew = -1;
  addresses: Array<Address> | undefined;
  //selectedTimeSlot : string;

  waitingAppointmentList = null;
  appointmentid: any;
  selectedService: any ;
  selectedServiceCode: any;
  myAppointments2: any;
  appointmentForm: FormGroup ;
  appointment_note: any;
  appointment_type: any;
  appointment_mobileno: any;


  minorAilments=['Allergic Runny nose',
  'Oral thrush',
 ' Bacterial or viral Eye infections and allergic Itchy eyes',
  'Skin rashes (atopic, eczema, allergic and contact)',
  'Menstrual cramps',
 'Heartburn',
  'Hemorrhoids',
  'Cold sores',
  'Impetigo',
  'Insect bites and hives',
 ' Tick bites, post-exposure prophylaxis to prevent Lyme disease',
  'Musculoskeletal sprains and strains',
  'Urinary tract infections (uncomplicated)',]
  selectedMinorAilmentService: number = 0;


  constructor(private pharmacyService: PharmacyService,public fb: FormBuilder,
    private appointmentService: AppointmentService, private datePipe: DatePipe, private userService: AuthenticationService, private localtDateTimePipe: LocaltDateTimePipe, private zone: NgZone, private router: Router,
    private csValidation: DynamicConditionValidationService, private activatedRoute: ActivatedRoute) {

     
    this.appointmentForm = this.fb.group({
      appointment_type: ['Physical Visit', Validators.required],
      appointment_note: ['', Validators.required],
      appointment_mobileno: ['', Validators.required]
    });

    this.pharmacies = [];
    $('.csLoader').css({ display: 'flex' });
    var dt = new Date();
    dt.setHours(0, 0, 0, 0);
    var serviceType = this.getServiceTypeFromUrl();
    // console.log("serviceTYpe",serviceType);
    this.appointmentService.getAllScheduleWithPharmacy(serviceType).subscribe(ph => {
      this.servicesForClient = ph;
      ph.forEach(element => {
        element.startDate = new Date(element.startDate);
        element.endDate = new Date(element.endDate);
        // if(serviceType == 7){
        //   element.serviceName = element.serviceName.toUpperCase() +'-' + this.selectedService;
        //   console.log('fff',element.serviceName)
        // }
        // else{
          // this.selectedService=null;
          element.serviceName = element.serviceName.toUpperCase();
        // }
        // console.log('serviceName Appointments',element.serviceName,element.serviceType)
        if (!this.pharmacies.find(x => x.id == element.pharmacyID) && dt <= element.endDate) {
          this.pharmacies.push(element.pharmacy);
        }

      });
      $('.csLoader').css({ display: 'none' });
      this.pharmacies = this.pharmacies.filter(x => x.ownerid);
      this.pharmacies = this.pharmacies.filter(x => x.appointmentModuleEnabled == true);
      var preferredPharmacy = ph.find(x => x.id == this.currentUser?.preferredPharmacy);
      if (preferredPharmacy) {
        this.newAppointment.pharmacyID = preferredPharmacy.id;
        this.newAppointment.serviceID = "-1";
        this.pharmacyChange();
      }
    }, error => {
      $('.csLoader').css({ display: 'none' });
    });


    var that = this;
    $(document).ready(function () {
      $('#datepicker').datepicker().on("changeDate", function (e: any) {
        that.zone.run(x => {
          that.selectedDateFor = that.datePipe.transform(e.date, "MM/dd/yyyy")!;
          that.serviceChange(true);
        })
      });
    });
    this.selectedDateFor = this.datePipe.transform(new Date(), "MM/dd/yyyy")!;
    // this.appointmentService.getMyWaitingList(this.currentUser?.id!).subscribe(x => {

    //   this.waitingAppointmentList = x;
    //   if (x.length == 0) {
    //     this.waitingAppointmentList = null;
    //   }
    // });
  }
  myAppointments: Appointment[] = [];
  ngOnInit(): void {
    this.appointmentForm = this.fb.group({
      appointment_type: ['Physical Visit', Validators.required],
      appointment_note: ['', Validators.required],
      appointment_mobileno: ['', Validators.required]
    });

    this.currentPageURL = (window.location.href).toString();
    if ( this.currentPageURL != undefined ) {
      let serviceNameParam = this.currentPageURL.split('?')[1];
      if (serviceNameParam != undefined) {
        let serviceName = serviceNameParam.split('=')[1];
        if (serviceName != "" || serviceName != undefined) {
          this.selectedServiceCode=serviceName;
        }
        else {
          console.log("Split conflict at second split with service URL");
          this.selectedServiceCode=localStorage.getItem("selectedService");
        }
      }
      else {
        console.log("Split conflict at first split with service URL");
        this.selectedServiceCode=localStorage.getItem("selectedService");
      }
    } 
    else {
      this.selectedServiceCode=localStorage.getItem("selectedService");
    }

    console.log("Service Code: ")
    console.log(this.selectedServiceCode);
    
    if ( this.selectedServiceCode == "ARN") {
      this.selectedService = "Allergic Runny nose";
    }
    else if ( this.selectedServiceCode == "OTH") {
      this.selectedService = "Oral thrush";
    }
    else if ( this.selectedServiceCode == "BAC") {
      this.selectedService = "Bacterial";
    }
    else if ( this.selectedServiceCode == "SKR") {
      this.selectedService = "Skin rashes";
    }
    else if ( this.selectedServiceCode == "MNP") {
      this.selectedService = "Menstrual cramps";
    }
    else if ( this.selectedServiceCode == "HBN") {
      this.selectedService = "Heartburn";
    }
    else if ( this.selectedServiceCode == "HEM") {
      this.selectedService = "Hemorrhoids";
    }
    else if ( this.selectedServiceCode == "CLD") {
      this.selectedService = "Cold sores";
    }
    else if ( this.selectedServiceCode == "IMP") {
      this.selectedService = "Impetigo";
    }
    else if ( this.selectedServiceCode == "INS") {
      this.selectedService = "Insect bites and hives";
    }
    else if ( this.selectedServiceCode == "TBT") {
      this.selectedService = "Tick bites";
    }
    else if ( this.selectedServiceCode == "MUS") {
      this.selectedService = "Musculoskeletal sprains";
    }
    else if ( this.selectedServiceCode == "UTI") {
      this.selectedService = "Urinary tract infections";
    }
    else {
      this.selectedService = this.selectedServiceCode;
    }
    console.log("Service: ")
    console.log(this.selectedService);

    this.userService.getUserAddresses(GlobalConstants.getcurrentUser()?.id!).subscribe(data => {
      this.addresses = data ? data.address : null;
      if (this.addresses != null && this.addresses?.length! > 3) {
        this.addresses = this.addresses.slice(0, 3);
      }
    });

    this.appointmentService.getMyAppoinments(this.currentUser?.id!).subscribe(x => {

    
      x.forEach(element => {
        element.timeslot = this.localtDateTimePipe.transform(element.timeslot);
         

        //  this.myAppointments2=[{timeslot:element.timeslot,address:element.address,slotName:element.slotName,note:element.note,status:element.status}];
      });

      var date = new Date();
      date.setHours(0, 0, 0, 0);
    
  //  this.myAppointments2 =   x.forEach(element => {

  //   if(this.selectedService != 'Covid Vaccine' && this.selectedService != 'Shingrix Vaccine' && this.selectedService != 'Cannabis Consultation' 
  //     && this.selectedService != 'Flu Shot' ){

  //         var a = element.slotName;        
  //         var b = this.selectedService +" - ";
  //         var position = 16;
  //         var output = [a.slice(0, position), b, a.slice(position)].join('');
  //         console.log(output)
  //         element.slotName = output;
  //         return element.slotName;
  //  }

  //  else{
  //   return element.slotName;
  //  }

  //     });
//  }    
      this.myAppointments= x.filter(d => d.timeslot > date);
      this.myAppointments = this.myAppointments.sort((a: { timeslot: { getTime: () => number; }; }, b: { timeslot: { getTime: () => number; }; }) => {
        return a.timeslot.getTime() - b.timeslot.getTime()
      });
      console.log(this.myAppointments)
    });
    this.getRelativeUsers();
  }
  timeslots: Timeslot[] = [{} as Timeslot];
  showTable = false;


  private RendData() {
    var currentDt = new Date();
    currentDt.setMinutes(currentDt.getMinutes() + 30);
    var splitDate = this.selectedDateFor.split("/");
    var dt = new Date(parseInt(splitDate[2]), parseInt(splitDate[0]) - 1, parseInt(splitDate[1]));

    var se = this.services.find(x => x.id == this.newAppointment.serviceID)!;
    console.log("se==",se)
    if (se) {

      se.daysOfTheWeek.find(x => x.day == dt.getDay())?.timeSlots.forEach(slot => {
        var time = slot.startTime.split(":");
        dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(time[0]), parseInt(time[1]), parseInt(time[2]));
        var running = true;
        slot.data = [{}];
        while (running) {
          var isActive = se.daysOfTheWeek.find(dow => dow.day == dt.getDay())?.active!;

          if (currentDt.getTime() > dt.getTime()) {
            isActive = false;
          }

          slot.data.push({ date: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds()), booked: false, isMe: false, isActive: isActive });
          dt.setMinutes(dt.getMinutes() + se.slotDuration!);
          var end = this.datePipe.transform(dt, "HH:mm:ss");
          running = !(parseInt(end!.replace(/:/g, '')) >= parseInt(slot.endTime.replace(/:/g, '')));
        } slot
          .data.splice(0, 1);
      });
    }
  }


  // myFunction() {
  //   document.getElementById("demo").innerHTML = "I have changed!";
  // }
  pharmacyChange() {
    // $('.csLoader').css({ display: 'flex' });
    this.newAppointment.serviceID = "-1";
    this.newAppointment.slotName = "-1";
    this.timeslots = [];

    this.services = this.servicesForClient.filter(x => x.pharmacyID == this.newAppointment.pharmacyID);
    console.log("services=",this.services)

    // this.appointmentService.getScheduleForPharmacy(this.newAppointment.pharmacyID).subscribe(sch => {
    //   $('.csLoader').css({ display: 'none' });
    //   sch.forEach(s => {
    //     s.startDate = new Date(s.startDate);
    //     s.endDate = new Date(s.endDate);
    //     s.serviceName = s.serviceName.toUpperCase();
    //     s.startDate.setHours(0, 0, 0, 0);
    //   });
    //   var dt = new Date();
    //   dt.setHours(0, 0, 0, 0);
    //   this.services = sch.filter(d => dt >= d.startDate && dt <= d.endDate);
    // }, error => {
    //   $('.csLoader').css({ display: 'none' });
    // });
  }
  // slotChange() {
  //   this.selectedDate = new Date(2000, 1, 1);
  //   this.selectedDateFor = this.datePipe.transform(new Date(), "MM/dd/yyyy")!;
  // }
  showWaitingButton = false;
  listQuestionnaire: Questionnaire[] = [];
  serviceChange(dateChanged: boolean = false) {
    this.newAppointment.timeslot = "";
    if (this.newAppointment.pharmacyID == "-1" || this.newAppointment.serviceID == "-1") {
      this.selectedDateFor = this.datePipe.transform(new Date(), "MM/dd/yyyy")!;
      return;
    }
    var se = this.services.find(x => x.id == this.newAppointment.serviceID)!;
    console.log("se=",se,se.serviceType);
    this.selectedMinorAilmentService = se.serviceType;
    if (!dateChanged) {
      this.isThisCovidService = false;
      if (se) {
        this.isThisCovidService = se.serviceType == 0;
      }
      this.newAppointment.slotName = "-1";

      var questionnaireId = this.getQuetionaireId(se);
      debugger;
      this.appointmentService.getQuestionnaire(questionnaireId).subscribe(qst => {
        this.listQuestionnaire = qst.filter(qs => qs.isActive).sort((a, b) => { return a.sequence - b.sequence });
      }, err => {
        this.listQuestionnaire = [];
      });
    }
    this.selectedDate = new Date(2000, 1, 1);
    if (this.selectedDateFor) {
      //this.newAppointment.timeslot = "";
      // if (!dateChanged) {
      //this.newAppointment.slotName = "-1";
      //}
      $('.csLoader').css({ display: 'flex' });
      this.RendData();
      console.log(se);
      var endDate = this.localtDateTimePipe.transform(se?.endDate);
      var startDate = this.localtDateTimePipe.transform(se?.startDate);
      console.log(startDate);
      console.log(endDate);
      if (dateChanged == false) {
        $("#datepicker").datepicker('setStartDate', startDate);
        $("#datepicker").datepicker('setEndDate', endDate);
      }
      var disabled = se?.daysOfTheWeek.filter(x => x.active == false)!;

      $(".olDisabled").removeClass("disabled olDisabled");
      var tbody = $("#datepicker").find('tbody')[0];
      $(tbody).find('tr').each((e: any) => {
        var tr = $(tbody).find('tr')[e];
        var tds = $(tr).find("td");
        for (var i = 0; i < tds.length; i++) {
          if (disabled.find(x => x.day == i)) {
            if ($(tds[i]).hasClass("disabled") == false) {
              $(tds[i]).addClass("disabled olDisabled");
            }
          }
        }
      });

      var splitDate = this.selectedDateFor.split("/");
      var dt = new Date(parseInt(splitDate[2]), parseInt(splitDate[0]) - 1, parseInt(splitDate[1]));

      var currentDay = se.daysOfTheWeek.find(x => x.day == dt.getDay());
      this.timeslots = currentDay?.timeSlots!;
      this.timeslots.forEach(element => {
        element.isActive = currentDay?.active!;
        if (element.isActive) {
          element.isActive = dt >= startDate && dt <= endDate;
        }
      });


      var oldTimeSlot = "-1";
      if (this.newAppointment.slotName != "-1" && dateChanged) {
        var ff = this.timeslots.find(x => x.timeSlotName == this.newAppointment.slotName);
        if (ff) {
          oldTimeSlot = ff.timeSlotName;
        }
        else if (this.timeslots.length > 0) {
          oldTimeSlot = this.timeslots[0].timeSlotName;
        }
        this.newAppointment.slotName = "-1";
      }

      setTimeout(() => {
        this.newAppointment.slotName = oldTimeSlot;
      }, 500);

      this.showTable = true;

      // this.newAppointment.pharmacyID = se?.pharmacyID!;
      this.appointmentService.getAppointmentForDay(this.newAppointment, this.selectedDateFor).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.timeslots.forEach(slot => {
          slot.data.forEach(d => {
            d.booked = startDate.getTime() > d.date.getTime();
            d.isMe = false;
          });

          if (x && x.length > 0) {
            //var pre = x.filter(p => p.slotName.toLocaleLowerCase() == slot.timeSlotName.toLocaleLowerCase());
            var pre = x;
            pre.forEach(p => {
              var booked = slot.data.find(d => this.datePipe.transform(d.date, "MM/dd/yyyy HH:mm:ss") == this.datePipe.transform(this.localtDateTimePipe.transform(p.timeslot), "MM/dd/yyyy HH:mm:ss"));
              if (booked && p.status == 0) {

                booked.booked = true;
                booked.isMe = this.listUsers.find(u => u.id == p.patientID) ? true : false;
              }
            });
          }
          debugger;
          slot.allBooked = slot.data.filter(x => x.booked == false && x.isActive == true).length == 0;
        });
        this.showWaitingButton = this.timeslots.filter(x => x.allBooked == false).length == 0;
      }, error => {
        $('.csLoader').css({ display: 'none' });
      });
    }
  }
  errorMsg = "";
  successMessage = "";


  save(addressForm: NgForm) {
    debugger;
    this.errorMsg = "";
    this.successMessage = "";
    // upload prescription file first
    if(this.selectedMinorAilmentService == 7){
      this.newAppointment.appointment_note =  this.appointmentForm.get('appointment_note')?.value ;
    
      this.newAppointment.appointment_status = 'pending';
      this.newAppointment.minorAilment_type = this.selectedService;
      this.newAppointment.appointment_type = this.appointmentForm.get('appointment_type')?.value ;
      // console.log(this.appointment_type);
      this.newAppointment.appointment_mob=  this.appointmentForm.get('appointment_mobileno')?.value;
      }
      else{
        this.newAppointment.appointment_note = '' ;
    
      this.newAppointment.appointment_status = '';
      this.newAppointment.minorAilment_type = '';
      this.newAppointment.appointment_type = '' ;
      // console.log(this.appointment_type);
      this.newAppointment.appointment_mob;
      }
    if (addressForm.valid) {
     
     
      if (this.newAppointment.timeslot) {
        console.log(this.newAppointment.timeslot)
        this.fillFirstAndLastName();
        if (this.listQuestionnaire.length > 0) {
          this.newAppointment.questionnaire = [];
          this.listQuestionnaire.forEach(element => {
            this.newAppointment.questionnaire.push({
              questionId: element.id, answer: element.answer
            });
          });
        }
        else {
          this.newAppointment.questionnaire = null;
        }
        $('.csLoader').css({ display: 'flex' });
        console.log("newap=",this.newAppointment);
        this.appointmentService.save(this.newAppointment,
          this.activatedRoute.snapshot.params.id == "asymptomatic-test" ? this.createCardiaObject(this.newAppointment) : null, this.autoBookingRequired).subscribe(result => {

    
    if(this.selectedMinorAilmentService == 7){
            this.appointmentService.addStatusofBookedMinorailments(result.data.id,'pending',this.newAppointment.patientID,this.newAppointment.serviceID,this.newAppointment.pharmacyID).subscribe(resultData => {
              console.log('result addStatusofBookedMinorailments=', resultData);
          })
        }
            $('.csLoader').css({ display: 'none' });
            $("#appointmentAddress").modal('toggle');
            if (result.data) {
              this.successMessage = ` Hi <b> ${this.newAppointment.patientName}</b> your appointment has been
                scheduled.`;
              if (this.getServiceTypeFromUrl() == 4) {
                this.successMessage += `<br/><br/>Thank you for your making an appointment, a member of the pharmacy team will call you with in 4 hour for an assessment and to advise you of the time of your test if necessary.`
              }
              $("#appointmentSuccess").modal('toggle');
            }
            else {
              this.errorMsg = result.errorMessage;
              $("#appointmentError").modal('toggle');
            }
          }, error => {
            $('.csLoader').css({ display: 'none' });
          });
      }
    } else {
      var firstInvalid = false;
      for (const field in addressForm.form.controls) {
        const control = addressForm.form.get(field);
        if (control?.invalid && !firstInvalid) {
          control.markAsTouched();
          $('#' + field).focus();

          firstInvalid = true;
        }
      }
    }
  }
  selectedDate: Date = new Date(2000, 1, 1);
  nexDate: string = "";
  selectedSlotIndex = -1;
  selectTimeSlot(timeSlotName: string, timeString: Date, slIndex: number) {
    //this.selectedTimeSlot = timeString;
    this.selectedSlotIndex = slIndex;
    this.selectedDate = timeString;
    this.newAppointment.slotName = timeSlotName;
    this.newAppointment.timeslot = this.datePipe.transform(new Date(timeString.getTime() + (timeString.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS")!;
    this.newAppointment.timeslotStrClient = this.datePipe.transform(new Date(timeString.getTime()), "MM/dd/yyyy HH:mm")!;
    var dt = new Date(timeString.getTime());
    dt.setDate(dt.getDate() + 112);
    this.nexDate = this.datePipe.transform(new Date(dt.getTime() + (timeString.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS")!;
  }
  refresPage() {
    this.nexDate = "";
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }

  ShowAddressModal() {
    var d = this.autoBookingRequired;
    debugger;
    $("#appointmentAddress").modal('toggle');
  }
  ShowQuestionModal() {
    if (this.listQuestionnaire.length > 0) {
      this.questionNumber = 0;
      this.qProgress = "0%";
      this.shouldContinueFromQuestion = false;
      this.showAssessemtError = false;
      this.listQuestionnaire.forEach(ele => {
        ele.answer = "";
      });
      $("#questionModal").modal('toggle');
    }
    else {
      this.autoBookingRequired = false;
      this.ShowAddressModal();
    }
  }
  autoBookingRequired = true;
  autoBookingMessage = '';
  ShowAutobooking() {
    this.autoBookingRequired = false;
    /// remove second dose booking.........
    // this.autoBookingMessage = 'Do you want auto booking for <b>second dose</b> after 112 days?';
    // var dose = this.listQuestionnaire.length > 0 ? this.listQuestionnaire.filter(x => x.question.toLocaleLowerCase().indexOf("first or second dose") > -1) : null;
    // if (dose && dose.length > 0 && dose[0].answer == "First") {
    //   var se = this.services.find(x => x.id == this.newAppointment.serviceID)!;
    //   if (se.serviceName.indexOf("PFIZER") > -1) {
    //     this.autoBookingMessage = 'Do you want auto booking for <b>second dose</b> after 21 days?';
    //   }
    //   else if (se.serviceName.indexOf("MODERNA") > -1) {
    //     this.autoBookingMessage = 'Do you want auto booking for <b>second dose</b> after 28 days?';
    //   } 
    //    else if (se.serviceName.indexOf("ASTRA") > -1) {
    //     this.autoBookingMessage = 'Do you want auto booking for <b>second dose</b> after 112 days?';
    //   }


    //   $("#autoBookingModal").modal('toggle');
    // }
    // else {
    //   this.ShowAddressModal();
    // }
    this.ShowAddressModal();
  }
  shouldContinueFromQuestion = false;
  showAssessemtError = false;
  questChange(moveNext: boolean) {
    // var fails = this.questions.filter(x => x.a == "" || x.a == "Yes");
    // var yes = this.questions.filter(x => x.a == "Yes");
    this.showAssessemtError = false;
    this.shouldContinueFromQuestion = this.listQuestionnaire.filter(x => x.answer == "" && x.isMandatory).length == 0;

    if (this.questionNumber == this.listQuestionnaire.length - 1) {
      this.showAssessemtError = this.listQuestionnaire.filter(x => x.answer == "" && x.isMandatory).length > 0;
    }
    if (this.questionNumber < this.listQuestionnaire.length - 1 && moveNext) {
      setTimeout(() => {

        this.moveQuestion(1);
      }, 500);
    }
  }
  questionNumber = 0;
  moveQuestion(num: number) {
    this.showAssessemtError = false;


    this.questionNumber += num;
    this.qProgress = ((this.questionNumber / (this.listQuestionnaire.length - 1)) * 100) + '%';
  }
  AddressChanged() {
    if (this.isAddressNew > -1) {
      this.newAppointment.address = this.addresses![this.isAddressNew];
    }
    else {
      this.newAppointment.address = {
        firstName: '',
        lastName: '',
        unitNo: '',
        buildingName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: ''
      };
    }
    this.fillFirstAndLastName();
  }
  patientChange() {
    this.fillFirstAndLastName();
  }
  fillFirstAndLastName() {
    var user = this.listUsers.find(x => this.newAppointment.patientName.toLowerCase().indexOf(x.firstName.toLowerCase()) > -1 && this.newAppointment.patientName.toLowerCase().indexOf(x.lastName.toLowerCase()) > -1);
    this.newAppointment.address.firstName = user?.firstName;
    this.newAppointment.address.lastName = user?.lastName;
    this.newAppointment.patientID = user?.id!;
    this.newAppointment.dateOfBirth = user?.dateOfBirth!;
  }
  cancelId = "";
  cancel(id: string) {
    this.cancelId = id;
    $("#cancelBookingModal").modal('toggle');
  }
  cancelBooking() {
    $('.csLoader').css({ display: 'flex' });
    this.appointmentService.cancelBooking(this.cancelId).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      this.cancelId = "";
      this.refresPage();
    }, error => {
      $('.csLoader').css({ display: 'none' });
    });
  }

  private getRelativeUsers() {
    this.listUsers = [];
    this.userService.getCurrentUserById(this.currentUser?.id).subscribe(u => {

      this.listUsers.push(u);
      this.userService.findByParentId(this.currentUser?.id).subscribe(x => {
        this.listUsers.push(...x);
      });
      this.fillFirstAndLastName();

    });
  }

  underAgeError = "";
  validateAgeLimit() {
    this.errorMsg = "";
    ///to check valid slot
    var se = this.services.find(x => x.id == this.newAppointment.serviceID)!;
    var splitDate = this.selectedDateFor.split("/");
    var dt = new Date(parseInt(splitDate[2]), parseInt(splitDate[0]) - 1, parseInt(splitDate[1]));


    var endDate = this.localtDateTimePipe.transform(se?.endDate);
    var startDate = this.localtDateTimePipe.transform(se?.startDate);
    var currentDay = se.daysOfTheWeek.find(x => x.day == dt.getDay());
    /*if (dt < startDate || dt > endDate || !currentDay?.active) {
      this.errorMsg = "Appointment is not available ";
      if (dt < startDate) {
        this.errorMsg = this.errorMsg + "before " + this.datePipe.transform(startDate, "MMM-dd-yyyy");
      }
      else if (dt > startDate) {
        this.errorMsg = this.errorMsg + "after " + this.datePipe.transform(endDate, "MMM-dd-yyyy");
      }
      else if (!currentDay?.active) {
        this.errorMsg = this.errorMsg + "for " + this.datePipe.transform(dt, "MMM-dd-yyyy");
      }
      $("#appointmentError").modal('toggle');
      return;
    }*/
    ///to check valid slot


    this.underAgeError = "";
    var user = this.listUsers.find(x => this.newAppointment.patientName.toLowerCase().indexOf(x.firstName.toLowerCase()) > -1 && this.newAppointment.patientName.toLowerCase().indexOf(x.lastName.toLowerCase()) > -1);
    if (user?.dateOfBirth) {
      this.underAgeError = this.csValidation.validateCondition(se.conditions, { age: user?.dateOfBirth, gender: user.sex == 0 ? "male" : "female" });
      // var dt1 = new Date(parseInt(mdy[2]), (parseInt(mdy[0]) - 1), parseInt(mdy[1]), 23, 59, 59);
      // var currentDate = new Date(this.selectedDate.getTime())
      // currentDate.setHours(23, 59, 59);

      // dt1.setFullYear(dt1.getFullYear() + 11);
      // this.underAgeError = dt1 <= currentDate ? "" : `<b>${user.firstName} ${user.lastName}</b>  is under age as per government guidelines for Covid Vaccination, age must be more or equal to <b>18</b> years.`;
      // var service = this.services.find(x => x.id == this.newAppointment.serviceID)?.serviceName!;
      // if (service.toLocaleLowerCase().indexOf("test") > -1) {
      //   this.underAgeError = "";
      // }
      console.log(this.underAgeError);
    }

    if (this.underAgeError) {
      $("#underAgeErrorModal").modal('toggle');
    }
    else {
      this.ShowQuestionModal();
    }
  }
  bookWaitingList() {
    $("#watingConfirmation").modal('toggle');
  }
  continueWaitingList() {
    this.appointmentService.addWaitingList(this.newAppointment.pharmacyID, this.newAppointment.patientID,
      this.newAppointment.serviceID, this.currentUser?.id!).subscribe(x => {
        console.log(x);
        $("#waitingSuccess").modal('toggle');
      });
  }
  googleAddressSelected(event: Address) {
    this.zone.run(() => {
      this.newAppointment.address.unitNo = event.unitNo;
      this.newAppointment.address.streetAddress = event.streetAddress;
      this.newAppointment.address.postalCode = event.postalCode;
      this.newAppointment.address.city = event.city;
    });
  }



  createCardiaObject(app: Appointment): any {
    var selectedPatient = this.listUsers.find(x => x.id == app.patientID)!;
    var dob = selectedPatient.dateOfBirth;
    if (!dob) {
      dob = "01/01/1980";
    }
    if (!selectedPatient.email) {
      selectedPatient.email = this.currentUser?.email;
    }
    if (selectedPatient.email && selectedPatient.email.indexOf("@") < 0) {
         selectedPatient.email = "info@mylocalrx.ca";
    }
    //age: this.selectedPatient.dateOfBirth,
    var passport_no = this.listQuestionnaire.find(x => x.question.toLowerCase().indexOf("passport number") > -1)!.answer;
    var nationality = this.listQuestionnaire.find(x => x.question.toLowerCase().indexOf("nationality") > -1)!.answer;
    var data = {
      "basicInfo": {
        "appointment_date": this.datePipe.transform(new Date(this.selectedDate.getTime() + (this.selectedDate.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'"), // "2021-09-22",
        "test_type": "031217a0-2ea3-11eb-81d1-314696e9bed3",
        "adult": "1",
        "children": "0",
        "booking_time": this.datePipe.transform(new Date(this.selectedDate.getTime()), "hh:mm"),
      },
      "userInfo": {
        "adult": [
          {
            "first_name": app.address.firstName,
            "last_name": app.address.lastName,
            "gender": selectedPatient.sex == 0 ? 'male' : 'female',
            "date": dob.split('/')[1],
            "month": dob.split('/')[0],
            "year": dob.split('/')[2],
            "address": app.address.streetAddress,
            "province": "",
            "email": selectedPatient.email ? selectedPatient.email : "info@mylocalrx.ca",
            "phone": app.phoneNumber,
            "phn": null,
            "nationality": nationality,
            "passport_no": passport_no,
            "test_reason": "",
            "test_reason_type": ""
          }
        ],
        "child": [

        ]
      },
      "billing": {
        "first_name": app.address.firstName,
        "last_name": app.address.lastName,
        "address": app.address.streetAddress,
        "city": app.address.city,
        "province": "",
        "postal_code": app.address.postalCode ? app.address.postalCode : "2",//this.data.address2.postalCode,
        "phone": app.phoneNumber,
        "email": selectedPatient.email ? selectedPatient.email : "info@mylocalrx.ca",
        "payment_type": "cash",
        "amount": 10,

      }
    };

    return data;
  }

  getServiceTypeFromUrl(): number {
    var serviceType = 2;
    switch (this.activatedRoute.snapshot.params.id) {
      case "vaccination": serviceType = 0; break;
      case "asymptomatic-test": serviceType = 1; break;
      case "symptomatic-test": serviceType = 4; break;
      case "flushot": serviceType = 3; break;
      case "shingrix": serviceType = 5; break;
      case "cannabis": serviceType = 6; break;
      case "minorAilments": serviceType = 7; break;
      // case "Bacterial": serviceType = 8; break;
      // case "Insect": serviceType = 9; break;
      // case "Cold-sores": serviceType = 10; break;
      // case "Impetigo": serviceType = 11; break;
      // case "Heartburn": serviceType = 12; break;
      // case "Hemorrhoids": serviceType = 13; break;
      // case "Tick-bites": serviceType = 14; break;
      // case "Skin-rashes": serviceType = 15; break;
      // case "Oral-thrush": serviceType = 16; break;
      // case "Menstrual-cramps": serviceType = 17; break;
      // case "Musculoskeletal": serviceType = 18; break;
      // case "Urinary-tract-infections": serviceType = 19; break;
    
      default: serviceType = 2; break;
    }
    return serviceType;
  }

  private getQuetionaireId(se: any): string {
    var id = se.serviceId;
    switch (se.serviceType) {
      case 0: id = "covid"; break;
      case 1: id = "covid_asymptomatic_test"; break;
      case 4: id = "covid_symptomatic_test"; break;
    }
    return id;
    // return se.serviceType == 0 ? "covid" : (se.serviceType == 1 ? "covid_asymptomatic_test" : se.serviceId);
  }

}
