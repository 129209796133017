<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-1">
        <!-- Image -->
        <img
          src="./../../assets/svg/img 6.svg"
          class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..."
          data-aos="fade-up"
          data-aos-delay="100"
          width="100%"
          height="100%"
        />
      </div>
      <div
        class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate"
        data-aos="fade-up"
      >
        <h3 class="py-4">Services</h3>
        <p class="lead">Our group of pharmacies offer</p>
        <p class="lead">
          Sesonal flu shot vaccination Upcoming covid vaccination ( subject to
          availability and approval) Ideal protein weight loss consult
          Medication reviews Diabetes review by our Certified diabetes educator
          Covid testing for travel purpose Custom compounding of medications
        </p>
        <ul class="list-group mb-5">
          <li class="list-group-item list-group lead fw-bold">
            1. Seasonal Flu Shot Vaccination
          </li>
          <li class="list-group-item list-group lead fw-bold">
            2. Upcoming Covid vaccination (subject to availability)
          </li>
          <li class="list-group-item list-group lead fw-bold">
            3. Ideal Protein Weight Loss Consult
          </li>
          <li class="list-group-item list-group lead fw-bold">
            4. Medication Reviews
          </li>
          <li class="list-group-item list-group lead fw-bold">
            5. Diabetes Review by our Certified Diabetes Educator
          </li>
          <li class="list-group-item list-group lead fw-bold">
            6. Covid Testing for Travel Purposes
          </li>
          <li class="list-group-item list-group lead fw-bold">
            7. Custom Compounding of Medications
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
