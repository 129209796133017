import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DeSanitizeMobileNumber } from 'src/app/directives/DeSanitizeMobileNumber';
import { DeSanitizeOHIPNumber } from 'src/app/directives/DeSanitizeOHIPNumber';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
declare var $: any;

declare var Compress: any;
@Component({
  selector: 'app-add-new-member',
  templateUrl: './add-new-member.component.html',
  styleUrls: ['./add-new-member.component.scss']
})
export class AddNewMemberComponent implements OnInit {
  compress = new Compress();
  currentUser = GlobalConstants.getcurrentUser();
  @Output() dataSavedEvent = new EventEmitter();
  @Input() newUser: any = null;
  constructor(private deSanitizeMobileNumber: DeSanitizeMobileNumber, private deSanitizeOHIPNumber: DeSanitizeOHIPNumber, private authenticationService: AuthenticationService) {
   
  }

  ngOnInit(): void {

    $("#ohipFile").fileinput({
      showCaption: false,
      dropZoneEnabled: false,
      showUpload: false,
      maxFileCount: 5,
      allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],    // allow only images
      showCancel: false,
      initialPreviewAsData: true,
      browseLabel: 'Upload OHIP...',
      msgFileRequired: 'You must select OHIP Card copy to upload here.',
      msgFilesTooMany: "You can upload only 5 images"
    });
    $("#insuranceFile").fileinput({
      showCaption: false,
      dropZoneEnabled: false,
      showUpload: false,
      maxFileCount: 6,
      allowedFileExtensions: ['jpg', 'jpeg', 'png', 'tiff', 'pdf'],    // allow only images
      showCancel: false,
      initialPreviewAsData: true,
      browseLabel: 'Upload Insurance Card...',
      msgFileRequired: 'You must select insurance card copy to upload here.',
      msgFilesTooMany: "You can upload only 6 images"
    });
  }


  ohipFiles: any[] = [];
  insuranceFiles: any[] = [];
  fileChangeEvent(event: any, id: string) {
    if (event.target.files) {
      const files1 = [...event.target.files];
      console.log(files1);
      this.compress.compress(files1, {
        size: 4,
        quality: .75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      }).then((data: any[]) => {
        console.log(data);
        var newFiles: any = [];
        data.forEach(element => {
          var nm = new Date().getTime() + "." + element.ext.split('/')[1];
          var baseString = element.data;
          if (baseString.indexOf("data") < 0) {
            baseString = "data:" + element.ext + ";base64," + baseString;
          }
          newFiles.push(this.base64ToFile(baseString, nm));
        });
        if (id === "ohipFile") {
          this.ohipFiles = newFiles;
        }
        else if (id === "insuranceFile") {
          this.insuranceFiles = newFiles;
        }
      })
    }
  }

  base64ToFile(dataURI: any, fileName: any): File {

    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }

  signup(form: NgForm) {
    var dt = new Date(this.newUser.dateOfBirth);
    if (!form.form.valid) {
      var invalid = false;
      for (const field in form.form.controls) {
        if (!invalid) {
          const control = form.form.get(field);
          if (control?.invalid) {
            control.markAsTouched();
            $('#' + field).focus();
            invalid = true;
          }
        }
      }
      return;
    }


    let ohipFile = $('#ohipFile')[0] ? this.ohipFiles : "";
    let insuranceFile = $('#insuranceFile')[0] ? this.insuranceFiles : null;
    GlobalConstants.isLoggedIn = false;

    var user = {
      id: this.newUser.id,
      firstName: this.newUser.firstName,
      lastName: this.newUser.lastName,
      password: this.newUser.password,
      email: this.newUser.email,
      phoneNumber: "",
      dateOfBirth: this.newUser.dateOfBirth,
      sex: this.newUser.sex,
      preferredPharmacy: this.newUser.preferredPharmacy,
      ohip: this.deSanitizeOHIPNumber.transform(this.newUser.ohip),
      parentId: this.currentUser?.id
    }
    if (!dt.getTime()) {
      $('#dateOfBirth').focus();
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    this.authenticationService.signup(user, ohipFile, insuranceFile).subscribe(result => {
      console.log(result);
      this.dataSavedEvent.emit(result);
      $('.csLoader').hide();
      //this.signIN = true;

    }, error => { $('.csLoader').hide(); console.log(error); });
  }

}
