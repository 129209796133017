import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'prescriptionStatus'})
export class PrescriptionStatusTransformPipe implements PipeTransform {
  transform(value: number): string {
      if(value == PrescriptionStatuses.NEW)
      {
            return "Prescription Uploaded";
      } else if (value == PrescriptionStatuses.CANCELLED_BY_PATIENT)
      {
            return "Prescription Cancelled by You"
      } else if (value == PrescriptionStatuses.CANCELLED_BY_PHARMCY)
      {
            return "Prescription Cancelled by Pharmacy"
      } else if (value == PrescriptionStatuses.DELIVERED)
      {
            return "Delivered"
      } else if (value == PrescriptionStatuses.OUT_FOR_DELIVERY)
      {
            return "Out For delivery"
      } else if (value == PrescriptionStatuses.PARTIAL_READY)
      {
            return "Partial prescription is ready to be picked up"
      } else if (value == PrescriptionStatuses.PICKED_UP)
      {
            return "Picked up from pharmacy"
      } else if (value == PrescriptionStatuses.READY_TO_PICKUP)
      {
            return "Ready to be picked up from pharmacy"
      } else if (value == PrescriptionStatuses.READY_TO_DELIVER)
      {
            return "Ready to be delivered"
      } else if (value == PrescriptionStatuses.ACCEPTED_BY_PHARMACY)
      {
            return "Accepted by Pharmacy"
      }else if (value == PrescriptionStatuses.PICKED_UP_FAILED)
      {
            return "Pick up Failed"
      } else if (value == PrescriptionStatuses.DELIVERY_FAILED)
      {
            return "Delivery Failed"
      }
      return "UNKNOWN STATUS";
  }
}

@Pipe({name: 'prescriptionDeliveryType'})
export class PrescriptionDeliveryTypePipe implements PipeTransform {
  transform(value: number): string {
      if(value == PrescriptionType.SELF_PICKUP)
      {
            return "Self Pickup";
      } else if(value == PrescriptionType.HOME_DELIVERY)
      {
        return "Home Delivery";
      } 
      else if(value == PrescriptionType.Express_DELIVERY)
      {
        return "Express Delivery";
      }
      return "UNKNOWN TYPE";
  }
}



export enum PrescriptionStatuses { 
    NEW,
    CANCELLED_BY_PATIENT,
    CANCELLED_BY_PHARMCY,
    ACCEPTED_BY_PHARMACY,
    READY_TO_PICKUP,
    PARTIAL_READY,
    READY_TO_DELIVER,
    OUT_FOR_DELIVERY,
    DELIVERED,
    PICKED_UP,
    DELIVERY_FAILED,
    PICKED_UP_FAILED
}

enum PrescriptionType {
    SELF_PICKUP,
    HOME_DELIVERY,
    Express_DELIVERY
  }