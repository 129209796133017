import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localtDateTime'
})
export class LocaltDateTimePipe implements PipeTransform {

  transform(value: string): Date {
    var date = new Date(value);
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

}
