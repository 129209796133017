import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ProductService } from '../services/product.service';
import { SiteConfigService } from '../services/site-config.service';
import { GlobalConstants } from '../shared/global-constants';
import { CartVM } from '../shared/models/cart.model';
import { SiteConfig, SiteConfigTpe } from '../shared/models/site.config';
declare var $: any;
@Component({
  selector: 'mylocalrx-header',
  templateUrl: './mylocalrx-header.component.html',
  styleUrls: ['./mylocalrx-header.component.scss']
})
export class MylocalrxHeaderComponent implements OnInit {

  @Output() onSiteConfigLoaded: EventEmitter<any> = new EventEmitter();
  currentUser: any;
  showMobiCart = false;
  isLoggedIn: boolean = false;
  constructor(private router: Router, private userService: AuthenticationService, private siteConfig: SiteConfigService, private product: ProductService) {


  }
  goToCart(target: any) {
    if ($(target).hasClass("goToCart")) {
      this.router.navigate(['/cart']);
    }
  }

  // get isLoggedIn()
  // {
  //   return GlobalConstants.isLoggedIn;
  // }

  // get currentUser()
  // {
  //   return GlobalConstants.currentUser;
  // }

  signOut() {
    //GlobalConstants.isLoggedIn = false;
    localStorage.removeItem(GlobalConstants.CURRENT_USER);
    this.router.navigate(['/']);
    this.isLoggedIn = false;
  }
  loginEvent(event: any) {
    this.currentUser = GlobalConstants.getcurrentUser();
    this.isLoggedIn = event;
  }

  marquee: any = null;
  currentCart: CartVM[] = [];
  totalAmount = 0;
  ngOnInit(): void {
    this.userService.logInSuccess.subscribe(x => {
      this.currentUser = x;
      this.isLoggedIn = this.currentUser != null;
    });
    this.siteConfig.getSiteConfig().subscribe(x => {
      if (this.onSiteConfigLoaded != null) {
        this.onSiteConfigLoaded.emit(x);
      }
      localStorage.setItem(GlobalConstants.SITE_CONFIG, JSON.stringify(x));
      this.marquee = x.find(m => m.active && m.configType == SiteConfigTpe.MARQUEE);
    });

    this.product.currentCart.subscribe(x => {
      GlobalConstants.cartList = x;
      this.currentCart = x;
      this.totalAmount = 0;
      this.currentCart.forEach(ele => {
        if (ele.product.discount && ele.product.discount > 0) {
          ele.product.discountPrice = ele.product.price - (ele.product.price * (ele.product.discount / 100));
        }
        else {
          ele.product.discountPrice = ele.product.price;
        }
        ele.total = ele.product.discountPrice * ele.qty;
        this.totalAmount += ele.total;
      });
    });

    this.product.getMyCart().subscribe(x => {
      this.product.currentCart.next(x);
    });
  }

}
