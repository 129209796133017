import { Component, OnInit,ViewChild  } from '@angular/core';
import { Router,ActivationStart ,RouterOutlet } from '@angular/router';

import {GlobalConstants} from '../../shared/global-constants';
import {User} from '../../shared/models/user.model';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss']
})
export class PrescriptionComponent implements OnInit {

  
  constructor(private router: Router) { 
    //this.outlet = null;
    
  }

  ngOnInit(): void {
    // if(GlobalConstants.isLoggedIn)
    // {
       this.router.navigateByUrl('prescription/prescription-list');
    // } else
    // {
      
    //   let element: HTMLElement = document.getElementById('SignInLink') as HTMLElement;
    //   element.click();
      
    // }
    
  }

}
