import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mylocalrx-scrollbacktotop',
  templateUrl: './mylocalrx-scrollbacktotop.component.html',
  styleUrls: ['./mylocalrx-scrollbacktotop.component.scss']
})
export class MylocalrxScrollbacktotopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
