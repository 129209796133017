
import { Directive, HostListener } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
declare var $: any;
@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

  constructor(public ngControl: NgControl) {

  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {

    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value, true);
    console.log(this.ngControl.name);
    console.log(document.getElementsByName(this.ngControl.name + ''));
  }


  onInputChange(event: any, backspace: any) {
    let newVal = event.replace(/\D/g, '');
    if (backspace && newVal.length <= 9) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    else {
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length <= 1) {

        newVal = newVal.replace(/^(\d{0,1})/, '+1 ($1)');
      } else if (newVal.length <= 4) {
        newVal = newVal.substring(1, newVal.length);
        newVal = newVal.replace(/^(\d{0,4})/, '+1 ($1)');
      } else if (newVal.length <= 7) {
        newVal = newVal.substring(1, newVal.length);
        newVal = newVal.replace(/^(\d{1,3})(\d{1,3})/, '+1 ($1) $2');
      } else if (newVal.length <= 11) {
        newVal = newVal.substring(1, newVal.length);
        newVal = newVal.replace(/^(\d{1,3})(\d{1,3})(\d{1,4})/, '+1 ($1) $2-$3');
      } else {
        newVal = newVal.substring(0, 11);
        newVal = newVal.replace(/^(\d{3,5})(\d{0,3})(\d{0,4})/, '+1 ($1) $2-$3');
      }
    }
    this.ngControl.valueAccessor?.writeValue(newVal);
  }

}
