import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { GlobalConstants } from './global-constants';
import { User } from './models/user.model';

@Injectable()
export class UserResolver implements Resolve<User> {

  constructor(private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Promise<User> {


    return new Promise((resolve, reject) => {

      var currentUser = GlobalConstants.getcurrentUser();
      if (currentUser == undefined) {
        this.router.navigate(['login/true']);
        return reject("Please login");
      }
      else {
        return resolve(currentUser);
      }
    });
  }
}
