import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from "@angular/router";
import { GlobalConstants } from './global-constants';
import { User } from './models/user.model';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      var currentUser = GlobalConstants.getcurrentUser();
      if (currentUser != undefined) {
        this.router.navigate(['/prescription']);
        return resolve(false);
      }
      else {
        return resolve(true);
      }
    })
  }
}