import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SiteConfig } from '../shared/models/site.config';

@Injectable({
  providedIn: 'root'
})
export class SiteConfigService {

  constructor(private http: HttpClient) { }

  getSiteConfig(): Observable<SiteConfig[]> {
    let result: Observable<any>;
    result =  this.http.get(environment.baseUrl + "/siteconfig");
    return result;
  }
}
