<!-- NAVBAR
      ================================================== -->
<nav style="    padding: 0;" class="navbar navbar-expand-lg navbar-light bg-white sticky-top">

  <div class="container">
    <!-- Brand -->
    <a routerLink="/" class="navbar-brand">
      <img src="./../../assets/svg/MyLocalRxLogo-full.svg" style="width: 135px;" class="navbar-brand-img" alt="..." />
    </a>

    <!-- Toggler -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="material-icons">close</i>
      </button>

      <!-- Navigation -->
      <ul class="navbar-nav ml-auto d-flex align-items-center p-3">
        <!-- <li style="margin: 0 !important;" class="my-3">
          <span>
            <img src="../../assets/new/uploadRx.svg" style="width: 15px;    margin-top: -5px;" />
            <label
              style="margin-right: 30px;font-weight: normal;margin-left: 10px; color: var(--gray);  font-family: var(--font-family-noto_sans);  font-size: var(--font-size-s);">Upload
              Rx</label>
          </span>
        </li> -->
        <li style="margin: 0 !important;" class="my-3">
          <a style="" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" class="nav-link dropdown-toggle" id="navbarLandings"
            [routerLink]="['MylocalrxContentcontainerFaqsComponent']" aria-haspopup="true" aria-expanded="false">
            <span>
              <img src="../../assets/new/qt.svg" style="width: 20px; " />
              <label
                style=" margin-right: 30px;font-weight: normal;margin-left: 10px; color: var(--gray);  font-family: var(--font-family-noto_sans);  font-size: var(--font-size-s);">FAQs</label>
            </span>
          </a>
        </li>
        <li *ngIf="currentCart && currentCart.length > 0">
          <a (click)="goToCart($event.target)"  class="topCartLink goToCart">
            <span class="goToCart"> {{currentCart.length}}</span>
            <img class="goToCart" src="../../assets/images/cart.png" style="width: 22px;" />
            <div class="topCart">
              <div style="height: 30px; width: 100%;border: none;"></div>
              <div *ngFor="let item of currentCart"
                style="width: 100%;display: table; background: white;border-bottom: none;">
                <img src="{{item.product.productImages[0]}}"
                  style="width: 60px; float: left;  background-clip: content-box;" />
                <p style="float: none;">{{item.product.productName}}</p>
              </div>
              <div style="width: 100%; display: block; background: white;">
                <button class="btn btn-primary mr-1" style="width: 100%;  margin-top: 10px;  margin-bottom: 10px;"
                  [routerLink]="['/cart']">{{totalAmount | currency}} Proceed To Checkout</button>
              </div>
            </div>
          </a>
        </li>
        <!-- Button -->
        <li *ngIf="!isLoggedIn"  style="margin: 0 !important;" [ngClass]="!isLoggedIn? 'sing-signup':''">
          <span>
            <img src="../../assets/new/lgsg.svg" style="width: 18.54px;" />
            <label   style="color: var(--red); font-family: var(--font-family-noto_sans);   font-size: var(--font-size-s);">
              <a   style="margin-left: 15px; color: var(--red); font-family: var(--font-family-noto_sans);   font-size:var(--font-size-s); font-weight: normal;"  [routerLink]="['/login/true']">Sign In</a>/
              <a   style="color: var(--red); font-family: var(--font-family-noto_sans);   font-size: var(--font-size-s);font-weight: normal;"  [routerLink]="['/register']">Sign Up</a>
            </label>
          </span>
        </li>
        <li  *ngIf="isLoggedIn" class="nav-item dropdown">
          <a style="padding-top: 0px;color: var(--red);" class="nav-link dropdown-toggle unma" id="navbarUserDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
          <img src="../../assets/new/lgsg.svg" style="width: 18.54px; margin-right: 5px;" />  Welcome {{ currentUser.firstName }}
          </a>
          <div class="dropdown-menu">
            <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" class="dropdown-item"
              [routerLink]="['/MylocalrxContentcontainerProfileComponent']">
              <i class="fa fa-user"></i><span style="padding-left: 7px;">My Profile</span></a>
            <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" class="dropdown-item"
              [routerLink]="['/prescription/prescription-list']">
              <i class="fa fa-hospital-o"></i><span style="padding-left: 7px;">My Prescriptions</span></a>
            <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" class="dropdown-item"
              [routerLink]="['/appointment/covid/vaccination']">
              <i class="fa fa-calendar-o"></i><span style="padding-left: 7px;">Covid Vaccination</span></a>

            <!-- <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" class="dropdown-item"
              [routerLink]="['MylocalrxContentcontainerAppointmentComponent']">
              <i class="fa fa-calendar"></i><span style="padding-left: 7px;">Appointment</span></a> -->

            <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" class="dropdown-item"
              [routerLink]="['family-members']">
              <i class="fa fa-users"></i><span style="padding-left: 7px;">Family Members</span></a>

            <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
              class="dropdown-item " [routerLink]="['/orders/my']">
              <i class="fa fa-first-order"></i><span style="padding-left: 5px;">My Orders</span>
            </a>
            <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
              class="dropdown-item text-primary" on-click="signOut()">
              <i class="fa fa-sign-out"></i><span style="padding-left: 5px;">Sign Out</span>
            </a>

          </div>
        </li>
      </ul>
    </div>
  </div>

</nav>

<div  *ngIf="marquee"  style="width: 100%; display: table;    background-color: #636363e6;    color: var(--white);
    font-family: var(--font-family-roboto);  font-size: var(--font-size-s);padding-top: 6px;padding-bottom: 4px;">
  <marquee>{{marquee.value}}</marquee>
</div>


<div class="mobiCart" *ngIf="currentCart && currentCart.length > 0">

  <div class="topCart"  (click)="showMobiCart = true;">
    <a class="topCartLink"  style="float: left;width: 45px;">
      <span> {{currentCart.length}}</span>
      <img src="../../assets/images/cart.png" style="width: 22px;" />

    </a>
    <div *ngFor="let item of currentCart let i = index" style="float: left">
      <img *ngIf="i<4" src="{{item.product.productImages[0]}}"
        style="width: 30px; float: left; margin-left: 10px; background-clip: content-box;" />
    </div>
  </div>
</div>
<div class="mobiCartDetails" *ngIf="showMobiCart">
  <div class="topCart">
    <div style="height: 30px; width: 100%;border: none;">
    <i (click)="showMobiCart = false;"  style="float: right;font-size: 22px; cursor: pointer;" class="fa fa-close"></i>
    </div>
    <div *ngFor="let item of currentCart" style="width: 100%;display: table; background: white;border-bottom: none;">
      <img src="{{item.product.productImages[0]}}" style="width: 60px; float: left;  background-clip: content-box;" />
      <p style="float: none;">{{item.product.productName}}</p>
    </div>
    <div style="width: 100%; display: block; background: white;">
      <button  (click)="showMobiCart = false;" class="btn btn-primary mr-1" style="width: 100%;  margin-top: 10px;  margin-bottom: 10px;"
        [routerLink]="['/cart']">{{totalAmount | currency}} Proceed To Checkout</button>
    </div>
  </div>
</div>