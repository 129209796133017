import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
@Directive({
  selector: '[appAddressAuto]'
})
export class AddressAutoDirective {

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  private element: HTMLInputElement;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.element, {
      fields: ["address_components", "geometry"],
      types: ['address'],
      componentRestrictions: { country: 'CA' }
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      console.log(place);
      var address = {
        unitNo:this.getAddressComponents(place.address_components,"street_number"),
        streetAddress:this.getAddressComponents(place.address_components,"route"),city:this.getAddressComponents(place.address_components,"locality"),postalCode:this.getAddressComponents(place.address_components,"postal_code")};
      this.onSelect.emit(address);
    });
  }

  //gets "street_number", "route", "locality", "country", "postal_code"
  getAddressComponents(components:any, type:any) {

    for (var key in components) {
      if (components.hasOwnProperty(key)) {

        if (type == components[key].types[0]) {
          return components[key].long_name;
        }
      }
    }
    return "";
  }

}

