import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { WebQueryService } from '../services/web-query.service';
import { PharmacyService } from '../services/pharmacy.service';
import { Pharmacy } from '../shared/models/pharmacy.model';
import { GoogleMapsModule } from '@angular/google-maps';

declare var $: any;


@Component({
  selector: 'mylocalrx-contentcontainer-home',
  templateUrl: './mylocalrx-contentcontainer-home.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-home.component.scss'],
})
export class MylocalrxContentcontainerHomeComponent implements OnInit {
  zoom = 10;
  center: google.maps.LatLngLiteral;
  pharmacies: Array<Pharmacy> | undefined;
  markers: Array<any> | undefined;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    maxZoom: 15,
    minZoom: 8

  }

  constructor(private webQueryService: WebQueryService,
    private pharmacyService: PharmacyService) {
    this.center = {
      lat: 43.815373,
      lng: -79.7315907,
    }
  }
  queryForm = {
    name: "",
    email: "",
    mobileNum: "",
    message: ""
  }
  pharmaciesForMaps: Array<any> | undefined;
  ngOnInit(): void {

    this.markers = [];
    this.pharmacyService.getAllMyLocarxEnable(false).subscribe(data => {
      this.pharmacies = data.filter(x=>x.myLocalRXEnabled == true);;

      if (this.pharmacies) {
        this.pharmaciesForMaps = [];
        var i = 0;
        data.forEach(element => {
          if (i % 3 == 0) {
            var d = {};
            this.pharmaciesForMaps!.push({ d: [] });
          }
          this.pharmaciesForMaps![this.pharmaciesForMaps!.length - 1].d.push(element);
          i++;
        });
        for (let entry of this.pharmacies) {
          if (entry.latitude && entry.longitude) {
            this.markers!.push({
              position: {
                lat: entry.latitude,
                lng: entry.longitude,
              },
              label: {
                color: 'red',
                text: entry.name,
              },
              title: entry.name,
              options: { animation: google.maps.Animation.BOUNCE },
            })
          }
          else {
            var address = entry.name + " " + entry.add1 + " " + entry.city + " " + entry.state + " " + entry.pin;
            this.pharmacyService.getLatLonByAddress(address).then(result => {
              
              this.markers!.push({
                position: result,
                label: {
                  color: 'red',
                  text: entry.name,
                },
                title: entry.name,
                options: { animation: google.maps.Animation.BOUNCE },
              })
            });
          }
        }
      }






      $('.csLoader').hide();
    }, error => $('.csLoader').hide());


    /*const mapProperties = {
      center: new google.maps.LatLng(35.2271, -80.8431),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement,    mapProperties);
    */
    let locations = [
      ['DUKH BHANJAN PHARMACY', 43.6511078, -79.7605107, 1],
      ['DUSK PHARMACY', 43.655981, -79.769966, 2],
      ['FANDOR PHARMACY', 43.6900711, -79.8171987, 3],
      ['FATHER TOBIN PHARMACY', 43.7715332, -79.7583558, 4],
      ['PULSE PHARMACY', 43.7715322, -79.7806711, 5],
      ['SANDALWOOD CARE PHARMACY', 43.7647385, -79.7480491, 6],
      ['SQUIRE ELLIS PHARMACY', 43.815373, -79.7315907, 7],
      ['WEST BRAMPTON PHARMACY', 43.6450127, -79.7565597, 8]
    ];
    /*var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 10,
      center: new google.maps.LatLng(43.72, -79.89),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });*/

    /*var infowindow = new google.maps.InfoWindow();

    var marker, i;

    for (i = 0; i < locations.length; i++) {  
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        map: this.map
      });
     
    }*/

  }
  thankYouUser = "";
  saveQuery(webQueryForm: NgForm) {
    if (webQueryForm.form.invalid) {
      for (const field in webQueryForm.form.controls) {
        const control = webQueryForm.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
          $('#' + field).focus();
          break;
        }
      }
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    this.webQueryService.save(this.queryForm).subscribe(x => {
      this.thankYouUser = x.name;
      this.queryForm = { name: "", email: "", message: "", mobileNum: "" };
      $('.csLoader').hide();
      $("#modal-thank-you").modal("toggle");
      for (const field in webQueryForm.form.controls) {
        const control = webQueryForm.form.get(field);
        control?.markAsUntouched();
      }
    }, error => {
      $('.csLoader').hide();
    });
  }
}
// export class MylocalrxContentcontainerHomeComponent {
//   constructor(private router: Router) {}

//   public onMylocalrxContentcontainerAboutusClick() {
//     this.router.navigate(['MylocalrxContentcontainerAboutusComponent']);
//   }
// }
