import { Directive, HostListener, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInputMask]',
})
export class InputMaskDirective {
  constructor(public ngControl: NgControl) {
  }
  @Input() regx: string = "";
  @Input() format: string = "";
  @Input() iniVal: string = "";
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {

    this.onInputChange(event, false);
  }
  @HostListener('paste', ['$event'])
  pasteEvent(event: ClipboardEvent) {


    let clipboardData = event.clipboardData
    let pastedText = clipboardData!.getData('text');
    this.onInputChange(pastedText, false);
  }



  onInputChange(event: any, backspace: any) {

    let newVal = event;
    let value = "";
    value = newVal.replace(this.iniVal, '');
    if (this.regx == "int") {
      value = value.replace(/\D/g, '');
    }
    else {
      value = value.replace(/[^0-9a-z]/gi, '');
    }
    // let value = newVal.replace(/[^0-9a-z]/gi, '');
    let valArr = value.split('');
    let fm = this.format.split('');
    for (var i = 0; i < fm.length; i++) {
      if (fm[i] == "#" && valArr.length > 0) {
        fm[i] = valArr.splice(0, 1)[0];
      }
      else if (valArr.length <= 0) {
        fm[i] = '';
      }
    }
    newVal = fm.join('');
    this.ngControl.valueAccessor?.writeValue(newVal.toUpperCase());
  }


}


