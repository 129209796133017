<img *ngIf="!myAppointments" src="./../../assets/svg/img 5.svg"
  class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate" alt="..." data-aos="fade-up"
  data-aos-delay="100" width="100%" height="100%" />
<div *ngIf="myAppointments" class="col-12">
  <div class="row">
    <div class="col-md-6">
      <div class="btn-group" style="width: 100%;">
        <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()">
          Previous
        </div>
        <div class="btn btn-outline-secondary" (viewDateChange)="closeOpenMonthViewDay()" mwlCalendarToday
          [(viewDate)]="viewDate">
          Today
        </div>
        <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()">
          Next
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="btn-group" style="width: 100%;">
        <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
          Month
        </div>
        <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
          Week
        </div>
        <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
          Day
        </div>
      </div>
    </div>

    <div class="col-md-12 text-center" style="margin-top: 20px;">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
  </div>
  <br />
  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
       [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
       (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" 
      (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </div>

</div>