import { User } from 'src/app/shared/models/user.model';
import { CartVM } from './models/cart.model';

export class GlobalConstants {
    //static readonly STRIP_KEY="pk_test_51KgNRoSDURPAUbGXMu9VqnVQyaeNgHPZTmTzyfv7RLgUtHv3Gko6HLUhmPT3IwMiFEQvL78pwDWitbViFceR4k8A00PWGgbLik";
    static readonly CURRENT_USER = "myLocalRxWebCurrentUser";
    static readonly SITE_CONFIG = "siteConfig";
    public static isLoggedIn: boolean = false;
    public static cartList:CartVM[] = [];
    //public static currentUser: User;
    public static getcurrentUser(): User|null {
        return localStorage.getItem(GlobalConstants.CURRENT_USER)? JSON.parse(localStorage.getItem(GlobalConstants.CURRENT_USER)||"{}") as User:null;
    };

    public static getCurrentSessionId(forceNew = false): string {

        var CURRENT_SESSION_ID = localStorage.getItem('CURRENT_SESSION_ID') || GlobalConstants.uuidv4();
        if (forceNew) {
            CURRENT_SESSION_ID = GlobalConstants.uuidv4();
        }
        localStorage.setItem('CURRENT_SESSION_ID', CURRENT_SESSION_ID);
        return CURRENT_SESSION_ID;

    }
    public static uuidv4(): string {
        var rand = Math.random;
        var nbr, randStr = "";
        do {
            randStr += (nbr = rand()).toString(16).substr(3, 6);
        } while (randStr.length < 30);
        return (
            randStr.substr(0, 8) + "-" +
            randStr.substr(8, 4) + "-4" +
            randStr.substr(12, 3) + "-" +
            ((nbr * 4 | 0) + 8).toString(16) + // [89ab]
            randStr.substr(15, 3) + "-" +
            randStr.substr(18, 12)
        );
    }
}