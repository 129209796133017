<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h5 style="margin-left: 5px;">My Family Members   <button (click)="addUser(null)" style="float: right" class="btn btn-primary" type="submit">
                Add New
            </button></h5>
        </div>
    </div>
    <div class="row">
        <div *ngFor="let item of listUser" class="col-md-4">
            <div class="usrr">
                <div>
                    <label>Name</label><span>{{item.firstName}} {{item.lastName}}</span>
                </div>
                <div>
                    <label>D.O.B</label><span>{{item.dateOfBirth}}</span>
                </div>
                <div>
                    <label>Gender</label><span>{{item.sex == 1?'Female':'Male'}}</span>
                </div>
                <i (click)="addUser(item)" class="fa fa-edit"></i>
            </div>
        </div>
    </div>
</div>


<div *ngIf="newUser"  class="modal fade" id="addNewMemberModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add new member</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-add-new-member [newUser]="newUser" (dataSavedEvent)="dataSavedEvent($event)">

                </app-add-new-member>
            </div>
        </div>
    </div>
</div>