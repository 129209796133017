import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { User } from '../shared/models/user.model';
import { GlobalConstants } from '../shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  logInSuccess = new BehaviorSubject<User>(GlobalConstants.getcurrentUser()!);

  public AUTHENTICATION_API = environment.baseUrl + '/user';
  public SIGNIN_API = environment.baseUrl + '/token';
  constructor(private http: HttpClient) { }

  signup(user: any, ohipFile: any, insuranceFile: any): Observable<any> {

    let result: Observable<any>;
    const formData = new FormData();
    formData.append('user', JSON.stringify(user));
    let isOHIPFile: boolean = false;
    let isInsuranceFile: boolean = false;

    for (let i = 0; i < ohipFile.length; i++) {
      formData.append("ohipFiles", ohipFile[i])
      isOHIPFile = true;
    }

    for (let i = 0; i < insuranceFile.length; i++) {
      formData.append("insuranceFiles", insuranceFile[i])
      isInsuranceFile = true;
    }

    if (isOHIPFile && isInsuranceFile) {
      result = this.http.post(this.AUTHENTICATION_API, formData);
    } else if (isOHIPFile) {
      result = this.http.post(this.AUTHENTICATION_API + 'ohip', formData);
    } else if (isInsuranceFile) {
      result = this.http.post(this.AUTHENTICATION_API + 'insurance', formData);
    } else {
      result = this.http.post(this.AUTHENTICATION_API + 'wofile', user);
    }

    return result;
  }

  signin(user: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.SIGNIN_API, user);

    return result;
  }

  verifyResetToken(token: string) {
    let result: Observable<any>;
    result = this.http.get(`${environment.baseUrl}/resetToken/${token}`);

    return result;
  }
  forgotPassword(data: any) {
    let result: Observable<any>;
    result = this.http.post(`${environment.baseUrl}/forgot`, data);

    return result;
  }
  resetPassord(data: any) {
    let result: Observable<any>;
    result = this.http.post(`${environment.baseUrl}/reset`, data);

    return result;
  }

  getCurrentUserById(id: any) {
    let result: Observable<any>;
    result = this.http.get(`${environment.baseUrl}/user/${id}`);

    return result;
  }
  updateUser(user: any, ohipFile: any, insuranceFile: any) {
    let result: Observable<any>;
    const formData = new FormData();
    formData.append('user', JSON.stringify(user));

    if (ohipFile) {
      for (let i = 0; i < ohipFile.length; i++) {
        formData.append("ohipFiles", ohipFile[i])
      }
    }
    if (insuranceFile) {
      for (let i = 0; i < insuranceFile.length; i++) {
        formData.append("insuranceFiles", insuranceFile[i])
      }
    }
    result = this.http.put(`${environment.baseUrl}/user`, formData);

    return result;
  }

  updatePassword(userId: string, data: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.put(`${this.AUTHENTICATION_API}/${userId}/password`, data);
    return result;
  }
  uploadProfile(profileImageFile: File): Observable<User> {
    let result: Observable<any>;
    const formData = new FormData();
    formData.append("profileImage", profileImageFile)
    result = this.http.post(`${environment.baseUrl}/user/${GlobalConstants.getcurrentUser()?.id}`, formData);
    return result;
  }

  getUserAddresses(id: any) {
    let result: Observable<any>;
    result = this.http.get(`${environment.baseUrl}/user/${id}/Address`);
    return result;
  }
  deleUserAddresses(id: any,index:number) {
    let result: Observable<any>;
    result = this.http.delete(`${environment.baseUrl}/user/${id}/Address?index=${index}`);
    return result;
  }
  findByParentId(id: any) {
    let result: Observable<any>;
    result = this.http.get(`${environment.baseUrl}/user/findByParentId/${id}`);

    return result;
  }
  deRegister(id: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.put(`${environment.baseUrl}/user/deregister/${id}`, null);

    return result;
  }

  getBlobData(url: string) {
    return this.http.get(url);
  }

}
