<div class="container">
  <div class="row">
    <!--<div class="col-xl-6 px-0">
        <div class="prescription-bg"></div>
      </div>-->
    <div class="col-xl">
      <!-- <h3 class="py-4">Schedule an Appointment</h3> -->
      <!--<div class="col-md-6 mx-auto">
                    <div class="md-form">
                      <input type="text" class="form-control" [(ngModel)]="searchText" (keyup)="searchItems()" id="search-input"
                             mdbInput>
                      <label for="search-input">Search</label>
                    </div>
                  </div>-->
      <div style="margin-top: 25px;" class="row">
        <div class="col-5">
          <h3>Your Prescriptions</h3>
          <span>
            <b>{{ prescriptions.length }} Prescription(s)</b> uploaded </span>
        </div>
        <div class="col-7 align-right">
          <button style="margin-right: 15px !important;"  (click)="refilePrescriptionWitNote(false)" type="button" class="btn btn-primary mr-3 req"
            data-toggle="modal">
            Refill Request
          </button>
          <button  (click)="refilePrescriptionWitNote(true)" type="button" class="btn btn-primary mr-3 req"
            data-toggle="modal">
            Transfer Prescription
          </button>
          <button style="min-width: 230px;" (click)="refilePrescription = null;" type="button" class="btn btn-primary"
            data-toggle="modal" data-target="#NewPrescriptionWarning">
            Upload New Prescription
          </button>
        </div>
      </div>
      <div *ngFor="let prescription of prescriptions" class="card prescription-card">
        <div class="card-header">
          <div class="row">
            <div class="col-3">
              <div>
                <span><strong>UPLOADED ON</strong></span>
              </div>
              <div>
                <span>{{ prescription.uploadDateAndTime | localtDateTime | date:'medium' }}</span>
              </div>
            </div>
            <!--<div class="col-2">
              <div>
                <span>TOTAL COST</span>
              </div>
              <div>
                <span *ngIf="prescription.cost >= 0">{{
                  prescription.cost | currency: "CAD"
                }}</span>
                <a
                  *ngIf="prescription.cost < 0"
                  title="Pharmacy has not updated the cost yet"
                  >---</a
                >
              </div>
            </div>-->
            <div class="col-2">
              <div>
                <span><strong>DELIVERY TYPE</strong></span>
              </div>
              <div>
                <span data-toggle="tooltip" data-html="true">{{
                  prescription.prescriptionType | prescriptionDeliveryType
                  }}</span>
              </div>
            </div>
            <div class="col-4">
              <div>
                <span><strong>STATUS</strong></span>
              </div>
              <div>
                <span data-toggle="tooltip" data-html="true">
                  {{ prescription.status | prescriptionStatus }}
                </span>
              </div>
            </div>
            <div class="col align-right">
              <div>
                <span><strong>PRESCRIPTION #</strong></span>
              </div>
              <div>
                <span data-toggle="tooltip" data-html="true">{{
                  prescription.displayID
                  }}</span>
              </div>
            </div>

          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <!--<div class="col-3">
              <div>
                <span>REFILL STATUS</span>
              </div>
              <div>
                <span>{{ prescription.status | prescriptionStatus }}</span>
              </div>
            </div>-->
            <div class="col-10" style="overflow: auto;">
              <span>Note: {{prescription.noteFromPatient}}</span>
              <app-image-preview-list style="width: 100%; display: table;" [listImage]="prescription.prescriptionLinks">
              </app-image-preview-list>

            </div>
            <!-- <div class="col-2" >
              <mylocalrx-qr-code [prescriptionId] = "prescription.id"></mylocalrx-qr-code>
            </div> -->
            <div class="col-2 align-right">
              <div *ngIf="prescription.status == prescriptionStatusEnum.NEW">
                <button style="width: 110px;" class="btn btn-outline-danger fullWitdhOnMob mrtop10OnMob"
                  data-toggle="modal" (click)="setCurrentPrescription(prescription)" data-target="#confirm-cancel">
                  Cancel
                </button>
              </div>

              <div
                *ngIf="prescription.status == prescriptionStatusEnum.PICKED_UP || prescription.status == prescriptionStatusEnum.DELIVERED"
                style="width: 100%;">
                <button (click)="refilePrescription = prescription;" style="margin-top: 20px;width: 110px;"
                  class="btn btn-primary fullWitdhOnMob" data-toggle="modal"
                  data-target="#NewPrescriptionWarning">Refill</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="NewPrescriptionWarning">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Warning</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Remember, you must present the original prescription at the pharmacy
          in order to pick up your medications.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal"
          [routerLink]="['../../prescription/prescription-upload']" [state]="refilePrescription">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="confirm-cancel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel prescription refill ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" on-click="cancel()" class="btn btn-primary" data-dismiss="modal">
          Yes
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>