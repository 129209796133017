import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { loadStripe, Stripe, StripeCardElement, Token } from '@stripe/stripe-js';
import { tr } from 'date-fns/locale';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { ProductService } from '../services/product.service';
import { GlobalConstants } from '../shared/global-constants';
import { CartVM } from '../shared/models/cart.model';
import { Address } from '../shared/models/prescription.model';
import { ProductVM } from '../shared/models/product.modal';
declare var $: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('singUpDetailForm') singUpDetailForm: any;
  @ViewChild('shippingAddressForm') shippingAddressForm: any;

  currentUser = GlobalConstants.getcurrentUser();
  addresses: Array<Address> | undefined;
  shippingAddress1 = {} as Address;
  isDeliveryAddressNew = 0;
  singUpDetail1 = { email: "", mobile: "", createAccount: false, password: "", confirmPassword: "" };
  constructor(private product: ProductService, private zone: NgZone, private userService: AuthenticationService, private route: Router) { }
  listCart: CartVM[] = [];
  totalAmount = 0;
  subtotal = 0;

  taxRate = 13.00;
  taxtAmount = 0;
  deliveryCharge = 4.99;
  paymentMethod = "STRIPE";

  stripe: Stripe | null = null;
  ngOnInit(): void {
    this.product.currentCart.subscribe(x => {
      this.subtotal = 0;
      this.totalAmount = 0;
      x.forEach(ele => {

        if (ele.product.discount && ele.product.discount > 0) {
          console.log(ele.product.price * (ele.product.discount / 100));
          ele.product.discountPrice = ele.product.price - (ele.product.price * (ele.product.discount / 100));
        }
        else {
          ele.product.discountPrice = ele.product.price;
        }
        this.subtotal += ele.product.discountPrice * ele.qty;
      });
      this.taxtAmount = (this.subtotal * (this.taxRate / 100))
      this.totalAmount = this.subtotal + this.deliveryCharge + this.taxtAmount;
      this.listCart = x
      console.log(x);
      if (x && x.length > 0) {
        setTimeout(() => {
          loadStripe(environment.STRIP_KEY).then(x => {
            this.stripe = x;

            // this.intiStripCard();
            $('.csLoader').css({ display: 'none' });
          });
        }, 3000);
      } else {
        $('.csLoader').css({ display: 'none' });
      }
    });

    setTimeout(() => {
      $('.csLoader').css({ display: 'flex' });
      this.getCurrentCart();
    }, 1000);
    $('.csLoader').css({ display: 'flex' });

    if (this.currentUser) {
      this.userService.getUserAddresses(this.currentUser?.id!).subscribe(data => {
        this.addresses = data ? data.address : null;

      });
    }
  }
  // stripeCard: StripeCardElement | null = null;
  // intiStripCard() {
  //   const elements = this.stripe!.elements();
  //   const style = {
  //     base: {
  //       color: '#32325d',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: 'antialiased',
  //       border: "solid 1px grey",
  //       borderRadius: '10px',
  //       fontSize: '16px',
  //       '::placeholder': {
  //         color: '#aab7c4'
  //       }
  //     },
  //     invalid: {
  //       color: '#fa755a',
  //       iconColor: '#fa755a'
  //     }
  //   };
  //   // Create an instance of the card Element.
  //   this.stripeCard = elements.create('card', { style: style, classes: { base: "form-control" }, hidePostalCode: true });
  //   // Add an instance of the card Element into the `card-element` <div>.
  //   this.stripeCard.mount('#card-element');
  //   this.stripeCard.clear
  //   this.stripeCard.on('change', (event) => {
  //     if (event.error) {
  //       $("#card-errors").html(event.error.message);
  //     } else {
  //       $("#card-errors").html('');
  //     }
  //   });
  // }
  // createToken() {
  //   this.stripe!.createToken(this.stripeCard!).then((result) => {
  //     $('.csLoader').css({ display: 'none' });
  //     if (result.error) {
  //       // Inform the user if there was an error
  //       $("#card-errors").html(result.error.message);
  //     } else {
  //       // Send the token to your server
  //       console.log(result.token);
  //       this.placeOrder(result.token);
  //     }
  //   });
  // }
  private getCurrentCart() {
    this.product.getMyCart().subscribe(x => {
      GlobalConstants.cartList = x;
      this.product.currentCart.next(x);
    });
  }
  updateQty(item: ProductVM, inc: number) {

    var cart = this.listCart.find(x => x.productId == item.id)!;

    $('.csLoader').css({ display: 'flex' });
    cart.qty += inc;
    if (cart.qty <= 0) {
      this.product.deleteCart(cart.id).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
    else {
      this.product.updateCart(cart.id, cart.qty).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
  }

  googleAddressSelected(event: Address) {
    this.zone.run(() => {
      this.shippingAddress1.streetAddress = event.streetAddress;
      this.shippingAddress1.postalCode = event.postalCode;
      this.shippingAddress1.city = event.city;
    });
  }
  errorMessage = "";
  placeOrder() {
    var first = false;
    if (!this.currentUser) {
      if (this.singUpDetailForm.invalid) {
        for (const field in this.singUpDetailForm.controls) {
          console.log(field);
          const control = this.singUpDetailForm.control.get(field);
          if (control?.invalid && !first) {
            first = true;
            control.markAsTouched();
            $('#' + field).focus();
          }
        }
        return;
      }
    }

    if (this.shippingAddressForm.invalid) {
      for (const field in this.shippingAddressForm.controls) {
        console.log(field);
        const control = this.shippingAddressForm.control.get(field);
        if (control?.invalid && !first) {
          first = true;
          control.markAsTouched();
          $('#' + field).focus();
        }
      }
      return;
    }


    // if (this.paymentMethod == "STRIPE" && this.stripe != null && stripeToken == null) {
    //   $('.csLoader').css({ display: 'flex' });
    //   this.createToken();
    //   return;
    // }

    var products: any[] = [];
    this.listCart.forEach(element => {
      products.push({
        name: element.product.productName,
        price: element.product.price,
        id: element.id,
        discount: element.product.discount,
        finalPrice: element.product.discountPrice,
        qty: element.qty,
        pharmacyId: element.product.pharmacyId
      });
    });
    // var token = "";
    // if (stripeToken != null) {
    //   token = stripeToken!.card!.id;
    // }
    var order = {
      singUpDetail: this.currentUser ? null : this.singUpDetail1,
      totalAmount: this.totalAmount,
      paymentMethod: this.paymentMethod,
      deliveryAddress: this.shippingAddress1,
      checkoutAsGuest: this.currentUser == null,
      userId: this.currentUser ? this.currentUser!.id : GlobalConstants.getCurrentSessionId(),
      products: products,
      cancelUrl: window.location.origin + '/cancel',
      successUrl: window.location.origin + '/thank-you',
    };
    console.log(order);
    $('.csLoader').css({ display: 'flex' });
    this.validateAddress(this.shippingAddress1).then(val => {
      if (val) {

        this.product.placeOrder(order).subscribe(x => {
          if (!x.errorMessage && x.data) {
            console.log(x);
            this.product.currentCart.next([]);
            GlobalConstants.cartList = [];
            setTimeout(() => {
              if (x.data.stripToken) {

                this.stripe?.redirectToCheckout({
                  sessionId: x.data.stripToken,
                });
              }
              else {

                this.route.navigate(['/thank-you']);
              }
            }, 500);
          }
          else {
            this.errorMessage = x.errorMessage;
            $("#confirm-cancel").modal('toggle');
          }
          $('.csLoader').css({ display: 'none' });
          console.log(x);
        }, error => {
          $('.csLoader').css({ display: 'none' });
          console.log(error);
        });
      }
      else {
        $('.csLoader').css({ display: 'none' });
      }
    }).catch(x => {
      $('.csLoader').css({ display: 'none' });
      this.errorMessage = "Delivery available only in Brampton.";
      $("#confirm-cancel").modal('toggle');
    });
  }

  AddressChanged() {
    if (this.isDeliveryAddressNew > -1) {
      this.shippingAddress1 = this.addresses![this.isDeliveryAddressNew];
    }
    else {
      this.shippingAddress1 = {
        firstName: '',
        lastName: '',
        unitNo: '',
        buildingName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: ''
      };
    }
  }



  validateAddress(addr: Address): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const geo = new google.maps.Geocoder();
      geo.geocode({ address: addr.postalCode, region: "CA" } as google.maps.GeocoderRequest, (res: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        console.log(res);
        if (status === "OK" && res.length > 0 && res.filter(x => x.formatted_address.toLowerCase().indexOf("brampton")>-1).length > 0) {
          resolve(true)
        }
        else {
          reject(false);
        }
      });
    });
  }
}
