import { Pipe, PipeTransform } from '@angular/core';



@Pipe({
  name: 'deSanitizeMobileNumber'
})
export class DeSanitizeMobileNumber implements PipeTransform {

  private formate = "# (###) ###-####";
  transform(value: string, formateBack: boolean = false): string {
    if (!value) {
      return "";
  }
    value = value.replace(/\D/g, '');
    if (formateBack) {
      value = this.doFormateBack(value);
    }
    return `+${value}`;
  }

  private doFormateBack(newVal: string): string {
    if (!newVal) {
      return "";
  }
    let value = newVal.replace(/\D/g, '');
    // let value = newVal.replace(/[^0-9a-z]/gi, '');
    let valArr = value.split('');
    let fm = this.formate.split('');
    for (var i = 0; i < fm.length; i++) {
      if (fm[i] == "#" && valArr.length > 0) {
        fm[i] = valArr.splice(0, 1)[0];
      }
      else if (valArr.length <= 0) {
        fm[i] = '';
      }
    }
    newVal = fm.join('');
    return newVal;
  }

}

