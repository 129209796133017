<div class="container">
    <div *ngIf="isHomePage" class="row">
        <div class="col-md-12">
            <h2 class="ourPr">Our products
                <a [routerLink]="['/our-products']">View all</a>
            </h2>
        </div>
    </div>
    <p *ngIf="!isHomePage">&nbsp;</p>
    <div *ngIf="listProducts && listProducts.length > 0" class="row">
        <i class="flash" style="padding: 0; margin-top: -20px">* Delivery available only in Brampton.</i>
        <div *ngFor="let item of listProducts" class="col-md-3 myProductOuter">
            <div class="myProduct ">
                <a [routerLink]="['/product/'+item.id+'/'+item.name]"  >
                    <span class="pDsicount" *ngIf="item.discountPrice > 0">
                        {{item.discount}} % OFF
                    </span>
                    <img src="{{item.productImages[0]}}" />
                    <label class="pName">{{item.productName}} <i style="    font-size: 0.9rem;
                        color: #646464; text-transform: uppercase; font-weight: 400;">{{item.productBrand}} {{item.productCode}}</i></label>
                    <label class="pLDsc" [innerHTML]="item.longDesc"></label>
                    <label *ngIf="item.discountPrice > 0" class="pPirce">
                        <span class="dPrice">
                            <span>{{item.currency}}</span>
                            <span>{{item.price}}</span>
                        </span>
                        <span>{{item.currency}}</span>
                        <span>{{item.discountPrice | number : '1.0-2'}}</span>
                    </label>

                    <label *ngIf="item.discountPrice <= 0" class="pPirce">
                        <span>{{item.currency}}</span>
                        <span>{{item.price | number : '1.0-2'}}</span>
                    </label>
                </a>
                <button *ngIf="!item.isAddedToCart" (click)="addToCart(item)" style="width: 100%;"
                    class="btn btn-primary mr-1"> Add to cart</button>
                <div class="pQty" *ngIf="item.isAddedToCart">
                    <span (click)="updateQty(item,-1)"
                        style="border-bottom-right-radius: 0; border-top-right-radius: 0;">-</span>
                    <label>{{item.qty}}</label>
                    <span (click)="updateQty(item,1)"
                        style="border-top-left-radius: 0;border-bottom-left-radius: 0;">+</span>
                </div>
            </div>
        </div>
    </div>
</div>