import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.scss']
})
export class EnterOtpComponent implements OnInit {
  myForm: FormGroup;
  constructor(private routAct: ActivatedRoute, private fb: FormBuilder, private router: Router) {
    this.myForm = fb.group({
      "opt": ["", Validators.required]
    });
  }
  token = "";
  id = "";
  isEmail = false;
  ngOnInit(): void {
    this.id = this.routAct.snapshot.params.id;
    this.isEmail = this.id.indexOf("@") > -1;
  }
  otpForm() {
    window.location.href = window.location.origin + '/reset?token=' + this.token;
  }

}
