<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-1">
        <!-- Image -->
        <img src="./../../assets/svg/img 4.svg" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..." data-aos="fade-up" data-aos-delay="100" width="100%" height="100%" />
      </div>
      <div class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate" data-aos="fade-up">
        <h3 class="py-4">Call (or) Contact Us</h3>
        <p>
          <b>NEED HELP (or) HAVE QUESTIONS ABOUT MEDICATIONS & PRODUCTS?</b>
        </p>
        <p>
          For questions of medical nature or questions regarding specific medications,
          please contact your local MyLocalRX pharmacist. If you have a question
          regarding the availability of a product, please contact the front store
          manager of your local MyLocalRX store directly.
        </p>
        <p>
          Our team is happy to answer any questions.
          Fill out the form and we’ll be in touch with you as soon as possible.
        </p>
        <div class="py-4">
          <!--<p><b>+1 (647) 555 - 9900</b></p>-->
          <p>
            <b><a href="mailto:info@mylocalrx.ca">info@mylocalrx.ca</a></b>
          </p>
          <!--<p><b>105 Father Tobin, Brampton - ON L6R 3P1</b></p>-->
        </div>
        <!--<p class="py-1">
          <b>Monday to Saturday, 8 AM to 8 PM EST</b> <br />
          <b>Sunday 11 AM to 7 PM EST</b>
        </p>-->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-md-offset-3">
        <div class="well well-sm">
          <form class="form-horizontal cntForm" #webQueryForm="ngForm" (ngSubmit)="saveQuery(webQueryForm)">
            <fieldset>
              <!-- Name input-->
              <div class="form-group">
                <div>
                  <input [(ngModel)]="queryForm.name" id="name" name="name" #name type="text"
                    placeholder="Enter your name" class="form-control" required pattern=".{3,}" />
                  <label class="control-label" for="name">Name</label>
                </div>

              </div>

              <!-- Email input-->
              <div class="form-group">

                <div>
                  <input [(ngModel)]="queryForm.email" id="email" name="email" #email type="text"
                    placeholder="Enter your e-mail" class="form-control" required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                  <label class="control-label" for="email">E-mail</label>
                </div>
              </div>

              <!-- Email input-->
              <div class="form-group">

                <div>
                  <input [(ngModel)]="queryForm.mobileNum" id="mobileNum" name="mobileNum" #mobileNum type="text"
                    placeholder="Enter your mobile number" class="form-control" phoneNumber appPhoneMask required
                    minlength="16" maxlength="17" />
                  <label class="control-label" for="email">Mobile Number</label>
                </div>
              </div>

              <!-- Message body -->
              <div class="form-group">

                <div>
                  <textarea [(ngModel)]="queryForm.message" class="form-control" #message id="message" name="message"
                    placeholder="Type your message here..." rows="5"></textarea>
                  <label class="control-label" for="message">Message</label>
                </div>
              </div>

              <!-- Form actions -->
              <div class="form-group">
                <div class="col-md-12 text-right">
                  <button type="submit" class="btn btn-primary mt-3">
                    Submit
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>



<div class="modal fade" id="modal-thank-you">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Success</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Thank you <b>{{ thankYouUser }}</b>, our team will contanct you soon.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>