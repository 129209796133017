<!-- WELCOME
      ================================================== -->
<section class="py-5 pt-md-11 border-bottom">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-5 order-md-2 order-2">
        <!-- Image -->
        <img src="./../../assets/svg/img 1.svg" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..." data-aos="fade-up" data-aos-delay="100" />
      </div>
      <div class="col-12 col-md-7 col-lg-7 order-md-1 aos-init aos-animate order-1" data-aos="fade-up">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 order-md-1 order-2">
            <h1 class="display-4 text-center text-md-left mb-3">
              Stay Home,<span class="text-primary"><br />Stay Safe!</span>
            </h1>
          </div>
          <div class="col-12 col-md-12 col-lg-12 order-md-1 order-3">
            <p class="text-center text-md-left mb-6 mb-lg-8">
              Get your prescribed medication delivered to your doorstep with MyLocalRX.
            </p>
          </div>
          <div class="col-12 col-md-12 col-lg-12 order-md-1 order-1">
            <div class="row">
              <div class="col-md-6">
                <div style="float: left; margin-bottom: 20px; width: 100%;">
                  <a style="min-width: 180px;" routerLink="prescription" class="btn btn-primary  mr-1">
                    Prescription Delivery
                  </a>
                </div>
                <div style="float: left;    margin-bottom: 20px; width: 100%;">
                  <a style="min-width: 180px;" href="https://pulseoncall.com/" target="_blank"
                    class="btn btn-primary  mr-1">
                    Speak to a Doctor
                  </a>
                </div>
              </div>
              <div class="col-md-6">
                <div style="float: left;    margin-bottom: 20px;">
                  <a [routerLink]="['/appointment/covid/vaccination']" class="btn btn-primary  mr-1">
                    Book Covid Vaccinations
                  </a>
                </div>

                <div style="float: left;    margin-bottom: 20px;">
                  <a data-toggle="modal" style="color: white;" data-target="#asymp-confirmation"
                    class="btn btn-primary  mr-1">
                    Covid Asymptomatic Test
                  </a>
                </div>
              </div>
              <div class="col-md-6">
                <div style="float: left;    margin-bottom: 20px;">
                  <a style="min-width: 180px;" [routerLink]="['/appointment/covid/flushot']"
                    class="btn btn-primary  mr-1">
                    Book Flu shot
                  </a>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
  <!-- / .container -->
</section>
<!-- <section class="py-5 py-md-11 border-bottom">
  <app-home-list-products [isHomePage]="true"></app-home-list-products>
</section> -->
<!-- FEATURES
            ================================================== -->
<section class="py-5 py-md-11 border-bottom">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4 mb-5" data-aos="fade-up" data-aos-delay="100">
        <!-- Icon -->
        <div class="icon text-primary mb-3">
          <img src="./../../assets/svg/service.svg" alt="" width="40" height="40" class="svg-icon" />
        </div>

        <!-- Heading -->
        <h3 class="text-primary">Register with Us</h3>

        <!-- Text -->
        <p class="mb-6 mb-md-0">Set up an account.</p> <br />
        <a routerLink="MylocalrxContentcontainerLoginComponent" class="btn btn-primary mt-3"> Learn More</a>
      </div>
      <div class="col-12 col-md-4 mb-5" data-aos="fade-up" data-aos-delay="100">
        <!-- Icon -->
        <div class="icon text-primary mb-3">
          <img src="./../../assets/svg/prescription.svg" alt="" width="40" height="40" class="svg-icon" />
        </div>

        <!-- Heading -->
        <h3 class="text-primary">Choose Pharmacy</h3>

        <!-- Text -->
        <p class="mb-6 mb-md-0">
          Upload a snapshot of your prescription and choose a pharmacy near you.
        </p>
        <a [routerLink]="['/prescription']" class="btn btn-primary mt-3">
          Learn More</a>
      </div>
      <div class="col-12 col-md-4 mb-5" data-aos="fade-up" data-aos-delay="100">
        <!-- Icon -->
        <div class="icon text-primary mb-3">
          <img src="./../../assets/svg/appointment.svg" alt="" width="40" height="40" class="svg-icon" />
        </div>

        <!-- Heading -->
        <h3 class="text-primary">Receive Medications</h3>

        <!-- Text -->
        <p class="mb-0">
          Have your medications delivered to your doorstep within two hours.
        </p>
        <a [routerLink]="['/prescription']" class="btn btn-primary mt-3">
          Learn More</a>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>

<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 border-bottom py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-1">
        <!-- Image -->
        <img src="./../../assets/svg/img 9.svg" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..." data-aos="fade-up" data-aos-delay="100" width="100%" height="100%" />
        <!-- Video -->
        <!-- <iframe
          width="100%"
          height="400"
          src="./../../assets/videos/1057540783-preview.mp4"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> -->
        <!-- <iframe
          width="100%"
          height="400"
          src="./../../assets/videos/1041405601-preview.mp4"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> -->
      </div>
      <div class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate" data-aos="fade-up">
        <!-- Heading -->
        <h1 class="display-4 text-center text-md-left mb-3">
          About Us
        </h1>

        <!-- Text -->
        <p class="text-center text-md-left mb-3">
          MyLocalRX has been developed by a group of 3 pharmacists with the
          patient in mind. During the height of the COVID-19 pandemic we
          realized that for many people it was difficult to get necessary
          prescriptions from their doctor to the pharmacy, and harder still to
          get the medication home from the pharmacy. MyLocalRX is an end to end
          solution for your medication needs.
        </p>
        <p class="text-center text-md-left mb-3">
          Simply upload a picture of your prescription to your profile and
          select your pharmacy of choice. The pharmacy is notified that you have
          uploaded a prescription.
        </p>
        <p class="text-center text-md-left mb-3">
          Once your prescription is ready you get a notification that you can go
          and collect it or get it delivered. Along with this, all our member
          pharmacies offer appointments for individual medication consultations,
          flu shots, vaccinations (including COVID once available). Along the
          way you will get timely updates via text message, and if you choose to
          get the medication delivered to you then you can track your
          medications progress to your house.
        </p>
        <!-- Buttons -->
        <div class="text-center text-md-left">
          <a href="#" class="btn btn-primary  mr-1"> Learn More </a>
        </div>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>

<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 border-bottom py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-2">
        <!-- Image -->
        <img src="./../../assets/svg/img 3.svg" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..." data-aos="fade-up" data-aos-delay="100" width="100%" height="100%" />
      </div>
      <div class="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate" data-aos="fade-up">
        <!-- Heading -->
        <h1 class="display-4 text-center text-md-left mb-3">FAQs</h1>

        <div class="row">
          <div class="col-12">
            <h5 class="mt-4">
              Do I need a valid prescription to get my medicine?
            </h5>
            <p>
              Yes. You will have to upload a valid prescription to buy medicines
              using the MyLocalRX web app, please note that the original
              prescriptipon must be received by the pharmacy either by faq from
              the doctors office or persocally form you before any medication
              can be released for pick-up or delivery. In case you have
              misplaced your prescription, our doctors can help you via virtual
              consultation.
            </p>
            <h5 class="mt-4">Is there a fee for delivery?</h5>
            <p>
              Delivery by the end of the day is free, but express delivery
              (within 2 hours) is a minimum of $10 if within 5kms of the pharmacy, or more of you live further away.
            </p>
            <h5 class="mt-4">Where do you deliver to?</h5>
            <p>
              At the moment we deliver in Brampton,Caledon and Mississauga only,
              but we hope to expand to the GTA soon.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>

<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-1">
        <!-- Image -->
        <img src="./../../assets/svg/img 4.svg" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..." data-aos="fade-up" data-aos-delay="100" width="100%" height="100%" />
      </div>
      <div class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate" data-aos="fade-up">
        <!-- Heading -->
        <h1 class="display-4 text-center text-md-left mb-3">Contact Us</h1>
        <div class="well well-sm">
          <form class="form-horizontal querForm" #webQueryForm="ngForm" (ngSubmit)="saveQuery(webQueryForm)">
            <fieldset>
              <!-- Name input-->
              <div class="form-group">
                <div>
                  <input [(ngModel)]="queryForm.name" id="name" name="name" #name type="text"
                    placeholder="Enter your name" class="form-control" required pattern=".{3,}" />
                  <label class="control-label" for="name">Name <span>*</span>
                  </label>
                </div>
              </div>

              <!-- Email input-->
              <div class="form-group">
                <div>
                  <input [(ngModel)]="queryForm.email" id="email" name="email" #email type="text"
                    placeholder="Enter your E-mail address" class="form-control" required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                  <label class="control-label" for="email">E-mail Address <span>*</span></label>
                </div>
              </div>

              <!-- Email input-->
              <div class="form-group">
                <div>
                  <input [(ngModel)]="queryForm.mobileNum" id="mobileNum" name="mobileNum" #mobileNum type="text"
                    placeholder="Enter your mobile number" class="form-control" phoneNumber appPhoneMask required
                    minlength="16" maxlength="17" />
                  <label class="control-label" for="email">Mobile Number <span>*</span></label>
                </div>
              </div>

              <!-- Message body -->
              <div class="form-group">
                <div>
                  <textarea [(ngModel)]="queryForm.message" class="form-control" #message id="message" name="message"
                    placeholder="Please type your message here..." rows="5"></textarea>
                  <label class="control-label" for="message">Message</label>
                </div>
              </div>

              <!-- Form actions -->
              <div class="form-group">
                <div class="col-md-12 text-right">
                  <button type="submit" class="btn btn-primary mt-3">
                    Submit
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>

<div class="container-fluid px-0 mt-10">
  <div class="row">
    <div class="col-md-6" style="padding-left: 25px;">
      <h4 class="ourph">Pharmacies Listed Under MyLocalRX</h4>
      <div class="pharmacy" *ngFor="let item of pharmacies">
        <label style="font-size: 12px;color: black;">{{item.name}}</label><br />
        <label>{{item.state}}, {{item.pin}}</label>
      </div>
    </div>
    <div class="col-md-6">

      <google-map height="375px" width="100%" [center]="center" [zoom]="zoom" [options]="options">
        <map-marker *ngFor="let marker of markers" [position]="marker.position" [title]="marker.title">
        </map-marker>
      </google-map>

    </div>
  </div>

</div>

<div class="modal fade" id="modal-thank-you">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Success</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Thank you <b>{{ thankYouUser }}</b>, our team will contanct you soon.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="asymp-confirmation">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer">
        <button type="button" [routerLink]="['/appointment/covid/asymptomatic-test']" class="btn btn-primary"
          data-dismiss="modal">
          Book appointment
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>