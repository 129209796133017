<!-- WELCOME
            ================================================== -->

            <section class="pt-4 pt-md-11 py-5">
                <div class="container containerMain">
                    <div class="row">
                        <div class="col-md-4">
                            <div style=" margin: 10px;">
                                <div style="padding: 10px" class="shdo">
                                    <!-- <div style="width:100%;">
                                        <label>Appointment Type: </label>
                                        <form [formGroup]="appointmentForm">
                                            Physical Visit <input type="radio" name="appointmentType" value='appointmentType' formControlName="appointmentType" [(ngModel)]='appointmentType' (click)="getvalue2nd('Physical Visit')">
                                            Virtual Call   <input type="radio" name='appointmentType' value='appointmentType' formControlName="appointmentType" [(ngModel)]='appointmentType' (click)="getvalue2nd('virtual Call')">  
                                        </form>
                                        <hr>
                                    </div> -->
                                    <div style="width:100%;">
                                        <label>Patient </label>
                                        <select (change)="patientChange()" class="form-control" id="patientName" name="patientName"
                                            #patientName [(ngModel)]="newAppointment.patientName">
                                            <option selected disabled [value]="-1" selected>Select patient</option>
                                            <option *ngFor="let user of listUsers" [value]="user.firstName+' '+user.lastName">
                                                {{ user.firstName }} {{ user.lastName }}
                                            </option>
                                        </select>
                                    </div>
                                    <div style="width: 100%;">
                                        <label>Location </label>
                                        <select class="form-control" id="pharmacyID" name="pharmacyID" #pharmacyID
                                            [(ngModel)]="newAppointment.pharmacyID" (change)="pharmacyChange()">
                                            <option selected disabled [value]="-1" selected>Select Location</option>
                                            <option *ngFor="let ph of pharmacies" [value]="ph.id">
                                                {{ ph.name }} / {{ ph.city }} {{ph.pin}}
                                            </option>
                                        </select>
                                    </div>
            
                                    <div style="width: 100%;" >
                                        <label>Service </label>
            
                                        <!-- <input style="width: 100%;" name="serviceName" class="form-control"  value="Minor Ailments"  readonly /> -->
                                        <select class="form-control" id="serviceID" name="serviceID" #serviceID
                                            [(ngModel)]="newAppointment.serviceID" (change)="serviceChange()">
                                            <option selected disabled [value]="-1" selected>Select service</option>
                                            <option *ngFor="let service of services" [value]="service.id">
                                                {{ service.serviceName }}
                                            </option>
                                        </select>
                                    </div>
            
            
                                    <ng-container *ngIf="selectedService != 'Covid Vaccine' && selectedService != 'Shingrix Vaccine' && selectedService != 'Cannabis Consultation' 
                                    && selectedService != 'Flu Shot'  && selectedService != 'Covid'  && selectedService != 'ShingrixVaccine' && selectedService != 'Cannabis'  && selectedService != 'FluShot' " >
                                  <hr>
            
                                    <div style="width:100%;">
                                        <label>Appointment Type</label>
                                        <form [formGroup]="appointmentForm">
                                            Physical Visit <input id="radioButton" type="radio" name="appointment_type" value="Physical Visit"  formControlName="appointment_type" [(ngModel)]='appointment_type' checked="checked" >
                                            Virtual Call &nbsp;&nbsp;  <input type="radio" name='appointment_type' value="Virtual Call" formControlName="appointment_type" [(ngModel)]='appointment_type' >   
                                        </form>
                                        <hr>
                                    </div>
            
                                    <div style="width: 100%;"  *ngIf="appointment_type == 'Virtual Call' ">
                                        <div class="form-group">
                                            
                                            <label class="control-label" for="email">Phone Number <span>*</span></label>
                                            <form [formGroup]="appointmentForm">
                                              <input style="width: 100%;" [(ngModel)]="appointment_mobileno" id="appointment_mobileno" name="appointment_mobileno"  type="text" value="appointment_mobileno"
                                                placeholder="Enter your mobile number" formControlName="appointment_mobileno" class="form-control" phoneNumber appPhoneMask required
                                                minlength="16" maxlength="17" />
                                            </form>
                                          </div>
                                    </div>
            
                                    <div style="width: 100%;">
                                        <label>Appointment Note</label>
                                        <form [formGroup]="appointmentForm">
                                            <textarea type="text" style="width: 100%;" name="appointment_note" value="appointment_note" [(ngModel)]="appointment_note" formControlName="appointment_note"  placeholder="Enter Appointment Note" >
                                            </textarea>
                                        </form>
                                    </div>
            
                                </ng-container>                    
            
                                    <!-- <div style="width: 100%;margin-top: 10px;">
                                        <label>Time Slot </label>
                                        <select (change)="slotChange()" class="form-control" id="slotName" name="slotName" #slotName
                                            [(ngModel)]="newAppointment.slotName">
                                            <option disabled [value]="-1" selected>Select time slot</option>
                                            <option *ngFor="let timeSlot of timeslots" [value]="timeSlot.timeSlotName">
                                                {{ timeSlot.timeSlotName }}
                                            </option>
                                        </select>
                                    </div> -->
                                    <div style="width: 100%;;margin-top: 10px;">
                                        <label>Appointment Date </label>
                                        <div style="margin-top: -5px;" class="appDate" id="datepicker" data-date="0d"
                                            data-date-start-date="1d"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="col-md-8">
                            <!--<button (click)="bookWaitingList()" style="margin-bottom: 25px; margin-top: 10px;"
                                class="btn btn-primary  mr-1" *ngIf="showWaitingButton">Book Appointment In Waiting
                                List</button>-->
                            <div style="width: 100%; background: white; margin-bottom: 20px;"
                                *ngFor="let timeSlot of timeslots let slotIndex = index"
                                [ngClass]="newAppointment.slotName == timeSlot.timeSlotName && selectedSlotIndex == slotIndex ?'selectSlotDiv shdo':'unselctedSlot'">
                                <!--<label *ngIf="isThisCovidService"  style="width: 100%; margin-bottom: 10px;text-transform: uppercase;">
                                    Vaccine Available :<b style="font-weight: 500;  font-size: 1.2rem;  color: var(--primary);">
                                        Astra-Zeneca COVID-19 vaccine Pfizer </b>
                                </label>-->
                                <div *ngIf="timeSlot.isActive">
                                    <label *ngIf="timeSlot.data"
                                        style="width: 100%; margin-bottom: 10px;text-transform: uppercase;">Slot name:
                                        {{timeSlot.timeSlotName}}
                                        <span style="font-weight: 500; margin-left: 10px;  font-size: 0.8rem;  color: #afafaf;"> {{
                                            timeSlot.data[0].date | date:'MMM dd yyyy'}} </span>
                                        <br /><b style="font-weight: 500;  font-size: 0.8rem;  color: #afafaf;">
            
                                            Slot timing: {{ timeSlot.data[0].date | date:'hh:mm a'}} To {{
                                            timeSlot.data[timeSlot.data.length-1].date
                                            |
                                            date:'hh:mm a'}}</b>
                                    </label>
                                    <div class="btnList shdo">
                                        <!-- <div class="card-header">{{ timeSlot.timeSlotName }}</div> -->
                                        <!-- <div class="card-body"> -->
                                        <button style="min-width: 90px;" type="button" *ngFor="let booking of timeSlot.data"
                                            (click)="selectTimeSlot(timeSlot.timeSlotName,booking.date,slotIndex)"
                                            [ngClass]="(selectedDate == booking.date || booking.isMe)? 'bg-success':'bg-danger1'"
                                            [disabled]="booking.booked || !booking.isActive"
                                            class="btn my-1 mx-1 me_{{booking.isMe}}">
                                            <span *ngIf="!booking.booked && booking.isActive" style="color: white;" class="badge"
                                                [ngClass]="selectedDate == booking.date? 'bg-success':'bg-danger1'">{{
                                                booking.date | date:'hh:mm a'
                                                }}</span>
                                            <span *ngIf="booking.booked || !booking.isActive"
                                                style="color: #000000; background-color: #e8e8e8;" class="badge">{{
                                                booking.date | date:'hh:mm a'
                                                }}</span>
                                        </button>
                                        <!-- </div> -->
                                    </div>
                                    <div style="margin-top: 15px; float: right;"
                                        [ngClass]="newAppointment.slotName == timeSlot.timeSlotName && selectedSlotIndex == slotIndex  ?'show':'hide'"
                                        class="form-group">
                                        <button [disabled]="newAppointment.timeslot? false:true" (click)="validateAgeLimit()"
                                            type="submit" class="btn btn-success float-right mt-2">
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="waitingAppointmentList" class="navbar-light"
                                style="width: 100%; margin-top: 20px;display: table">
                                <label style=" width: 100%; display: table;">Your waiting appointment list</label>
                                <span class="waitingList" *ngFor="let item of waitingAppointmentList">
                                    {{item}}
            
                                    <span style="width: 100%;">Create On: {{item.createdOn | date :'MMM-dd-yyy'}} <i
                                            style="float: right;" class="navbar-toggler-icon"></i></span>
                                    {{item.serviceName}}
                                </span>
                            </div>
                            <div style="width: 100%; margin-top: 20px;display: table">
                                <label style="margin-left: 5px;">
                                    Upcoming Appointments
                                </label>
            
                                <div class="row" style="width: 100%; margin: 0;">
                                    <div *ngFor="let app of myAppointments" style="padding: 5px;position: relative;"
                                        class="col-md-6 ">
                                        <div class="upcoming" [ngClass]="app.status == 0?'bookedApp':''">
                                            <label style="margin-bottom: 0;display: table;"><span
                                                    style="float: left;width: auto;">{{app.address.firstName}} 
                                                    {{app.address.lastName}}</span> <span
                                                    style="float: right;width: auto;margin-right: 30px;"> {{app.timeslot |
                                                    date:'medium'}}</span></label>
                                            <div [innerHTML]="app.slotName"> {{app.slotName}}</div>
                                            <label *ngIf="app.note">
                                                <span>Note: {{app.note}}</span>
                                            </label>
                                            <label>
                                                <span>Status: <span style="color: green " *ngIf="app.status == 0">Booked</span>
                                                    <span style="color: var(--primary); " *ngIf="app.status == 1">Cancelled</span>
                                                </span>
                                            </label>
                                        </div>
                                        <i *ngIf="app.status == 0" (click)="cancel(app.id)"
                                            style="position: absolute; top: 15px;right: 15px; color: var(--primary); cursor: pointer;"
                                            class="fa fa-window-close">
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            
            <div class="modal fade" id="appointmentSuccess" tabindex="-1" role="dialog" aria-labelledby="appointmentSuccess"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;
                                    padding: 10px !important;
                                  ">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                Success
                            </h5>
                        </div>
                        <div *ngIf="nexDate && newAppointment.timeslot" class="modal-body" [innerHTML]="successMessage">
                            Hi <b> {{ newAppointment.patientName}}</b> your appointment has been
                            scheduled
                            <span *ngIf="isThisCovidService">.
            
                                <!-- <br />1st dose: </span><span *ngIf="!isThisCovidService"> on -->
                            </span>
            
                            <!-- {{newAppointment.timeslot | localtDateTime | date:'medium'}}.<br />
                            <span *ngIf="autoBookingRequired"> 2nd dose: {{nexDate | localtDateTime | date:'medium'}}.</span> -->
                        </div>
                        <div class="modal-footer">
                            <button (click)="refresPage()" type="button" data-dismiss="modal" class="btn btn-primary">
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="appointmentError" tabindex="-1" role="dialog" aria-labelledby="appointmentError"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;
                                    padding: 10px !important;
                                  ">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                Error
                            </h5>
                        </div>
                        <div class="modal-body">
                            {{errorMsg}}
                        </div>
                        <div class="modal-footer">
            
                            <button type="button" class="btn btn-primary" data-dismiss="modal">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            
            
            <div class="modal fade" id="appointmentAddress" tabindex="-1" role="dialog" aria-labelledby="appointmentAddress"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;
                                    padding: 10px !important;
                                  ">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                Address
                            </h5>
                        </div>
                        <div class="modal-body">
            
                            <div *ngIf="addresses" class="row deliveryTime addresses">
            
                                <div *ngFor="let delAddress of addresses let i = index" class="col-md-2-0">
                                    <input (change)="AddressChanged()" type="radio" [(ngModel)]="isAddressNew" name="delAddress"
                                        id="oldAddress_{{i}}" [value]="i" />
                                    <label for="oldAddress_{{i}}">
                                        <div>{{delAddress.firstName}} {{delAddress.lastName}}</div>
                                        <div>{{delAddress.streetAddress}}</div>
                                        <div>{{delAddress.city}}-{{delAddress.postalCode}}</div>
                                    </label>
            
                                </div>
                                <div class="col-md-2-0">
                                    <input type="radio" [(ngModel)]="isAddressNew" (change)="AddressChanged()" name="delAddress"
                                        id="delAddressNew" [value]="-1" />
                                    <label for="delAddressNew">
                                        <div>Add new</div>
                                        <div>Address</div>
                                    </label>
                                </div>
                            </div>
                            <div style="width: 100%;">
                                <form #addressForm="ngForm">
                                    <div class="form-row">
                                        <div class="col">
                                            <div class="form-group">
                                                <!-- First Name -->
                                                <label for="full_name_id" class="control-label">First Name *</label>
                                                <input type="text" class="form-control" id="firstname"
                                                    [(ngModel)]="newAppointment.address.firstName" name="firstname" #firstname
                                                    required pattern=".{3,}" placeholder="Enter your first name" />
                                                <span>*</span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <!-- Last Name -->
                                                <label for="full_name_id" class="control-label">Last Name *</label>
                                                <input required pattern=".{3,}" type="text" class="form-control" id="lastName"
                                                    [(ngModel)]="newAppointment.address.lastName" name="lastName" #lastName
                                                    placeholder="Enter your last name" />
                                                <span>*</span>
                                            </div>
                                        </div>
                                    </div>
            
            
                                    <div class="form-row">
                                        <!-- Street 1 -->
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="unitNo" class="control-label">House No.</label>
                                                <input type="text" class="form-control" [(ngModel)]="newAppointment.address.unitNo"
                                                    id="unitNo" name="unitNo" placeholder="Enter your house number" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="buildingName" class="control-label">Building Name</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="newAppointment.address.buildingName" id="buildingName"
                                                    name="buildingName" placeholder="Enter your building name" />
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="form-group">
                                        <!-- Street 2 -->
                                        <label for="street2_id" class="control-label">Street Address *</label>
                                        <input required appAddressAuto (onSelect)="googleAddressSelected($event)" pattern=".{4,}"
                                            type="text" class="form-control" id="streetAddress"
                                            [(ngModel)]="newAppointment.address.streetAddress" name="streetAddress"
                                            placeholder="Enter your street address" />
                                        <span>*</span>
                                    </div>
            
                                    <div class="form-row">
                                        <div class="col">
                                            <div class="form-group">
                                                <!-- City-->
                                                <label for="city_id" class="control-label">City *</label>
                                                <input required pattern=".{4,}" type="text" class="form-control" id="city"
                                                    [(ngModel)]="newAppointment.address.city" name="city"
                                                    placeholder="Enter your city" />
                                                <span>*</span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <!-- Zip Code-->
                                                <label for="zip_id" class="control-label">Postal Code *</label>
                                                <input appInputMask [format]="'### ###'" required minlength="7" maxlength="7"
                                                    type="text" class="form-control" id="postalCode" name="postalCode"
                                                    [(ngModel)]="newAppointment.address.postalCode"
                                                    placeholder="Enter your postal code" />
                                                <span>*</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Cancel
                            </button>
                            <button (click)="save(addressForm)" type="button" class="btn btn-primary">
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div class="modal fade" id="questionModal" tabindex="-1" role="dialog" aria-labelledby="questionModal"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;display: table;    padding: 0px !important;">
                            <h5 class="modal-title" style="padding: 10px !important;" id="questionModalLongTitle">
                                Pre-Assessment Questionnaire
                            </h5>
                            <div style="width: 100%; height: 5px; background: gainsboro;">
                                <div style="height: 5px; background: var(--primary);" [ngStyle]="{'width': qProgress}">
                                </div>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="qstn" *ngFor="let que of listQuestionnaire let i = index">
                                <div [ngClass]="questionNumber == i?'show':'hide'" style="width: 100%;">
                                    <label style="width: 100%;font-weight: 400;"><b>{{i+1}})</b> {{que.question}}</label>
            
                                    <div *ngIf="que.questionType == 0">
                                        <label class="qsta"><input (change)="questChange(true)" type="radio" name="qst_{{i}}"
                                                value="{{que.options[0]}}" [(ngModel)]="que.answer" />
                                            {{que.options[0]}}</label>
                                        <label class="qsta"><input (change)="questChange(true)" type="radio" name="qst_{{i}}"
                                                value="{{que.options[1]}}" [(ngModel)]="que.answer" />
                                            {{que.options[1]}}</label>
                                    </div>
                                    <div *ngIf="que.questionType == 1">
                                        <input (change)="questChange(false)"  placeholder="{{que.placeholder}}" class="form-control" style="width: 100%;" type="text" name="qst_{{i}}" [(ngModel)]="que.answer" />
                                    </div>
                                </div>
                            </div>
                            <div class="prenext" style="float: right">
            
                                <span *ngIf="questionNumber > 0" (click)="moveQuestion(-1)" style="width: 120px;
                                text-align: center;" class="fa">
                                    <i style="padding: 0;
                                    margin: 3px;" class="fa fa-chevron-left"></i> Previous
                                </span>
                                <span *ngIf="questionNumber < listQuestionnaire.length-1" (click)="moveQuestion(1)" style="width: 120px;
                                text-align: center;" class="fa">Next <i style="padding: 0;
                                    margin: 3px;" class="fa fa-chevron-right"></i></span>
            
            
                            </div>
                        </div>
                        <div class="modal-footer">
                            <label style="width: 100%; color: var(--primary); margin-bottom: 10px;" *ngIf="showAssessemtError">
                                Your assessment is failed, please re-check all questions.
                            </label>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Cancel
                            </button>
                            <button [disabled]="shouldContinueFromQuestion? false:true" type="button" (click)="ShowAutobooking()"
                                class="btn btn-primary" data-dismiss="modal">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div class="modal fade" id="autoBookingModal" tabindex="-1" role="dialog" aria-labelledby="autoBookingModal"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;
                                    padding: 10px !important;
                                  ">
                            <h5 class="modal-title" id="autoBookingLongTitle">
                                Confirmation
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div class="qstn">
                                <label style="width: 100%;font-weight: 400;font-size: 1rem;"
                                    [innerHTML]="autoBookingMessage"></label>
            
                                <label class="qsta"><input type="radio" name="autoBookingRequired" [value]="true"
                                        [(ngModel)]="autoBookingRequired" />
                                    Yes</label>
                                <label class="qsta"><input type="radio" name="autoBookingRequired" [value]="false"
                                        [(ngModel)]="autoBookingRequired" />
                                    No</label>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" (click)="ShowAddressModal()" class="btn btn-primary" data-dismiss="modal">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="cancelBookingModal" tabindex="-1" role="dialog" aria-labelledby="cancelBookingModal"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;
                                    padding: 10px !important;
                                  ">
                            <h5 class="modal-title" id="cancelBookinModalitle">
                                Confirmation
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div class="qstn">
                                <label style="width: 100%;font-weight: 400;font-size: 1rem;">Do you want to cancel your
                                    appointment?</label>
            
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" (click)="cancelBooking()" class="btn btn-primary" data-dismiss="modal">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            <div class="modal fade" id="underAgeErrorModal" tabindex="-1" role="dialog" aria-labelledby="underAgeErrorgModal"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;
                                    padding: 10px !important;
                                  ">
                            <h5 class="modal-title" id="underAgeErrorModalitle">
                                Error
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div class="qstn">
                                <label style="width: 100%;font-weight: 400;font-size: 1rem;" [innerHTML]="underAgeError"></label>
            
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            
            <div class="modal fade" id="watingConfirmation" tabindex="-1" role="dialog" aria-labelledby="watingConfirmation"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="
                                    margin-bottom: 0 !important;
                                    padding: 10px !important;
                                  ">
                            <h5 class="modal-title" id="watingConfirmationModalitle">
                                Confirmation
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div class="qstn">
                                <label style="width: 100%;font-weight: 400;font-size: 1rem;">
                                    We will send notification once we have available slots.
                                    Do you want to continue?</label>
            
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" (click)="continueWaitingList()" class="btn btn-primary" data-dismiss="modal">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            <div class="modal fade" id="waitingSuccess" tabindex="-1" role="dialog" aria-labelledby="waitingSuccess"
                aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
            
                        <div class="modal-body">
                            <div class="qstn">
                                <label style="width: 100%;font-weight: 400;font-size: 1rem;">
                                    Your waiting registered successfully.
                                </label>
            
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            