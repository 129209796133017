import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { WavesModule } from 'ng-uikit-pro-standard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MylocalrxContentcontainerLoginComponent } from './mylocalrx-contentcontainer-login/mylocalrx-contentcontainer-login.component';
import { MylocalrxContentcontainerHomeComponent } from './mylocalrx-contentcontainer-home/mylocalrx-contentcontainer-home.component';
import { MylocalrxHeaderComponent } from './mylocalrx-header/mylocalrx-header.component';
import { MylocalrxContentcontainerContactusComponent } from './mylocalrx-contentcontainer-contactus/mylocalrx-contentcontainer-contactus.component';
import { MylocalrxNavigationComponent } from './mylocalrx-navigation/mylocalrx-navigation.component';
import { MylocalrxContentcontainerPrescriptionsComponent } from './mylocalrx-contentcontainer-prescriptions/mylocalrx-contentcontainer-prescriptions.component';
import { MylocalrxContentcontainerAppointmentComponent } from './mylocalrx-contentcontainer-appointment/mylocalrx-contentcontainer-appointment.component';
import { MylocalrxContentcontainerServiceComponent } from './mylocalrx-contentcontainer-service/mylocalrx-contentcontainer-service.component';
import { MylocalrxContentcontainerAboutusComponent } from './mylocalrx-contentcontainer-aboutus/mylocalrx-contentcontainer-aboutus.component';
import { MylocalrxFooterComponent } from './mylocalrx-footer/mylocalrx-footer.component';
import { MylocalrxTermsandconditionsComponent } from './mylocalrx-footer/mylocalrx-termsandconditions/mylocalrx.termsandconditions.component';
import { MylocalrxPrivacypolicyComponent } from './mylocalrx-footer/mylocalrx-privacypolicy/mylocalrx-privacypolicy.component';
import { MylocalrxConsentComponent } from './mylocalrx-footer/mylocalrx-consent/mylocalrx-consent.component';
import { MylocalrxScrollbacktotopComponent } from './mylocalrx-scrollbacktotop/mylocalrx-scrollbacktotop.component';
import { PrescriptionComponent } from './mylocalrx-contentcontainer-prescriptions/prescription/prescription.component';
import { PrescriptionListComponent } from './mylocalrx-contentcontainer-prescriptions/prescription-list/prescription-list.component';

import { PrescriptionStatusTransformPipe, PrescriptionDeliveryTypePipe, PrescriptionStatuses } from './shared/pipes/prescription-status.pipe';
import { MatchPasswordDirective } from './directives/match-password.directive';
import { AuthGuard } from './shared/AuthGuard';
import { UserResolver } from './shared/user.resolver';
import { PhoneMaskDirective } from './directives/phone.mask.directive';
import { MylocalrxContentcontainerResetPasswordComponent } from './mylocalrx-contentcontainer-reset-password/mylocalrx-contentcontainer-reset-password.component';
import { MylocalrxContentcontainerFaqsComponent } from './mylocalrx-contentcontainer-faqs/mylocalrx-contentcontainer-faqs.component';
import { MylocalrxContentcontainerProfileComponent } from './mylocalrx-contentcontainer-profile/mylocalrx-contentcontainer-profile.component';
import { InputMaskDirective } from './directives/input.mask';
import { DeSanitizeMobileNumber } from "./directives/DeSanitizeMobileNumber";
import { DeSanitizeOHIPNumber } from "./directives/DeSanitizeOHIPNumber";
import { ImagePreviewListComponent } from './image-preview-list/image-preview-list.component';
import { ImageViewerModule } from 'ngx-image-viewer';
import { LocaltDateTimePipe } from './shared/pipes/localt-date-time.pipe';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GoogleMapsModule } from '@angular/google-maps';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MyBookedAppointmentsComponent } from './mylocalrx-contentcontainer-appointment/my-booked-appointments/my-booked-appointments.component';
import { CovidAppointmentComponent } from './mylocalrx-contentcontainer-appointment/covid-appointment/covid-appointment.component';
import { FamilyMembersComponent } from './family-members/family-members.component';
import { AddNewMemberComponent } from './family-members/add-new-member/add-new-member.component';
import { AddressAutoDirective } from './directives/address-auto.directive';
import { EnterOtpComponent } from './enter-otp/enter-otp.component';
import { TokenInterceptor } from './shared/token.httpIntercepter';
import { HomeListProductsComponent } from './mylocalrx-contentcontainer-home/home-list-products/home-list-products.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { OrderPaymentStatusPipe, OrderStatusPipe } from './shared/pipes/order-status.pipe';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CancelComponent } from './thank-you/cancel/cancel.component';


@NgModule({
  declarations: [
    AppComponent,
    MylocalrxContentcontainerLoginComponent,
    MylocalrxContentcontainerHomeComponent,
    MylocalrxHeaderComponent,
    MylocalrxContentcontainerContactusComponent,
    MylocalrxNavigationComponent,
    MylocalrxContentcontainerAppointmentComponent,
    MylocalrxContentcontainerPrescriptionsComponent,
    MylocalrxContentcontainerServiceComponent,
    MylocalrxContentcontainerAboutusComponent,
    MylocalrxFooterComponent,
    MylocalrxTermsandconditionsComponent,
    MylocalrxPrivacypolicyComponent,
    MylocalrxConsentComponent,
    MylocalrxScrollbacktotopComponent,
    PrescriptionComponent,
    PrescriptionListComponent,
    PrescriptionStatusTransformPipe,
    PrescriptionDeliveryTypePipe,
    MatchPasswordDirective,
    PhoneMaskDirective,
    InputMaskDirective,
    MylocalrxContentcontainerResetPasswordComponent,
    MylocalrxContentcontainerFaqsComponent,
    MylocalrxContentcontainerProfileComponent,
    ImagePreviewListComponent,
    LocaltDateTimePipe,
    DeSanitizeMobileNumber, DeSanitizeOHIPNumber, MyBookedAppointmentsComponent, CovidAppointmentComponent, FamilyMembersComponent, AddNewMemberComponent, AddressAutoDirective, EnterOtpComponent, HomeListProductsComponent, CheckoutComponent, ThankYouComponent, MyOrdersComponent, MyCartComponent, OrderStatusPipe,OrderPaymentStatusPipe, NewHomePageComponent, ProductDetailComponent, CancelComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,ReactiveFormsModule ,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    GoogleMapsModule,
    RouterModule.forRoot([]),
    ImageViewerModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],

  providers: [AuthGuard, UserResolver,{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, DatePipe, DeSanitizeMobileNumber, DeSanitizeOHIPNumber,LocaltDateTimePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
