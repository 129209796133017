import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Pharmacy } from '../shared/models/pharmacy.model';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  public PHARMACY_API = environment.baseUrl + '/pharmacy';
  service = new google.maps.Geocoder();
  constructor(private http: HttpClient) { }

  getAllMyLocarxEnable(prescriptionModuleEnabled: boolean = false): Observable<Pharmacy[]> {
    let result: Observable<any>;

    result = this.http.get(prescriptionModuleEnabled ? `${this.PHARMACY_API}/prescription/enabled` : this.PHARMACY_API);
    return result;
  }
  getLatLonByAddress(addresses: any): Promise<any> {
    if (this.service == null) {
      this.service = new google.maps.Geocoder();
    }
    return new Promise<any>((res, rej) => {
      this.service.geocode({ 'address': addresses }, (results, status)=> {
        if (status == google.maps.GeocoderStatus.OK) {
            res(results[0].geometry.location);
        } else {
          rej("can't find address")
        }
      });
    });
  }
}
