import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { GlobalConstants } from '../shared/global-constants';
import { User } from '../shared/models/user.model';
import { OrderVM } from '../shared/models/order.modal';
declare var $: any;
@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {

  constructor(private product: ProductService) { }

  currentUser: User | null = GlobalConstants.getcurrentUser();
  myOrders:OrderVM[] = [];
  totalAmount = 0;
  ngOnInit(): void {
    $('.csLoader').css({ display: 'flex' });
    this.product.getMyOrder(this.currentUser == null ? GlobalConstants.getCurrentSessionId() : this.currentUser.id).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      this.myOrders = x;
      console.log(this.myOrders);
    }, error => {
      $('.csLoader').css({ display: 'none' });
    });
  }

}
