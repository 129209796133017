import { Component, OnInit ,ViewChild } from '@angular/core';
import { RouterOutlet, Router, ActivationStart } from '@angular/router';

@Component({
  selector: 'mylocalrx-navigation',
  templateUrl: './mylocalrx-navigation.component.html',
  styleUrls: ['./mylocalrx-navigation.component.scss']
})
export class MylocalrxNavigationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  
  }

}
