// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: 'http://localhost:5000' 
  // baseUrl: 'https://testapi.mylocalrx.ca',
  //baseUrl: 'https://api.mylocalrx.ca',
  // STRIP_KEY =Publishable key in this project
  // STRIP_KEY:"pk_test_51LvQBYCBFWyj6Lf3MwxOoEpwQFwXsxvktXwKCP2xxrCapd9br9WSpKLKow2Ca4wC9H4An5ry8RgCW9rxz3u9NNvN00PntfUrW2"
  baseUrl: 'https://wize-health.apps.sterilwize.com',
  // STRIP_KEY:"sk_test_51LvQBYCBFWyj6Lf3mQokg5GEupubnKiW9NSoYLM1GsEVaefF53b3sfwDVvOmlBXRl993Aj6yUn4h4dODFRM5cMcS00PRcuCg5E" 
  STRIP_KEY:"pk_live_51LvQBYCBFWyj6Lf3Q3HHLk1cPGiAuk94zsixRU7gPBDJoevao8SNnaHhz3ovpuWTPKL2IlruKLsiCrVTFLsInDyw00uR0LPiFa" 

};
