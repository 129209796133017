<div class="container" style="width: 95%; margin: 0 auto;">
    <div class="row">
        <div class="col-md-12">
            <h3>My Orders</h3>
        </div>
    </div>
    <div *ngIf="!myOrders || myOrders.length == 0" class="row">
        <a style="margin: 0 auto; padding: 50px;" [routerLink]="['/our-products']">Your order list is empty. Shop
            now</a>
    </div>
    <div *ngIf="myOrders && myOrders.length > 0" class="row">
        <div style="margin-top: 30px;background: #fdfdfd;border: solid 1px #dadada; border-radius: 10px 10px 0px 0px;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;" class="col-md-12" *ngFor="let item of myOrders">
            <div style="width: 100%; ;padding: 10px;padding-left: 15px;">

                <div style="width: 100%;display: flex;justify-content: space-between;">
                    <label><b>Date</b><br /><span style="font-size: 1rem; font-weight: 400;">
                            {{item.createdOn|date:'MMM-dd-yyy'}}</span></label>
                    <label><b>Status</b><br /><span
                            style="font-size: 1rem; font-weight: 400;">{{item.status|orderStatus}}</span> </label>
                    <label><b>Payment</b><br /><span
                            style="font-size: 1rem; font-weight: 400;">{{item.paymentStatus|orderPaymentStatus}}</span>
                    </label>
                    <div></div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div style="float: left;">
                            <b>Address</b><br />
                            <label style="font-size: 0.95rem;">{{item.deliveryAddress.firstName}}
                                {{item.deliveryAddress.lastName}}<br />
                                {{item.deliveryAddress.streetAddress}}<br /> {{item.deliveryAddress.city}},
                                {{item.deliveryAddress.postalCode}}
                            </label>
                        </div>
                        <div style="float: right;">
                            <button *ngIf="item.status < 2" class="btn btn-primary mr-1">Cancel</button>
                            <br />
                            <br />
                            <a *ngIf="item.invoiceUrl" [href]="item.invoiceUrl" target="_blank">
                                <i class="fa fa-eye"></i> View Reciepts
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pOu">
                <div class="pList" *ngFor="let pr of item.products">
                    <div class="row">
                        <div class="col-md-6">
                            <label style="font-size: 0.9rem; font-weight: 400;">{{pr.name}} <span
                                    style="color: var(--primary); font-weight: 500;"> @
                                    {{pr.finalPrice|currency}}</span></label>
                        </div>
                        <div class="col-md-6">
                            <label style="display: flex; justify-content: space-between;">
                                <span>
                                    <b>Price</b><br />
                                    {{pr.finalPrice|currency}}</span>
                                <span><b>Qty</b><br />{{pr.qty}}</span>
                                <span><b>Amount</b><br />{{(pr.finalPrice*pr.qty)|currency}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <label style="padding: 15px;font-size: 1.2rem;">
                    <span style="font-size: 0.95rem;">
                        Payment: <b> {{item.paymentMethod}} </b>
                    </span>
                    <span style="font-weight: 500; float: right;">
                        <b style="font-size: 1.2rem;"> Total:
                        </b> {{item.totalAmount|currency}}
                    </span>
                </label>
            </div>
        </div>
    </div>
</div>