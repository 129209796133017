<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-12 col-md-5 col-lg-6">
        <!-- Image -->
        <img
          src="./../../assets/svg/img 8.svg"
          class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..."
          data-aos="fade-up"
          data-aos-delay="100"
          width="50%"
          height="100%"
        />
      </div>
      <div
        class="col-xl-12 col-md-7 col-lg-6 aos-init aos-animate"
        data-aos="fade-up"
      >
        <h3 class="py-4">Consent</h3>
        <h5>Welcome</h5>
        <p>
          This Consent Policy is designed to inform you of our practices
          regarding collection, use and disclosure of information that you may
          provide via this site. Please read this entire Consent Policy before
          using or submitting information to this site. This site is intended
          for use by residents of Canada.
        </p>
        <h5>Your Consent</h5>
        <p>
          By using this site, you agree with the terms of this Consent Policy.
          Whenever you submit information via this site, you consent to the
          collection, use and disclosure of that information in accordance with
          this Consent Policy.
        </p>
        <h5>About Children</h5>
        <p>
          This site is not intended for children under the age of 13. We will
          not knowingly collect information from site visitors in this age
          group. We encourage parents to talk to their children about their use
          of the Internet and the information they disclose to Web sites.
        </p>
        <h5>Active Information Collection</h5>
        <p>
          Like many Web sites, this site actively collects information from its
          visitors both by asking specific questions and by permitting them to
          communicate directly with us via e-mail, feedback forms and/or chat
          rooms. Some of the information that you submit may be personally
          identifiable information (that is, information that can be uniquely
          identified with you, such as your full name, address, e-mail address,
          phone number etc.). Some areas of this site may require you to submit
          information in order for you to benefit from the specified features
          (such as newsletter subscriptions, tips/pointers and order processing)
          or to participate in a particular activity (such as sweepstakes or
          other promotions). You will be informed at each information collection
          point of what information is required and what information is
          optional.
        </p>
        <h5>Passive Information Collection</h5>
        <p>
          As you navigate through a Web site, certain information can be
          passively collected (i.e., gathered without you actively providing the
          information) using various technologies and means, such as Internet
          Protocol addresses, "cookies", Internet tags and navigational data
          collection.
        </p>
        <p>
          An Internet Protocol (IP) address is a number assigned to your
          computer by your Internet service provider so you can access the
          Internet. It is generally considered to be non-personally identifiable
          information, because in most cases an IP address is dynamic (changing
          each time you connect to the Internet), rather than static (unique to
          a particular user's computer). We use IP addresses on this site. We
          use your IP address to diagnose problems with our server, report
          aggregate information, determine the fastest route for your computer
          to use when connecting to our site and administer and improve the
          site.
        </p>
        <p>
          A "cookie" is a small text file that a Web site sends to your Web
          browser that helps the site remember information about you and your
          preferences. "Session cookies" are temporary text files that are
          erased once you exit your Web browser window or otherwise turn your
          computer off. Session cookies are used to improve navigation on Web
          sites and to collect aggregate statistical information. This site uses
          session cookies. "Persistent cookies" are more permanent text files
          that are placed on the hard drive of your computer and stay there
          unless you delete them. Persistent cookies store information on your
          computer for a number of purposes, such as retrieving certain
          information you have previously provided (e.g., passwords), helping to
          determine what areas of the Web site visitors find most valuable and
          customizing the Web site based on your preferences. This site uses
          persistent cookies.
        </p>
        <p>
          Internet tags ("single-pixel GIFs", "clear GIFs", "invisible GIFs" and
          "1-by-1 GIFs") are smaller files than cookies and tell the Web site
          server information such as the IP address and browser type related to
          the visitor's computer. This site uses Internet tags. Tags have been
          placed both in on-line advertisements that bring people to the site
          and on different pages of the site. These tags indicate how many times
          a page is opened and what information is consulted. We do not collect
          or seek personally identifiable information through these tags.
        </p>
        <p>
          Navigational data ("log files", "server logs" and "click stream data")
          is used for system management, to improve the content of the site, for
          market research purposes and to communicate information to visitors.
          This site uses navigational data.
        </p>
        <h5>Use and Disclosure of Information</h5>
        <p>
          Except as otherwise stated, we may use your information to improve the
          content of our site, to customize the site to your preferences, to
          communicate information to you (if you have requested it), for our
          marketing and research purposes and for the purposes specified in this
          Consent Policy. If you provide personally identifiable information to
          this site, we may combine such information with other actively
          collected information unless we specify otherwise at the point of
          collection. We will take reasonable measures to prevent personally
          identifiable information from being combined with passively collected
          information, unless you consent otherwise. We may disclose your
          personally identifiable information to our affiliates that agree to
          treat it in accordance with this Consent Policy. In addition, we may
          disclose your personally identifiable information to third parties,
          but only:
        </p>
        <ul>
          <li>
            to contractors we use to support our business (e.g., fulfillment
            services, technical support, delivery services and financial
            institutions), in which case we will require such third parties to
            agree to treat it in accordance with this Consent Policy;
          </li>
          <li>
            in connection with the sale, assignment or other transfer of the
            business of this site to which the information relates, in which
            case we will require any such buyer to agree to treat it in
            accordance with this Consent Policy;
          </li>
          <li>
            where required by applicable laws, court orders or government
            regulations.
          </li>
          <li>
            In addition, we will make full use of all information acquired
            through this site that is not in personally identifiable form.
          </li>
        </ul>
        <h5>Access and Corrections</h5>
        <p>
          To keep your personally identifiable information accurate, current and
          complete, please contact us as specified below. We will take
          reasonable measures to update or correct personally identifiable
          information in our possession previously submitted via this site.
        </p>
        <h5>Security</h5>
        <p>
          We take reasonable measures to protect your personally identifiable
          information as you transmit your information from your computer to our
          site and to protect such information from loss, misuse, and
          unauthorized access, disclosure, alteration or destruction. You should
          keep in mind that no Internet transmission is ever 100% secure or
          error-free. In particular, e-mail sent to or from this site may not be
          secure, and you should therefore take special care in deciding what
          information you send to us via e-mail. Moreover, where you use
          passwords, ID numbers, or other special access features on this site,
          it is your responsibility to safeguard them.
        </p>
        <h5>Links to Other Web Sites</h5>
        <p>
          This site may contain links or references to other Web sites. Please
          be aware that we do not control other Web sites and that, in any case,
          this Consent Policy does not apply to those Web sites. We encourage
          you to read the privacy policy of every Web site you visit.
        </p>
        <h5>Our Terms</h5>
        <p>Your use of this site is subject to our Disclaimer.</p>
        <h5>How to Contact Us</h5>
        <p>
          If you have any questions, comments, or concerns about this Consent
          Policy or the information practices of this site, please contact our
          Privacy Officer.
        </p>
      </div>
    </div>
  </div>
</section>
