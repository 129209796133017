<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-1">
        <!-- Image -->
        <img
          src="./../../assets/svg/img 7.svg"
          class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..."
          data-aos="fade-up"
          data-aos-delay="100"
          width="100%"
          height="100%"
        />
      </div>
      <div
        class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate"
        data-aos="fade-up"
      >
        <h3 class="py-4">About Us</h3>
        <p class="lead mb-5">
          Mylocalrx has been developed by a group of 3 pharmacists with the
          patient in mind. During the height of the COVID-19 pandemic we
          realized that for many people it was difficult to get necessary
          prescriptions from their doctor to the pharmacy, and harder still to
          get the medication home from the pharmacy. Mylocalrx is an end to end
          solution for your medication needs. Simply upload a picture of your
          prescription to your profile and select your pharmacy of choice. The
          pharmacy is notified that you have uploaded a prescription. Once your
          prescription is ready you get a notification that you can go and
          collect it or get it delivered. Along with this, all our member
          pharmacies offer appointments for individual medication consultations,
          flu shots, vaccinations (including COVID once available).
        </p>
      </div>
    </div>
  </div>
</section>
