import { Injectable } from '@angular/core';

import { Condition } from '../shared/models/schedual.modal';

@Injectable({
  providedIn: 'root'
})
export class DynamicConditionValidationService {

 private properties = [{ id: 'age', value: 'Patient age' }, { id: 'gender', value: 'Patient gender' }];
private  listConditionTypes = [{ id: 0, value: 'EQUALS_TO' }, { id: 1, value: 'GREATER_THAN' },
  { id: 2, value: 'GREATER_THAN_EQUALS_TO' }, { id: 3, value: 'LESS_THAN' },
  { id: 4, value: 'LESS_THAN_EQUALS_TO' }];
  constructor() { }


  validateCondition(conditions: Condition[], val: any): string {
    var msg = "";
    if (val == undefined || val == null) {
      return "Invalid input value.";
    }
    if (conditions && conditions.length > 0) {
      conditions.forEach(element => {
        if (msg == "") {
          msg = this.validate(element, val);
          if (msg != "") {
            console.error(msg);
            msg = element.errorMessage;
          }
        }
      });

    }

    return msg;
  }
  private validate(condition: Condition, val: any): string {
    var msg = "";
    switch (condition.propertyName) {
      case "age": {
        msg = this.validateAge(condition, val);
        break;
      }
      case "gender": {
        msg = this.validateGender(condition, val);
        break;
      }
      default: break;
    }

    return msg;
  }
  private validateAge(condition: Condition, val: any): string {
    var msg = "";
    if (val.age == undefined || val.age == null) {
      return "Age property is not defined in val.";
    }

    var mdy = val.age.split('/');
    var dt1 = new Date(parseInt(mdy[2]), (parseInt(mdy[0]) - 1), parseInt(mdy[1]), 23, 59, 59);
    var currentDate = new Date()
    currentDate.setHours(23, 59, 59);

    dt1.setFullYear(dt1.getFullYear() + parseInt(condition.propertyValueMustBe));
    msg = this.compareValue(condition, currentDate, dt1);
    return msg;
  }
  private validateGender(condition: Condition, val: any): string {
    var msg = "";
    if (val.gender == undefined || val.gender == null) {
      return "Gender property is not defined in val.";
    }

    msg = this.compareValue(condition, condition.propertyValueMustBe.toLowerCase(), val.gender.toLowerCase());
    return msg;
  }
  private compareValue(condition: Condition, inp: any, to: any): string {
    var msg = "";
    switch (condition.condition) {
      case 0: {
        msg = inp === to ? "" : "Values are not equal.";
        break;
      }
      case 1: {
        msg = inp > to ? "" : "Input is not greater.";
        break;
      }
      case 2: {
        msg = inp >= to ? "" : "Input is not greater or equals to.";
        break;
      }
      case 3: {
        msg = inp < to ? "" : "Input is not lesser.";
        break;
      }
      case 4: {
        msg = inp < to ? "" : "Input is not lesser or equals to.";
        break;
      }
      default: break;
    }
    return msg;
  }
}
