import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MylocalrxContentcontainerLoginComponent } from './mylocalrx-contentcontainer-login/mylocalrx-contentcontainer-login.component';
import { MylocalrxContentcontainerHomeComponent } from './mylocalrx-contentcontainer-home/mylocalrx-contentcontainer-home.component';
import { MylocalrxContentcontainerAppointmentComponent } from './mylocalrx-contentcontainer-appointment/mylocalrx-contentcontainer-appointment.component';
import { MylocalrxContentcontainerPrescriptionsComponent } from './mylocalrx-contentcontainer-prescriptions/mylocalrx-contentcontainer-prescriptions.component';
import { PrescriptionComponent } from './mylocalrx-contentcontainer-prescriptions/prescription/prescription.component';
import { PrescriptionListComponent } from './mylocalrx-contentcontainer-prescriptions/prescription-list/prescription-list.component';
import { MylocalrxContentcontainerServiceComponent } from './mylocalrx-contentcontainer-service/mylocalrx-contentcontainer-service.component';
import { MylocalrxContentcontainerAboutusComponent } from './mylocalrx-contentcontainer-aboutus/mylocalrx-contentcontainer-aboutus.component';
import { MylocalrxContentcontainerContactusComponent } from './mylocalrx-contentcontainer-contactus/mylocalrx-contentcontainer-contactus.component';
import { MylocalrxFooterComponent } from './mylocalrx-footer/mylocalrx-footer.component';
import { MylocalrxTermsandconditionsComponent } from './mylocalrx-footer/mylocalrx-termsandconditions/mylocalrx.termsandconditions.component';
import { MylocalrxPrivacypolicyComponent } from './mylocalrx-footer/mylocalrx-privacypolicy/mylocalrx-privacypolicy.component';
import { MylocalrxConsentComponent } from './mylocalrx-footer/mylocalrx-consent/mylocalrx-consent.component';
import { AuthGuard } from './shared/AuthGuard';
import { UserResolver } from './shared/user.resolver';
import { MylocalrxContentcontainerResetPasswordComponent } from './mylocalrx-contentcontainer-reset-password/mylocalrx-contentcontainer-reset-password.component';
import { MylocalrxContentcontainerFaqsComponent } from './mylocalrx-contentcontainer-faqs/mylocalrx-contentcontainer-faqs.component';
import { MylocalrxContentcontainerProfileComponent } from './mylocalrx-contentcontainer-profile/mylocalrx-contentcontainer-profile.component';
import { CovidAppointmentComponent } from './mylocalrx-contentcontainer-appointment/covid-appointment/covid-appointment.component';
import { FamilyMembersComponent } from './family-members/family-members.component';
import { EnterOtpComponent } from './enter-otp/enter-otp.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeListProductsComponent } from './mylocalrx-contentcontainer-home/home-list-products/home-list-products.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CancelComponent } from './thank-you/cancel/cancel.component';


const routes: Routes = [
  {
    path: '',
    component: NewHomePageComponent,
  },
  {
    path: 'login/:id',
    component: MylocalrxContentcontainerLoginComponent, canActivate: [AuthGuard],
  },
  {
    path: 'enter-otp/:id',
    component: EnterOtpComponent,
  },
  {
    path: 'prescription',
    component: PrescriptionComponent, resolve: { data: UserResolver },
    children: [
      {
        path: 'prescription-upload',
        component: MylocalrxContentcontainerPrescriptionsComponent,
      },
      {
        path: 'prescription-list',
        component: PrescriptionListComponent,
      }
    ]

  },
  {
    path: 'MylocalrxContentcontainerFaqsComponent',
    component: MylocalrxContentcontainerFaqsComponent,
  },
  {
    path: 'MylocalrxContentcontainerAppointmentComponent',
    component: MylocalrxContentcontainerAppointmentComponent, resolve: { data: UserResolver }
  },
  {
    path: 'MylocalrxContentcontainerServiceComponent',
    component: MylocalrxContentcontainerServiceComponent,
  },
  {
    path: 'MylocalrxContentcontainerAboutusComponent',
    component: MylocalrxContentcontainerAboutusComponent,
  },
  {
    path: 'register',
    component: MylocalrxContentcontainerLoginComponent, canActivate: [AuthGuard]
  },
  {
    path: 'MylocalrxContentcontainerContactusComponent',
    component: MylocalrxContentcontainerContactusComponent,
  },
  {
    path: 'MylocalrxTermsandconditionsComponent',
    component: MylocalrxTermsandconditionsComponent,
  },
  {
    path: 'MylocalrxPrivacypolicyComponent',
    component: MylocalrxPrivacypolicyComponent,
  },
  {
    path: 'MylocalrxConsentComponent',
    component: MylocalrxConsentComponent,
  },
  // {
  //   path: 'MylocalrxFooterComponent',
  //   component: MylocalrxFooterComponent,
  //   children: [
  //     {
  //       path: 'MylocalrxTermsandconditionsComponent',
  //       component: MylocalrxTermsandconditionsComponent,
  //     },
  //     {
  //       path: 'MylocalrxPrivacypolicyComponent',
  //       component: MylocalrxPrivacypolicyComponent,
  //     },
  //     {
  //       path: 'MylocalrxConsentComponent',
  //       component: MylocalrxFooterComponent,
  //     },
  //   ],
  // },
  {
    path: 'reset',
    component: MylocalrxContentcontainerResetPasswordComponent,
  }, {
    path: 'checkout',
    component: CheckoutComponent,
  },{
    path: 'cart',
    component: MyCartComponent,
  }, {
    path: 'our-products',
    component: HomeListProductsComponent,
  },{
    path: 'product/:id/:name',
    component: ProductDetailComponent,
  },
  {
    path: 'orders/my',
    component: MyOrdersComponent,
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
  },
  {
    path: 'cancel/:id',
    component:CancelComponent,
  },
  {
    path: 'MylocalrxContentcontainerProfileComponent',
    component: MylocalrxContentcontainerProfileComponent, resolve: { data: UserResolver }
  },
  {
    path: 'appointment/covid/:id',
    component: CovidAppointmentComponent, resolve: { data: UserResolver }
  },
  {
    path: 'family-members',
    component: FamilyMembersComponent, resolve: { data: UserResolver }
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
export const routingComponents = [
  MylocalrxContentcontainerHomeComponent,
  MylocalrxContentcontainerPrescriptionsComponent,
  MylocalrxContentcontainerAppointmentComponent,
  MylocalrxContentcontainerServiceComponent,
  MylocalrxContentcontainerAboutusComponent,
  MylocalrxContentcontainerLoginComponent,
  MylocalrxContentcontainerContactusComponent,
  MylocalrxFooterComponent,
  MylocalrxTermsandconditionsComponent,
  MylocalrxPrivacypolicyComponent,
  MylocalrxConsentComponent,
];
