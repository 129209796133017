<div style="padding: 120px 15px;" class="container">
        <div class="row">
            <div class="col-lg-4" style="float:none;margin:auto; max-width: 320px;">
                <div *ngIf="!isvalidToken"  class="alert alert-danger" role="alert">
                  <i class="fa fa-exclamation-triangle"></i>  Token is invalid or expired. <a href="javascript:void(0)" (click)="resendLink()" >Click to resend. </a>
                  </div>
                  <div *ngIf="isPasswordResetDone"  class="alert alert-success" role="alert">
                  <i class="fa fa-thumbs-up"></i>  Your password has been changed. <a href="javascript:void(0)" (click)="resendLink()" >Click to login. </a>
                  </div>
                <form *ngIf="isvalidToken && !isPasswordResetDone"  class="form-reset" #resetForm="ngForm" (ngSubmit)="reset(resetForm)" >
                 <h3>Reset passowd</h3>
                    <div class="form-label-group">
                        <input id="password" type="password" name="password" #password placeholder="Enter Password"
                            [(ngModel)]="user.newPassword" class="form-control form-control-sm" required pattern=".{4,}" />
                        <label for="password">Enter Password <span style="display: none;">*</span></label>
                    </div>
    
    
                    <div class="form-label-group">
                        <input [(ngModel)]="user.confirmPassword" type="password" id="confirmPassword" #confirmPassword
                            name="confirmPassword" class="form-control form-control-sm" placeholder="Confirm Password" validateEqual="password"
                            required pattern=".{4,}" />
                        <label for="confirmPassword">Confirm Password <span style="display: none;">*</span></label>
                    </div>
    
                    <button [disabled]="resetForm.invalid" class="btn  btn-primary btn-block text-uppercase" type="submit">
                        Change Password
                    </button>
                </form>
            </div>
        </div>
    </div>