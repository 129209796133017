export interface SiteConfig {
    id: any;
    active: boolean;
    configType: SiteConfigTpe
}

export enum SiteConfigTpe {
    MARQUEE,
    MARQUEE_ADMIN,
    ASYMPTOMATIC_TEST_PRICE
}