<div style="width: 320px; margin: 0 auto; margin-top: 10%;">
    <label style="font-size: 17px;color: gray; font-weight: 400;margin-bottom: 15px;">
        OTP has been sent to your <br/><span *ngIf="isEmail">email id </span><span *ngIf="!isEmail">phone number </span>: <b>{{id}}</b></label>
    <form  [formGroup]="myForm"     (ngSubmit)="otpForm()" >
        <div class="form-group">
            <label style="color: gray;" for="opt">Enter six digit OTP here... </label>
        <input [(ngModel)]="token"   formControlName="opt" class="form-control"  pattern="^\d{6}$"  name="opt" id="opt" placeholder="Enter OTP" />
        </div>
        <button  [disabled]="myForm.invalid" style="margin-top: 20px;" class="btn btn-primary btn-block text-uppercase" type="submit">
            Continue
        </button>
    </form>
</div>