import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  addMinutes,
} from 'date-fns';
import { AppointmentService } from 'src/app/services/appointment.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { Appointment } from 'src/app/shared/models/Appointment';
import { LocaltDateTimePipe } from 'src/app/shared/pipes/localt-date-time.pipe';
declare var $: any;
@Component({
  selector: 'app-my-booked-appointments',
  templateUrl: './my-booked-appointments.component.html',
  styleUrls: ['./my-booked-appointments.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class MyBookedAppointmentsComponent implements OnInit {

  constructor( private appointmentService: AppointmentService,private datePipe: DatePipe, private localtDateTimePipe: LocaltDateTimePipe) { }

  currentUser = GlobalConstants.getcurrentUser();
  ngOnInit(): void {
    this.appointmentService.getMyAppoinments(this.currentUser?.id!).subscribe(x => {
      this.myAppointments = x;
      console.log(this.myAppointments);
      this.myAppointments.forEach(element => {
        this.events = [
          ...this.events,
          {
            start: this.localtDateTimePipe.transform(element.timeslot),
            end: addMinutes(this.localtDateTimePipe.transform(element.timeslot), 15),
            title: `You have an appointment  at ${this.datePipe.transform(this.localtDateTimePipe.transform(element.timeslot), "hh:mm a")}<br/> ${element.slotName}<br/>`,
            color: this.colors.blue,
            allDay: false,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
          },
        ];
      });
      this.cal_cell();
    });
  }
  ///https://angular-calendar.com/#/kitchen-sink
  myAppointments: Appointment[] = [];
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  viewDate: Date = new Date();
  colors: any = {
    red: {
      primary: '#f20505',
      secondary: '#f20505',
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
  };
  events: CalendarEvent[] = [
  ];

  activeDayIsOpen: boolean = false;
 
  cal_cell() {
    setTimeout(() => {
      $('.cal-month-view .cal-header .cal-cell').each(function (i: any, obj: any) {
        $(obj).text($(obj).text().trim().substr(0, 3).toUpperCase());
      });
       $('.cal-week-view .cal-day-headers .cal-header').each(function (i: any, o: any) {
         var obj = $(o).find('b');
         console.log(obj);
        $(obj).text($(obj).text().trim().substr(0, 3).toUpperCase());
      });
    }, 60);
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  setView(view: CalendarView) {
    this.view = view;
    this.cal_cell();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.cal_cell();
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }
  handleEvent(action: string, event: CalendarEvent): void {

  }

}
