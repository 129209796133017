<div class="container">
    <div class="row" *ngIf="listCart.length > 0">
        <div class="col-md-12">
            <h2 class="chkPr">One Step Checkout</h2>

        </div>
    </div>
    <div class="row" *ngIf="listCart.length == 0">
        <a style="margin: 0 auto; padding: 50px;" [routerLink]="['/our-products']">Your cart is empty. Shop now</a>
    </div>
    <div class="row" *ngIf="listCart.length > 0">
        <div class="col-md-3" *ngIf="currentUser != null">
            <div class="ffm">
                <h6 class="hddr">
                    Select your address
                </h6>
                <div *ngIf="addresses" class="row deliveryTime addresses">

                    <div *ngFor="let delAddress of addresses let i = index" class="col-md-12">
                        <input (change)="AddressChanged()" type="radio" [(ngModel)]="isDeliveryAddressNew"
                            name="delAddress" id="oldAddress_{{i}}" [value]="i" />
                        <label for="oldAddress_{{i}}">
                            <div>{{delAddress.firstName}} {{delAddress.lastName}}</div>
                            <div>{{delAddress.streetAddress}}</div>
                            <div>{{delAddress.city}}-{{delAddress.postalCode}}</div>
                        </label>

                    </div>
                    <div class="col-md-12">
                        <input type="radio" (change)="AddressChanged()" [(ngModel)]="isDeliveryAddressNew"
                            name="delAddress" id="delAddressNew" [value]="-1" />
                        <label for="delAddressNew">
                            <div>Add new</div>
                            <div>Delivery address</div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3" *ngIf="currentUser == null">
            <div class="ffm">
                <p style="margin-top: 15px;">Please enter your details below to complete your purchase.</p>
                <a [routerLink]="['/login/checkout']">Already have an account? Click here to login</a>
                <form style="margin-top: 20px;" #singUpDetailForm="ngForm">
                    <div class="form-group">
                        <label for="email" class="control-label">E-mail Address *</label>
                        <input type="email" class="form-control form-control-sm" id="email"
                            [(ngModel)]="singUpDetail1.email" name="email1" required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="Enter your e-mail address" />
                        <span>*</span>
                    </div>
                    <!-- <div class="form-group">
                        <label for="mobile" class="control-label">Mobile Number *</label>
                        <input type="tel" class="form-control form-control-sm" id="mobile"
                            [(ngModel)]="singUpDetail1.mobile" name="mobile" appPhoneMask required minlength="16"
                            maxlength="17" placeholder="Enter your mobile number" />
                        <span>*</span>
                    </div> -->
                    <div class="form-group">
                        <input type="checkbox" name="create_account" id="create_account"
                            [(ngModel)]="singUpDetail1.createAccount" />
                        <label style="display: inline; margin-left: 5px; cursor: pointer;" for="create_account"
                            class="control-label">Create account</label>
                    </div>
                    <div *ngIf="singUpDetail1.createAccount">
                        <div class="form-group">
                            <label for="password" class="control-label">Password *</label>
                            <input id="password" type="password" name="password" #password placeholder="Enter Password*"
                                [(ngModel)]="singUpDetail1.password" class="form-control form-control-sm" required
                                pattern=".{4,}" minlength="8" maxlength="14" />
                            <span>*</span>
                            <small id="passwordHelpBlock" class="form-text text-muted">
                                Your password must be 8-14 characters long and must not contain
                                spaces.
                            </small>
                        </div>

                        <div class="form-group">
                            <label for="confirmPassword" class="control-label">Confirm Password *</label>
                            <input [(ngModel)]="singUpDetail1.confirmPassword" type="password" id="confirmPassword"
                                #confirmPassword validateEqual="password" name="confirmPassword"
                                class="form-control form-control-sm" placeholder="Confirm Password*" required
                                pattern=".{4,}" />
                            <span>*</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-4">
            <div class="ffm">
                <form #shippingAddressForm="ngForm">
                    <h6 class="hddr">
                        Shipping address
                    </h6>
                    <div class="col">
                        <div class="form-group">
                            <label for="shpfirstname" class="control-label">First Name *</label>
                            <input type="text" class="form-control form-control-sm" id="shpfirstname"
                                [(ngModel)]="shippingAddress1.firstName" name="shpfirstname" required pattern=".{3,}"
                                placeholder="Enter your first name" />
                            <span>*</span>
                        </div>
                        <div class="form-group">
                            <label for="shplastname" class="control-label">Last Name *</label>
                            <input type="text" class="form-control form-control-sm" id="shplastname"
                                [(ngModel)]="shippingAddress1.lastName" name="shplastname" required pattern=".{3,}"
                                placeholder="Enter your last name" />
                            <span>*</span>
                        </div>

                        <div class="form-group">
                            <label for="spunitNo" class="control-label">House No.</label>
                            <input type="text" class="form-control form-control-sm"
                                [(ngModel)]="shippingAddress1.unitNo" id="spunitNo" name="spunitNo"
                                placeholder="Enter your house number" />
                        </div>

                        <!-- <div class="form-group">
                            <label for="buildingName" class="control-label">Building Name</label>
                            <input type="text" class="form-control form-control-sm"
                                [(ngModel)]="shippingAddress1.buildingName" id="buildingName" name="buildingName"
                                placeholder="Enter your building name" />
                        </div> -->

                        <div class="form-group">
                            <label for="spstreetAddress" class="control-label">Street Address *</label>
                            <input appAddressAuto (onSelect)="googleAddressSelected($event)" required pattern=".{4,}"
                                type="text" class="form-control form-control-sm" id="spstreetAddress"
                                [(ngModel)]="shippingAddress1.streetAddress" name="spstreetAddress"
                                placeholder="Enter your street address" />
                            <span>*</span>
                        </div>

                        <div class="form-group half-width">
                            <label for="spcity" class="control-label">City *</label>
                            <input required pattern=".{4,}" type="text" class="form-control form-control-sm" id="spcity"
                                [(ngModel)]="shippingAddress1.city" name="spcity" placeholder="Enter your city" />
                            <span>*</span>
                        </div>
                        <div class="form-group half-width" style="float: right;">
                            <label for="sppostalCode" class="control-label">Postal Code *</label>
                            <input appInputMask [format]="'### ###'" required minlength="7" maxlength="7" type="text"
                                class="form-control form-control-sm" id="sppostalCode" name="sppostalCode"
                                [(ngModel)]="shippingAddress1.postalCode" placeholder="Enter your postal code" />
                            <span>*</span>
                        </div>
                        <div class="form-group" style="    display: table;">
                            <i style="color:#f20505;font-size: 1rem;    display: table;"> * Delivery is only available within Brampton</i>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-md-5">
            <div class="ffm">
                <!-- <h6 class="hddr">
                    Payment Method
                </h6>
                <div style="width: 100%;">
                    <div class="form-group">
                        <input type="radio" value="COD" name="paymentMethod" id="paymentMethodCod"
                            [(ngModel)]="paymentMethod" />
                        <label style="display: inline; margin-left: 5px; cursor: pointer;" for="paymentMethodCod"
                            class="control-label">Cash On Delivery</label>
                    </div>
                    <div class="form-group">
                        <input value="STRIPE" type="radio" name="paymentMethod" id="paymentMethodStripe"
                            [(ngModel)]="paymentMethod" />
                        <label style="display: inline; margin-left: 5px; cursor: pointer;" for="paymentMethodStripe"
                            class="control-label">Credit or debit card </label>
                    </div>
                </div> -->
                <!-- <div style="width: 100%;" [ngStyle]="{'display': paymentMethod == 'STRIPE' ? 'block':'none'}">
                    <div class="form-group">
                        <div style="width: 100%;" id="card-element">
                        </div>
                        <div style="color: #fa755a;
                        font-size: 0.9rem;" id="card-errors" role="alert"></div>
                    </div>
                </div> -->
                <h6 class="hddr">
                    Order Summary
                </h6>
                <div *ngFor="let item of listCart" class="cartRow">
                    <div class="prImage">
                        <img style="width: 100%;" src="{{item.product.productImages[0]}}" />
                    </div>
                    <label class="cpName form-control-sm">{{item.product.productName}} <span
                            style="color:var(--primary)">@ {{item.product.discountPrice|currency}} </span></label>

                    <div class="myProduct">
                        <div class="pQty">
                            <span (click)="updateQty(item.product,-1)"
                                style="border-bottom-right-radius: 0; border-top-right-radius: 0;">-</span>
                            <label>{{item.qty}}</label>
                            <span (click)="updateQty(item.product,1)"
                                style="border-top-left-radius: 0;border-bottom-left-radius: 0;">+</span>
                        </div>
                    </div>
                    <br />

                    <span style="float: right;">{{(item.qty*item.product.discountPrice)|currency}}</span>
                </div>
                <label style="margin-bottom:0; border: none;    color: #302f2f; font-size: 1rem" class="ttlAmount">
                    <span>Sub Total :</span> <span
                        style="margin-left: 15px; font-weight: 500; font-size: 1rem;    width: 120px;
                        display: inline-block;">{{subtotal|currency}}</span>
                </label>
                <label  style="margin:0; border: none;   color: #302f2f; font-size: 1rem"  class="ttlAmount">
                    <span>{{taxRate}}% HST :</span> <span
                        style="margin-left: 15px; font-weight: 500; font-size: 1rem;    width: 120px;
                        display: inline-block;">{{taxtAmount|currency}}</span>
                </label>
                <label  style="margin:0; border: none;   color: #302f2f; font-size: 1rem"  class="ttlAmount">
                    <span>Delivery Charges :</span> <span
                        style="margin-left: 15px; font-weight: 500; font-size: 1rem;    width: 120px;
                        display: inline-block;">{{deliveryCharge|currency}}</span>
                </label>
                <label style="margin-top:0" class="ttlAmount">
                    <b>Total Amount :</b> <span
                        style="margin-left: 15px; font-weight: 500; font-size: 1.2rem;    width: 120px;
                        display: inline-block;">{{totalAmount|currency}}</span>
                </label>
                <button style="width: 220px; float: right;margin-bottom: 20px;" class="btn btn-primary mr-1"
                    (click)="placeOrder()">Place
                    Order</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="confirm-cancel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Error</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{errorMessage}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    Ok
                </button>
            </div>
        </div>
    </div>
</div>