import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../shared/global-constants';
import { CartVM } from '../shared/models/cart.model';
import { OrderVM } from '../shared/models/order.modal';
import { ProductVM } from '../shared/models/product.modal';

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  PRODUCT_API = environment.baseUrl + '/product';
  CART_API = environment.baseUrl + '/cart';
  ORDER_API = environment.baseUrl + '/orders';


  currentCart = new BehaviorSubject<CartVM[]>(GlobalConstants.cartList!);

  constructor(private http: HttpClient) { }

  addProduct(data: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.PRODUCT_API, data);
    return result;
  }
getById(id:string): Observable<ProductVM> {
    let result: Observable<any>;
    result = this.http.get(this.PRODUCT_API+"/detail/"+id);
    return result;
  }
  getAllProducts(): Observable<[ProductVM]> {
    let result: Observable<any>;
    result = this.http.get(this.PRODUCT_API+"/frontend");
    return result;
  }

  getMyCart(): Observable<[CartVM]> {
    let result: Observable<any>;
    var user = GlobalConstants.getcurrentUser();
    result = this.http.get(this.CART_API + "/my?userId=" + (user != null ? user!.id : "") + "&userUID=" + GlobalConstants.getCurrentSessionId());
    return result;
  }
  addToCart(data: any): Observable<any> {
    data.userUID = GlobalConstants.getCurrentSessionId();
    var user = GlobalConstants.getcurrentUser();
    data.userId = user != null ? user.id : "";
    let result: Observable<any>;
    result = this.http.post(this.CART_API + "/add", data);
    return result;
  }
  updateCart(id: any, qty: number): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.CART_API + "/update?id=" + id + "&qty=" + qty, null);
    return result;
  }
  mapMyCart(userUID: string, userId: string): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.CART_API + "/map?userId=" + userId + "&userUID=" + userUID, null);
    return result;
  }
  deleteCart(id: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.CART_API + "/delete?id=" + id, null);
    return result;
  }
  placeOrder(data: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.post(this.ORDER_API + "/add", data);
    return result;
  }
  getMyOrder(userId: String): Observable<[OrderVM]> {
    let result: Observable<any>;
    result = this.http.get(this.ORDER_API + "/myorder/" + userId);
    return result;
  }
}
