<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-6 order-md-1">

        <app-my-booked-appointments></app-my-booked-appointments>

      </div>
      <div class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate" data-aos="fade-up">
        <h3 style="    padding-top: 0 !important;" class="py-4">Book an Appointment</h3>
        <form>
          <div class="form-group">
            <label for="pharmacyID">Pharmacy</label>
            <select (change)="pharmacyChanged()" class="form-control" id="pharmacyID" name="pharmacyID" #pharmacyID
              [(ngModel)]="newAppointment.pharmacyID">
              <option selected disabled [value]="-1" selected>Select Pharmacy</option>
              <option *ngFor="let data of pharmacies" value="{{data.id}}">
                {{ data.name }}
              </option>
            </select>
          </div>
          <div *ngIf="services && services.length > 0" class="form-group">
            <label for="serviceID">Service</label>
            <select class="form-control" id="serviceID" name="serviceID" #serviceID
              [(ngModel)]="newAppointment.serviceID" (change)="serviceChange()">
              <option selected disabled [value]="-1" selected>Select service</option>
              <option *ngFor="let service of services" [value]="service.id">
                {{ service.serviceName }}
              </option>
            </select>
          </div>
          <div [ngClass]="newAppointment.serviceID != '-1' && newAppointment.pharmacyID != '-1' ? 'show':'hide'"
            class="form-group">
            <label for="exampleFormControlSelect1">Pick the Date & Time</label>
            <div class="container-fluid px-0 mx-auto">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card border-0">
                    <div class="card-header">
                      <div class="row mx-0 mb-0 px-1">
                        <i for="mdate" class="material-icons" style="display: flex; align-items: center">today</i>
                        <!-- <input appInputMask [regx]="'int'" [format]="'##/##/####'" type="text" name="date" id="date"
                          #date (change)="serviceChange()" class="datepicker" placeholder="29/12/2020 - Today"
                          minlength="10" maxlength="10" name="date" data-provide="datepicker" data-date-autoclose="true"
                          data-date-format="mm/dd/yyyy" data-date-start-date="1d" [(ngModel)]="selectedDateFor" /> -->

                        <input appInputMask [regx]="'int'" [format]="'##/##/####'" placeholder="mm/dd/yyyy"
                          data-provide="datepicker" data-date-autoclose="true" data-date-format="mm/dd/yyyy"
                          data-date-start-date="1d" id="mdate" name="mdate" #dateOfBirth [(ngModel)]="selectedDateFor"
                          class="form-control mdate" required minlength="10" maxlength="10" />
                      </div>
                    </div>
                    <div *ngIf="showTable">
                      <div *ngFor="let timeSlot of timeslots" class="card-body" style="display: table;   width: 100%;padding: 10px !important;">
                        <div class="card border-0">
                          <div class="card-header">{{ timeSlot.timeSlotName }}</div>
                          <div class="card-body">
                            <button style="min-width: 23%;" type="button"  *ngFor="let booking of timeSlot.data"
                              (click)="selectTimeSlot(timeSlot.timeSlotName,booking.date)"
                              [ngClass]="(selectedDate == booking.date || booking.isMe)? 'bg-success':'bg-danger1'"
                              [disabled]="booking.booked || !booking.isActive" class="btn my-1 mx-1 me_{{booking.isMe}}">
                              <span *ngIf="!booking.booked && booking.isActive" style="color: white;" class="badge"
                                [ngClass]="selectedDate == booking.date? 'bg-success':'bg-danger1'">{{
                                booking.date | date:'HH:mm:ss'
                                }}</span>
                              <span *ngIf="booking.booked || !booking.isActive" style="color: #000000; background-color: #dadada;"
                                class="badge">{{
                                booking.date | date:'HH:mm:ss'
                                }}</span>
                            </button>
                          </div>
                        </div>

                      </div>

                      
                      <div   style="display: table;margin-right: 18px; float: right;" class="form-group">
                        <button [disabled]="newAppointment.timeslot? false:true" (click)="save()" type="submit" class="btn btn-success float-right mt-2">
                          Confirm Appointment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>


<div class="modal fade" id="appointmentSuccess" tabindex="-1" role="dialog" aria-labelledby="appointmentSuccess"
  aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="
          margin-bottom: 0 !important;
          padding: 10px !important;
        ">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Success
        </h5>
      </div>
      <div class="modal-body">
        Hi <b> {{ currentUser?.firstName }} {{ currentUser?.lastName }}</b> your appointment has been schedualed for
        {{newAppointment.timeslot | localtDateTime | date:'medium'}}.
      </div>
      <div class="modal-footer">
        <button (click)="refresPage()" type="button" data-dismiss="modal" class="btn btn-primary">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>