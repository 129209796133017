<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-12 col-md-5 col-lg-6">
        <!-- Image -->
        <img
          src="./../../assets/svg/img 8.svg"
          class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..."
          data-aos="fade-up"
          data-aos-delay="100"
          width="50%"
          height="100%"
        />
      </div>
      <div
        class="col-xl-12 col-md-7 col-lg-6 aos-init aos-animate"
        data-aos="fade-up"
      >
        <h3 class="py-4">Terms and Conditions</h3>
        <h5>General</h5>
        <p>This web site is owned and operated by MyLocalRX.</p>
        
        <p>
          All references in this web site to "us", "we" or "our" refer to MyLocalRX as the context requires.
        </p>
        <h5>Consent</h5>
        <p>
          By using this web site you agree to the terms and conditions set out
          below.
        </p>
        <h5>Canadian Content</h5>
        <p>
          The information provided on this web site is provided for Canadian residents only. MyLocalRX makes no representations that the material is appropriate for residents of other jurisdictions. 
        </p>
        <!--<p>
          Residents of the Province of Quebec please note that the information
          provided herein is not designed for the Province of Quebec. A
          Pharmaprix web site that is intended for residents of the Province of
          Quebec can be located at
          <a href="https://www1.pharmaprix.ca/en/home" target="”_blank”"
            >www.pharmaprix.ca</a
          >
        </p>-->
        <h5>No Endorsement</h5>
        <p>
          No endorsement of any third party products or services is expressed or
          implied by any information, material or content referred to or
          included on or linked from or to this web site.
        </p>
        <p>
          MyLocalRX is not responsible for the content of any other web site whether or not it is linked from or to this site.
        </p>
        <h5>Permitted Use</h5>
        <p>
          All content which forms part of this web site is the property of or licensed to MyLocalRX. You are authorized to copy material on one computer for your personal, non-commercial use only, or e-mail the information to a person who you feel may be interested in the content, provided that any copy of this material retains all copyright, disclaimer or other proprietary notices on it. Any other copying of this web site in whole or in part or any modification of any material is prohibited and violates MyLocalRX intellectual property rights.
        </p>
        <p>
          Nothing in this web site should be construed as granting any other right or license to any intellectual property of MyLocalRX or any third party. You may not make any commercial use of any material from this web site, include such material in any product you make or distribute, or copy such material onto any other web site.
        </p>
        <h5>Limitation of Liability</h5>
        <p>
          MyLocalRX shall not be liable for any damages or injuries whatsoever arising out of or relating to the use of this web site or any web site linked to this web site, whether or not such damages or injuries might be foreseeable and whether or not MyLocalRX has been negligent.
        </p>
        <h5>No Warranty</h5>
        <p>
          The content of this web site is provided for general information only. It is not intended to substitute for medical advice; to provide a diagnosis; or to act as a substitute for professional advice. All specific questions or concerns should be addressed with your healthcare advisors, pharmacist at your local pharmacy. Although MyLocalRX will try to ensure that this web site is current and does not contain inaccuracies, MyLocalRX does not warrant the quality, accuracy or completeness of any information available on this web site. The material may contain inaccuracies or typographical errors. The material is provided "as is". MyLocalRX does not make any warranties, whether expressed or implied, respecting any information, services or products described in or offered from this web site.
        </p>
      </div>
    </div>
  </div>
</section>
