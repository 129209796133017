<div *ngIf="newPrescription" class="container">
  <h3 class="py-4"
    [innerHTML]="withNoteOnly && !isTransferredPrescription?'Refill Request':isTransferredPrescription? 'Transfer your prescription':'Upload Your Prescription'">
  </h3>
  <form #prescriptionForm="ngForm">
    <div class="form-group">
      <div class="row">
        <div class="col-md-6">
          <label for="exampleFormControlSelect1">Select Member</label>
          <select class="form-control" [(ngModel)]="newPrescription.patientID" name="selectedMember"
            (change)="onMemberSelect()">
            <option *ngFor="let mem of listMembers" [value]="mem.id">
              {{ mem.firstName }} {{ mem.lastName }}
            </option>
          </select>
        </div>
        <div class="col-md-6"> <label for="exampleFormControlSelect1">Select Pharmacy</label>
          <select class="form-control" [(ngModel)]="newPrescription.pharmacyID" name="selectedPharmacy"
            #selectedPharmacy (change)="onPharmacySelect()">
            <option *ngFor="let pharmacy of pharmacies" [value]="pharmacy.id">
              {{ pharmacy.name }}
            </option>
          </select>
        </div>
      </div>

    </div>

    <fieldset class="form-group">
      <div class="row">
        <div class="col-sm-10">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="prescriptionTypeSelf"
              [(ngModel)]="newPrescription.prescriptionType" name="prescriptionType" [value]="0"
              (change)="changePrescriptionType($event)" />
            <label style="cursor: pointer;" class="form-check-label" for="prescriptionTypeSelf">
              Self Pickup
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="prescriptionTypeHome"
              [(ngModel)]="newPrescription.prescriptionType" name="prescriptionType" [value]="1"
              (change)="changePrescriptionType($event)" />
            <label style="cursor: pointer;" class="form-check-label" for="prescriptionTypeHome">
              Delivery
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="prescriptionTypeExpress"
              [(ngModel)]="newPrescription.prescriptionType" name="prescriptionType" [value]="2"
              (change)="changePrescriptionType($event)" />
            <label style="cursor: pointer;" class="form-check-label" for="prescriptionTypeExpress">
              Express Delivery
            </label>
          </div>
        </div>
      </div>
    </fieldset>

    <div style="margin-top: -10px;" *ngIf="newPrescription.prescriptionType == 2" class="row deliveryTime">
      <div class="col-md-12">
        <label style="color: #f20505;font-size: 0.9rem;">Additional cost will apply.</label>
      </div>
    </div>

    <!-- Preferred delivery time-->
    <div *ngIf="newPrescription.prescriptionType == 1 && deliverySlots" class="row deliveryTime">
      <div class="col-md-12" id="prefferdDeliveryTime">
        <label>Select your preffered delivery time *:</label>
      </div>


      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <label style="font-size: 0.9rem;">Today</label>
                <div class="row">
                  <div class="col-md-4" *ngFor="let item of deliverySlots let i = index">
                    <input [attr.disabled]="item.disable? true : null" required
                      [(ngModel)]="newPrescription.prefferdDeliveryTime" type="radio"
                      value="{{item.startTime}} - {{item.endTime}}" name="prefferdDeliveryTime" id="time{{i}}" />

                    <label for="time{{i}}">
                      <span> {{item.startTime.substring(0,5)}} - {{item.endTime.substring(0,5)}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <label style="font-size: 0.9rem;">Tomorrow</label>
                <div class="row">
                  <div class="col-md-4" *ngFor="let item of deliverySlots let i = index">
                    <input required [(ngModel)]="newPrescription.prefferdDeliveryTime" type="radio"
                      value="n_{{item.startTime}} - {{item.endTime}}" name="prefferdDeliveryTime" id="time_n{{i}}" />

                    <label for="time_n{{i}}">
                      <span> {{item.startTime.substring(0,5)}} - {{item.endTime.substring(0,5)}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!withNoteOnly" style="margin-left: 20px;" class="form-group">
      <input class="form-check-input" [(ngModel)]="newPrescription.medicineTakenBefore" type="checkbox" name="taken"
        id="yes" />
      <label for="yes">I have taken this medicine before.</label>

    </div>
    <div *ngIf="!withNoteOnly" class="form-group">
      <label class="lable">Purpose of this medicine</label>
      <textarea name="purposeOfMedicine" id="purposeOfMedicine" required pattern=".{2,}"
        [(ngModel)]="newPrescription.purposeOfMedicine" class="form-control"></textarea>
      <span>*</span>
    </div>
    <div *ngIf="!withNoteOnly" class="form-group">
      <label class="lable">Allergic to any medicine?</label>
      <textarea id="allergic" name="allergic" required pattern=".{2,}" [(ngModel)]="newPrescription.allergyInfo"
        class="form-control"></textarea>
      <span>*</span>
    </div>
    <div
      *ngIf="this.newPrescription.refill && newPrescription.prescriptionLinks && newPrescription.prescriptionLinks.length>0"
      style="display: table; width: 100%;" class="form-group">
      <label style=" width: 100%;" class="lable">Uploaded prescription</label>
      <!-- <div class="exitPrescitpion"> -->
      <div style="margin-left: -1rem;">
        <app-image-preview-list style="width: 100%; display: table;" [listImage]="newPrescription.prescriptionLinks">
        </app-image-preview-list>
      </div>
      <!-- </div> -->
    </div>
    <div *ngIf="!this.newPrescription.refill" class="form-group">
      <input id="input-b5" (change)="fileChangeEvent($event,'input-b5')" name="input-b5[]" type="file" multiple />
      <small id="passwordHelpBlock" class="form-text text-muted">
        To capture a clear and complete photo of the prescription, make sure
        your prescription is flat and well illuminated.
      </small>
    </div>

    <!--
     <div class="form-group">
      <input id="input-b6" name="input-b6[]" type="file" multiple/>
      <small id="passwordHelpBlock" class="form-text text-muted">
          To capture a clear and complete photo of the prescription, make sure your prescription is flat and well illuminated.
      </small>
    </div>-->
    <hr>



    <div *ngIf="newPrescription.prescriptionType >= 1" class="form-group">

      <div *ngIf="addresses" class="row deliveryTime addresses">

        <div *ngFor="let delAddress of addresses let i = index" class="col-md-2">
          <input (change)="AddressChanged()" type="radio" [(ngModel)]="newPrescription.isDeliveryAddressNew"
            name="delAddress" id="oldAddress_{{i}}" [value]="i" />
          <label for="oldAddress_{{i}}">
            <div>{{delAddress.firstName}} {{delAddress.lastName}}</div>
            <div>{{delAddress.streetAddress}}</div>
            <div>{{delAddress.city}}-{{delAddress.postalCode}}</div>

            <i (click)="deleteAddress(i)" style="cursor: pointer; position: absolute;top: 5px; right: 22px;" class="fa fa-trash"></i>
          </label>
         
        </div>
        <div class="col-md-2">
          <input type="radio" (change)="AddressChanged()" [(ngModel)]="newPrescription.isDeliveryAddressNew"
            name="delAddress" id="delAddressNew" [value]="-1" />
          <label for="delAddressNew">
            <div>Add new</div>
            <div>Delivery address</div>
          </label>
        </div>
      </div>
      <div style="margin-top: 30px; margin-bottom: 30px;" class="row">
        <div class="col-md-4">
          <input type="checkbox" name="repeatable" id="repeatable" [(ngModel)]="newPrescription.repeatable" />
          <label style="margin-left: 10px;cursor: pointer;" for="repeatable">I want to repeat my prescription</label>
        </div>
        <div *ngIf="newPrescription.repeatable" class="col-md-4">
          <select class="form-control" required id="repeateInDays" name="repeateInDays"
            [(ngModel)]="newPrescription.repeateInDays">
            <option style="color:red" value="">Select days *</option>
            <option value="1">Every day</option>
            <option value="7">Repeat after 7 days</option>
            <option value="10">Repeat after 10 days</option>
            <option value="14">Repeat after 14 days</option>
            <option value="28">Repeat after 28 days</option>
            <option value="45">Repeat after 45 days</option>
            <option value="60">Repeat after 60 days</option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <!-- First Name -->
            <label for="full_name_id" class="control-label">First Name *</label>
            <input type="text" class="form-control" id="firstname"
              [(ngModel)]="newPrescription.deliveryAddress.firstName" name="firstname" #firstname required
              pattern=".{3,}" placeholder="Enter your first name" />
            <span>*</span>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <!-- Last Name -->
            <label for="full_name_id" class="control-label">Last Name *</label>
            <input required pattern=".{3,}" type="text" class="form-control" id="lastName"
              [(ngModel)]="newPrescription.deliveryAddress.lastName" name="lastName" #lastName
              placeholder="Enter your last name" />
            <span>*</span>
          </div>
        </div>
      </div>


      <div class="form-row">
        <!-- Street 1 -->
        <div class="col">
          <div class="form-group">
            <label for="unitNo" class="control-label">House No.</label>
            <input type="text" class="form-control" [(ngModel)]="newPrescription.deliveryAddress.unitNo" id="unitNo"
              name="unitNo" placeholder="Enter your house number" />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="buildingName" class="control-label">Building Name</label>
            <input type="text" class="form-control" [(ngModel)]="newPrescription.deliveryAddress.buildingName"
              id="buildingName" name="buildingName" placeholder="Enter your building name" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <!-- Street 2 -->
        <label for="street2_id" class="control-label">Street Address *</label>
        <input appAddressAuto (onSelect)="googleAddressSelected($event)" required pattern=".{4,}" type="text"
          class="form-control" id="streetAddress" [(ngModel)]="newPrescription.deliveryAddress.streetAddress"
          name="streetAddress" placeholder="Enter your street address" />
        <span>*</span>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <!-- City-->
            <label for="city_id" class="control-label">City *</label>
            <input required pattern=".{4,}" type="text" class="form-control" id="city"
              [(ngModel)]="newPrescription.deliveryAddress.city" name="city" placeholder="Enter your city" />
            <span>*</span>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <!-- Zip Code-->
            <label for="zip_id" class="control-label">Postal Code *</label>
            <input appInputMask [format]="'### ###'" required minlength="7" maxlength="7" type="text"
              class="form-control" id="postalCode" name="postalCode"
              [(ngModel)]="newPrescription.deliveryAddress.postalCode" placeholder="Enter your postal code" />
            <span>*</span>
          </div>
        </div>
      </div>



    </div>
    <div *ngIf="withNoteOnly || newPrescription.prescriptionType >= 1" class="form-group">
      <!-- Zip Code-->
      <label for="noteFromPatient" class="control-label">{{withNoteOnly && !isTransferredPrescription?'Refill Request
        *': isTransferredPrescription?
        'Enter your requirement': 'Delivery
        instruction'}}</label>
      <input type="text" class="form-control" required id="noteFromPatient" name="noteFromPatient"
        [(ngModel)]="newPrescription.noteFromPatient"
        placeholder="{{withNoteOnly && !isTransferredPrescription?'Enter your refill requirement':isTransferredPrescription? 'Type here your requirements':'Type here delivery instruction'}}" />
    </div>
    <div *ngIf="isTransferredPrescription">
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <!-- City-->
            <label for="outerPharmacyName" class="control-label">Pharmacy Name *</label>
            <input required pattern=".{4,}" type="text" class="form-control" id="city"
              [(ngModel)]="newPrescription.outerPharmacyName" name="outerPharmacyName"
              placeholder="Pharmacy Name From Where To Transfer Prescriptions" />
            <span>*</span>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <!-- Zip Code-->
            <label for="zip_id" class="control-label">Pharmacy Contact Number *</label>
            <input appPhoneMask required minlength="16" maxlength="17" type="text" class="form-control"
              id="outerPharmacyNumber" name="outerPharmacyNumber" [(ngModel)]="newPrescription.outerPharmacyNumber"
              placeholder="Contact of Pharmacy From Where To Transfer Prescriptions" />
            <span>*</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="newPrescription.prescriptionType == 0" class="card">
      <div class="card-header">Pickup Instructions</div>

      <div class="card-body">
        <h5 class="card-title">
          Once prescription is ready it can be picked up from :
        </h5>
        <p class="card-text">{{ selectedPharmacyAddress }}</p>
        <p class="card-text">{{ selectedPharmacyAddress2 }}</p>
        <p class="card-text">Contact : {{ selectedPharmacyContact }}</p>

        <!-- <h6 class="card-title">pickup time</h6>
        <p class="card-text">{{ selectedPharmacyTiming }}</p> -->
      </div>
    </div>

    <button (click)="TriggerModal(prescriptionForm, 'confirm-submit')" class="btn btn-primary mt-3">
      Submit
    </button>
  </form>
</div>

<div class="modal fade" id="confirm-submit">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="confirmationText"></p>
      </div>
      <div class="modal-footer">
        <button type="button" on-click="save()" class="btn btn-primary" data-dismiss="modal">
          Yes
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="deliveryNotAvialble">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Error</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Delivery is not available at this address</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="submit-success">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Success</h5>
        <button type="button" on-click="showList()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Your Prescription request is submitted successfully. You will be
          notified once it is ready {{newPrescription.prescriptionType==0?"to be picked up":"for delivery"}}.
        </p>
        <p><b> Pharmacy Name:</b> {{ selectedPharmacyAddress }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" on-click="showList()" class="btn btn-primary" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="developerError">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Error please capture screenshot and send to developer</h5>
        <button type="button" on-click="showList()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{developerError}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" on-click="showList()" class="btn btn-primary" data-dismiss="modal">
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="delAddressConfirmation">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Comfirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Do you want delete this address?
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" on-click="confirmDeleteAddress()" class="btn btn-primary" data-dismiss="modal">
          Yes
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>