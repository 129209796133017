import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ProductService } from '../services/product.service';
import { GlobalConstants } from '../shared/global-constants';
import { CartVM } from '../shared/models/cart.model';
import { ProductVM } from '../shared/models/product.modal';
declare var $:any;
@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.scss']
})
export class MyCartComponent implements OnInit {

  currentUser = GlobalConstants.getcurrentUser();
  constructor(private product: ProductService, private zone: NgZone, private userService: AuthenticationService, private route: Router) { }
  listCart: CartVM[] = [];
  subtotal=0;
  deliveryCharge = 4.99;
  taxRate = 13.00;
  taxtAmount = 0;
  totalAmount = 0;
  paymentMethod = "COD";
  ngOnInit(): void {
    this.product.currentCart.subscribe(x => {
      this.subtotal = 0;
      this.totalAmount = 0;
      x.forEach(ele => {

        if (ele.product.discount && ele.product.discount > 0) {
          console.log(ele.product.price * (ele.product.discount / 100));
          ele.product.discountPrice = ele.product.price - (ele.product.price * (ele.product.discount / 100));
        }
        else {
          ele.product.discountPrice = ele.product.price;
        }
        this.subtotal += ele.product.discountPrice * ele.qty;
      });
      this.taxtAmount = (this.subtotal*(this.taxRate/100))
      this.totalAmount = this.subtotal +this.deliveryCharge+this.taxtAmount;
      this.listCart = x;
      console.log(x);
      $('.csLoader').css({ display: 'none' });

    });

    setTimeout(() => {
      $('.csLoader').css({ display: 'flex' });
      this.getCurrentCart();
    }, 1000);
    $('.csLoader').css({ display: 'flex' });

  }
  private getCurrentCart() {
    this.product.getMyCart().subscribe(x => {
      GlobalConstants.cartList = x;
      this.product.currentCart.next(x);
    });
  }
  updateQty(item: ProductVM, inc: number) {

    var cart = this.listCart.find(x => x.productId == item.id)!;

    $('.csLoader').css({ display: 'flex' });
    cart.qty += inc;
    if (cart.qty <= 0) {
      this.product.deleteCart(cart.id).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
    else {
      this.product.updateCart(cart.id, cart.qty).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
  }

}
