import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PrescriptionStatuses } from 'src/app/shared/pipes/prescription-status.pipe';

declare var JSON: any;

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  public PRESCRIPTION_API = environment.baseUrl + '/prescription';
  private PRESCRIPTION_API_NO_FILE = environment.baseUrl + '/prescriptionwofile';
  constructor(private http: HttpClient) { }

  save(prescription: any, prescriptionFiles: any): Observable<any> {
    let result: Observable<any>;


    const formData = new FormData();

    for (let i = 0; i < prescriptionFiles.length; i++) {
      formData.append("files", prescriptionFiles[i])
    }
    // formData.append('files', prescriptionFiles[0]);
    formData.append('prescription', JSON.stringify(prescription));

    result = this.http
      .post<any>(prescriptionFiles.length > 0 ? this.PRESCRIPTION_API : this.PRESCRIPTION_API_NO_FILE, prescriptionFiles.length > 0 ? formData : prescription)
      .pipe(timeout(1300000));
    return result;
  }

  cancel(prescriptionID: string): Observable<any> {
    let result: Observable<any>;

    var prescriptionUpdateDTO = {
      id: prescriptionID,
      status: PrescriptionStatuses.CANCELLED_BY_PATIENT,
      noteFromPatient: "",
      noteFromPharmacy: "",
      cost: -1
    }

    result = this.http.patch<any>(this.PRESCRIPTION_API, prescriptionUpdateDTO);


    return result;
  }


  getAll(): Observable<any> {
    return this.http.get(this.PRESCRIPTION_API);
  }

  getPrescriptionForUser(userID: string): Observable<any> {
    return this.http.get(this.PRESCRIPTION_API + '?patientID=' + userID);
  }

  findAddressRoute(from: string, to: string): Promise<any> {
    // return this.http.get(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/directions/json?origin=${from}&destination=${to}&key=AIzaSyB3ZAO1G6nEOYzGmaymer_6lOpn-OI2TnU`);

    const service = new google.maps.DistanceMatrixService();
    return new Promise<any>((resolve, reject) => {

      service.getDistanceMatrix(
        {
          origins: [from],
          destinations: [to],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (status !== "OK") {
            reject("Can't calculate distance");
          } else {
            resolve(response);
          }
        });
    });
  }
}
