import { Component, OnInit } from '@angular/core';
import { Form, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
declare var $: any;
declare var angular: any;

@Component({
  selector: 'app-mylocalrx-contentcontainer-reset-password',
  templateUrl: './mylocalrx-contentcontainer-reset-password.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-reset-password.component.scss']
})
export class MylocalrxContentcontainerResetPasswordComponent implements OnInit {

  constructor(private routAct: ActivatedRoute, private authServive: AuthenticationService) { }
  token: any;
  isvalidToken = true;
  isPasswordResetDone = false;
  user = { newPassword: "", confirmPassword: "", token: "" };
  ngOnInit(): void {
    this.routAct.queryParams.subscribe(params => {
      this.token = params['token'];
      if (this.token) {
        
        $('.csLoader').css({ display: 'flex' });
        this.authServive.verifyResetToken(this.token).subscribe(x => {
          $('.csLoader').css({ display: 'none' });
          console.log(x);
          this.isvalidToken = x.errorCode == "";
          if (this.isvalidToken) {
            this.user.token = this.token;
          }
        },err=>{
        $('.csLoader').css({ display: 'hide' });
        });
      }
    });
  }
  reset(resetForm: NgForm) {
    if (!resetForm.form.valid) {
      for (const field in resetForm.form.controls) {
        const control = resetForm.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
        }
      }
      return;
    }
    
    $('.csLoader').css({ display: 'flex' });
    this.authServive.resetPassord(this.user).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      console.log(x);
      this.isPasswordResetDone = x.errorCode == null;
    },err=>{
      $('.csLoader').css({ display: 'none' });
    });
  }
  resendLink() {
   window.location.href  = window.location.origin+"/login/true";
  }
}
