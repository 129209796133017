import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    var status = "UN-KNOWN";
    switch (value) {
      case OrderStatus.PENDING: status = "Pending"; break;
      case OrderStatus.PROCESSING: status = "Processing"; break;
      case OrderStatus.CANCELLED_BY_USER: status = "Canceled by user"; break;
      case OrderStatus.CANCELLED_BY_ADMIN: status = "Canceled by admin"; break;
      case OrderStatus.READY_TO_DELIVER: status = "Ready to deliver"; break;
      case OrderStatus.OUT_FOR_DELIVERY: status = "Out for delivery"; break;
      case OrderStatus.DELIVERED: status = "Delivered"; break;
      case OrderStatus.DELIVERY_FAILED: status = "Delivery failed"; break;
    }

    return status;
  }

}

@Pipe({
  name: 'orderPaymentStatus'
})
export class OrderPaymentStatusPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    var status = "UN-KNOWN";
    switch (value) {
      case OrderPaymentStatus.PENDING: status = "Pending"; break;
      case OrderPaymentStatus.PROCESSING: status = "Processing"; break;
      case OrderPaymentStatus.PAID: status = "Paid"; break;
      case OrderPaymentStatus.FAIL: status = "Failed"; break;
      case OrderPaymentStatus.REQUIREAUTH: status = "REQUIREAUTH"; break;
      case OrderPaymentStatus.PARTIALY_REFUNDE: status = "PARTIALY_REFUNDE"; break;
      case OrderPaymentStatus.FULL_REFUNDED: status = "FULL_REFUNDED"; break;
    }

    return status;
  }

}

export enum OrderStatus {
  PENDING, PROCESSING, CANCELLED_BY_USER, CANCELLED_BY_ADMIN, READY_TO_DELIVER, OUT_FOR_DELIVERY,
  DELIVERED, DELIVERY_FAILED
}
export enum OrderPaymentStatus {
  PENDING,PROCESSING,PAID,FAIL, REQUIREAUTH, PARTIALY_REFUNDE,FULL_REFUNDED
}
