<!-- WELCOME
            ================================================== -->
<section class="pt-4 pt-md-11 py-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-5 col-lg-6 order-md-1">
        <!-- Image -->
        <img
          src="./../../assets/svg/img 3.svg"
          class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
          alt="..."
          data-aos="fade-up"
          data-aos-delay="100"
          width="100%"
          height="100%"
        />
      </div>
      <div
        class="col-12 col-md-7 col-lg-6 order-md-2 aos-init aos-animate"
        data-aos="fade-up"
      >
        <h3 class="py-4">FAQs</h3>
        <h5>Do I need a valid prescription to get my medicine?</h5>
        <p>
          Yes. You will have to upload a valid prescription to buy medicines
          using the MylocalRx web app, please note that the original
          prescription must be received by the pharmacy either by fax from the
          doctors office or personally form you before any medication can be
          released for pick-up or delivery. In case you have misplaced your
          prescription, our doctors can help you via virtual consultation.
        </p>
        <h5>Is there a fee for delivery?</h5>
        <p>
          Delivery by the end of the day is free, but express delivery (within 2
          hours) is a minimum of $10 if within 5kms of the pharmacy, or more of you live further away.
        </p>
        <h5>Where do you deliver to?</h5>
        <p>
          At the moment we deliver in Brampton,Caledon and Mississauga only, but
          we hope to expand to the GTA soon
        </p>
      </div>
    </div>
  </div>
</section>
