<div class="container" style="margin-top: 40px;">

    <div *ngIf="product" class="row">
        <div class="col-md-8 offset-md-2">
            <div class="row">
                <div class="col-md-6">
                    <div class="imgd">
                        <span class="pDsicount" *ngIf="product.discountPrice > 0">
                            {{product.discount}} % OFF
                        </span>
                        <img src="{{product.productImages[0]}}" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="myProductd">
                        <p> <label class="pName">{{product.productName}}</label></p>
                        <p> <label class="pLDsc" [innerHTML]="product.longDesc"></label></p>
                        <p class="keyVal">
                            <b>Code</b> <span>{{product.productCode}}</span>
                        </p>
                        <p class="keyVal">
                            <b>Brand</b> <span>{{product.productBrand}}</span>
                        </p>
                        <p class="keyVal">
                            <b>Category</b> <span>{{product.productCategory}}</span>
                        </p>
                        <p style="    margin-top: 10px;  display: table;" *ngIf="product.tags">
                            <span class="tags" *ngFor="let tag of product.tags">{{tag}}</span>
                        </p>
                        <div>
                            <div style="width: 60%;float: left;">
                                <label *ngIf="product.discountPrice > 0" class="pPirce">
                                    <span class="dPrice">
                                        <span>{{product.currency}}</span>
                                        <span>{{product.price}}</span>
                                    </span>
                                    <span>{{product.currency}}</span>
                                    <span>{{product.discountPrice | number : '1.0-2'}}</span>
                                </label>

                                <label *ngIf="product.discountPrice <= 0" class="pPirce">
                                    <span>{{product.currency}}</span>
                                    <span>{{product.price | number : '1.0-2'}}</span>
                                </label>
                            </div>
                            <div style="width: 40%;float: right;">
                                <div class="pQty" *ngIf="product.isAddedToCart">
                                    <span (click)="updateQty(product,-1)"
                                        style="border-bottom-right-radius: 0; border-top-right-radius: 0;">-</span>
                                    <label>{{product.qty}}</label>
                                    <span (click)="updateQty(product,1)"
                                        style="border-top-left-radius: 0;border-bottom-left-radius: 0;">+</span>
                                </div>
                            </div>


                        </div>
                        <button *ngIf="!product.isAddedToCart" (click)="addToCart(product)" style="width: 100%;"
                            class="btn btn-primary mr-1"> Add to cart</button>

                    </div>
                    <i class="flash">* Delivery available only in Brampton.</i>
                </div>
            </div>
        </div>
    </div>
</div>