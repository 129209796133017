import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../services/product.service';
import { GlobalConstants } from '../shared/global-constants';
import { CartVM } from '../shared/models/cart.model';
import { ProductVM } from '../shared/models/product.modal';
declare var $: any;
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  constructor(private productService: ProductService, private routAct: ActivatedRoute,) { }
  product: ProductVM | null = null;
  listCart: CartVM[] = [];
  ngOnInit(): void {
    $('.csLoader').css({ display: 'flex' });
    this.productService.getById(this.routAct.snapshot.params.id).subscribe(ele => {
      $('.csLoader').css({ display: 'none' });
      if (ele.discount && ele.discount > 0) {
        console.log(ele.price * (ele.discount / 100));
        ele.discountPrice = ele.price - (ele.price * (ele.discount / 100));
      }
      else {
        ele.discountPrice = 0;
      }
      ele.name = ele.productName.replace(/\s/g, '-').toLowerCase();

      this.product = ele;
      this.voidIsProductAddedToCart();
    });

    this.productService.currentCart.subscribe(x => {
      this.listCart = x;
      this.voidIsProductAddedToCart();
    });
    this.getCurrentCart();
  }
  private getCurrentCart() {
    this.productService.getMyCart().subscribe(x => {
      GlobalConstants.cartList = x;
      this.productService.currentCart.next(x);
    });
  }

  private voidIsProductAddedToCart() {
    if (this.listCart && this.product) {
        var cart = this.listCart.find(x => x.productId == this.product!.id);
        if (cart != null) {
          this.product!.isAddedToCart = true;
          this.product!.qty = cart.qty;
        }
        else {
          this.product!.isAddedToCart = false;
          this.product!.qty = 0;
        }
    }
  }

  addToCart(item: ProductVM) {
    $('.csLoader').css({ display: 'flex' });
    var cart = { productId: item.id, qty: 1, userId: "", userUID: "" };
    this.productService.addToCart(cart).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      this.getCurrentCart();
      console.log(x);
    }, err => {
      $('.csLoader').css({ display: 'none' });
      alert("Error");
    });
  }
  updateQty(item: ProductVM, inc: number) {

    var cart = this.listCart.find(x => x.productId == item.id)!;

    $('.csLoader').css({ display: 'flex' });
    cart.qty += inc;
    if (cart.qty <= 0) {
      this.productService.deleteCart(cart.id).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
    else {
      this.productService.updateCart(cart.id, cart.qty).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
  }

}
