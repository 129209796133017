import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';

import { PharmacyService } from '../services/pharmacy.service';
import { PharmacySchedule, Timeslot } from '../shared/models/schedual.modal';
import { AppointmentService } from '../services/appointment.service';
import { DatePipe } from '@angular/common';
import { GlobalConstants } from '../shared/global-constants';
import { Appointment } from '../shared/models/Appointment';
import { LocaltDateTimePipe } from '../shared/pipes/localt-date-time.pipe';
import { Router } from '@angular/router';
import { Address } from '../shared/models/prescription.model';

declare var $: any;
@Component({
  selector: 'mylocalrx-contentcontainer-appointment',
  templateUrl: './mylocalrx-contentcontainer-appointment.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-appointment.component.scss']
})
export class MylocalrxContentcontainerAppointmentComponent implements OnInit {
  // /appointment/{pharmacy}/{service}/{date}
  pharmacies: Pharmacy[] = [];
  services: PharmacySchedule[] = [];
  appointments: any;

  currentUser = GlobalConstants.getcurrentUser();

  newAppointment: Appointment = {
    status: 0,
    id: "",
    dateOfBirth: "",
    note: "",
    "pharmacyID": "-1",
    "serviceID": "-1",
    timeslotStrClient: "",
    patientID: this.currentUser?.id!,
    phoneNumber: this.currentUser?.phoneNumber!,
    patientName: this.currentUser?.firstName + " " + this.currentUser?.lastName, timeslot: "",
    slotName: "",
    appointment_type:'',

  appointment_note: '',

  appointment_status: '',

  appointment_mob: 0,
  minorAilment_type: '',

    address: {} as Address,questionnaire:null
  };
  selectedDateFor = "";
  //selectedTimeSlot : string;

  constructor(private pharmacyService: PharmacyService,
    private appointmentService: AppointmentService, private datePipe: DatePipe, private localtDateTimePipe: LocaltDateTimePipe, private zone: NgZone, private router: Router) {
    $('.csLoader').css({ display: 'flex' });
    this.pharmacyService.getAllMyLocarxEnable().subscribe(data => {
      $('.csLoader').css({ display: 'none' });
      data = data.filter(x => x.myLocalRXEnabled == true);
      this.pharmacies = data.filter(x => x.ownerid);
      console.log(this.pharmacies);

    }, error => {
      $('.csLoader').css({ display: 'none' });
    });

    var that = this;
    $(document).ready(function () {
      $('.mdate').datepicker().on("changeDate", function (e: any) {
        that.zone.run(x => {
          that.selectedDateFor = $('.mdate').val();
          that.serviceChange();
        })
      });
    });
  }
  ngOnInit(): void {


  }
  pharmacyChanged() {
    $('.csLoader').css({ display: 'flex' });
    this.newAppointment.serviceID = "-1";
    this.appointmentService.getScheduleForPharmacy(this.newAppointment.pharmacyID).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      this.services = x!;

    }, error => {
      $('.csLoader').css({ display: 'none' });
    });
  }
  timeslots: Timeslot[] = [{} as Timeslot];
  showTable = false;
  private RendData() {
    // this.services.forEach(data => {
    //   data.services.forEach(service => {
    //     service.schedules.timeslots.forEach(slot => {
    //       var splitDate = this.selectedDateFor.split("/");
    //       var currentDt = new Date();
    //       currentDt.setMinutes(currentDt.getMinutes() + 30);
    //       var dt = new Date(parseInt(splitDate[2]), parseInt(splitDate[0]) - 1, parseInt(splitDate[1]));

    //       var time = slot.startTime.split(":");
    //       dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(time[0]), parseInt(time[1]), parseInt(time[2]));
    //       var running = true;
    //       slot.data = [{}];
    //       while (running) {
    //         var isActive = service.schedules.daysOfTheWeek.find(dow => dow.day == dt.getDay())?.active!;

    //         if (currentDt.getTime() > dt.getTime()) {
    //           isActive = false;
    //         }

    //         slot.data.push({ date: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds()), booked: false, isMe: false, isActive: isActive });
    //         dt.setMinutes(dt.getMinutes() + service!.schedules!.slotDuration!);
    //         var end = this.datePipe.transform(dt, "HH:mm:ss");
    //         running = !(parseInt(end!.replace(/:/g,'')) >=  parseInt(slot.endTime.replace(/:/g,'')));
    //       }
    //       slot.data.splice(0, 1);
    //     });
    //   });
    // });
  }

  serviceChange() {
    // if (this.selectedDateFor) {
    //   this.newAppointment.timeslot = "";
    //   $('.csLoader').css({ display: 'flex' });
    //   this.RendData();
    //   var sch = this.services.find(x => x.id == this.newAppointment.serviceID)?.services[0].schedules!;
    //   this.timeslots = sch.timeslots!;
    //   this.showTable = true;
    //   console.log(sch);
    //   this.appointmentService.getAppointmentForDay(this.newAppointment, this.selectedDateFor).subscribe(x => {
    //     $('.csLoader').css({ display: 'none' });
    //     this.timeslots.forEach(slot => {
    //       slot.data.forEach(d => {
    //         d.booked = false;
    //         d.isMe = false;
    //       });

    //       if (x && x.length > 0) {
    //         var pre = x.filter(p => p.slotName.toLocaleLowerCase() == slot.timeSlotName.toLocaleLowerCase());
    //         pre.forEach(p => {
    //           var booked = slot.data.find(d => this.datePipe.transform(d.date, "MM/dd/yyyy HH:mm:ss") == this.datePipe.transform(this.localtDateTimePipe.transform(p.timeslot), "MM/dd/yyyy HH:mm:ss"));
    //           if (booked) {
    //             console.log(this.localtDateTimePipe.transform(booked.date));
    //             booked.booked = true;
    //             booked.isMe = p.patientID == this.currentUser?.id;
    //           }
    //         });
    //       }
    //     });
    //   }, error => {
    //     $('.csLoader').css({ display: 'none' });
    //   });
    // }

  }

  save() {
    // upload prescription file first
    if (this.newAppointment.timeslot) {
      $('.csLoader').css({ display: 'flex' });
      this.appointmentService.save(this.newAppointment,null).subscribe(result => {
        $('.csLoader').css({ display: 'none' });
        $("#appointmentSuccess").modal('toggle');
      }, error => {
        $('.csLoader').css({ display: 'none' });
      });
    }
  }
  selectedDate: Date = new Date(2000, 1, 1);
  selectTimeSlot(timeSlotName: string, timeString: Date) {
    //this.selectedTimeSlot = timeString;
    this.selectedDate = timeString;
    this.newAppointment.slotName = timeSlotName;
    this.newAppointment.timeslot = this.datePipe.transform(new Date(timeString.getTime() + (timeString.getTimezoneOffset() * 60 * 1000)), "yyyy-MM-dd'T'HH:mm:ss.SSS")!;
    console.log(this.newAppointment);
  }
  refresPage() {
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }

}

export interface Pharmacy {
  id: string;
  name: string;

}

