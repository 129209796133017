import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mylocalrx-contentcontainer-aboutus',
  templateUrl: './mylocalrx-contentcontainer-aboutus.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-aboutus.component.scss']
})
export class MylocalrxContentcontainerAboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
