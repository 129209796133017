import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WebQueryService } from '../services/web-query.service';
declare var $: any;
@Component({
  selector: 'mylocalrx-contentcontainer-contactus',
  templateUrl: './mylocalrx-contentcontainer-contactus.component.html',
  styleUrls: ['./mylocalrx-contentcontainer-contactus.component.scss']
})
export class MylocalrxContentcontainerContactusComponent implements OnInit {

  constructor(private webQueryService: WebQueryService) { }
  queryForm = {
    name: "",
    email: "",
    mobileNum: "",
    message: ""
  }
  ngOnInit(): void { }
  thankYouUser = "";
  saveQuery(webQueryForm: NgForm) {
    if (webQueryForm.form.invalid) {
      for (const field in webQueryForm.form.controls) {
        const control = webQueryForm.form.get(field);
        if (control?.invalid) {
          control.markAsTouched();
          $('#' + field).focus();
          break;
        }
      }
      return;
    }
    $('.csLoader').css({ display: 'flex' });
    this.webQueryService.save(this.queryForm).subscribe(x => {
      this.thankYouUser = x.name;
      this.queryForm = { name: "", email: "", message: "", mobileNum: "" };
      $('.csLoader').hide();
      $("#modal-thank-you").modal("toggle");
      for (const field in webQueryForm.form.controls) {
        const control = webQueryForm.form.get(field);
        control?.markAsUntouched();
      }
    }, error => {
      $('.csLoader').hide();
    });
  }
}
