<form class="form-signup" #signupForm="ngForm" (ngSubmit)="signup(signupForm)">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <input id="firstName" name="firstName" #firstName [(ngModel)]="newUser.firstName"
                    class="form-control form-control-sm" required pattern=".{2,}" placeholder="First Name*" />
                <span>*</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <input id="lastName" name="lastName" #lastName [(ngModel)]="newUser.lastName"
                    class="form-control form-control-sm" required pattern=".{1,}" placeholder="Last Name*" />
                <span>*</span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div style="position: relative;" class="form-group">
                <input appInputMask [regx]="'int'" [format]="'##/##/####'" style="font-size: 0.9rem;"
                    placeholder="Date of birth MM/dd/yyyy" id="dateOfBirth" name="dateOfBirth" #dateOfBirth
                    [(ngModel)]="newUser.dateOfBirth" class="form-control agedatepicker1" required minlength="10"
                    maxlength="10" />
                <span>*</span>
                <label style="width: 15px;" class="agedatepickerIcon" for="dateOfBirth"><i style="float: right;"
                        class="fa fa-calendar"></i></label>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <input type="radio" name="sex" id="male" [value]="0" [(ngModel)]="newUser.sex" />
                <label style="padding-left: 5px; padding-right: 30px" for="male">Male</label>
                <input type="radio" name="sex" id="female" [value]="1" [(ngModel)]="newUser.sex" />
                <label style="padding-left: 5px" for="female">Female</label>
                <span>*</span>
            </div>
        </div>
    </div>




    <div class="form-group">
        <input id="ohip" placeholder="OHIP Number (Optional)" maxlength="15" name="ohip" appInputMask
            [format]="'####-###-###-##'" #ohip [(ngModel)]="newUser.ohip" class="form-control form-control-sm" />
    </div>
    <div class="form-group">
        <input id="ohipFile" (change)="fileChangeEvent($event,'ohipFile')" name="ohipFiles" type="file" multiple />
        <!-- <small id="ohipFilesHelpBLock" class="form-text text-muted">
        To capture a clear and complete photo of the OHIP, make sure your
        OHIP is flat and well illuminated.
      </small> -->
    </div>

    <div class="form-group">
        <div>
            <input style="cursor: pointer" type="checkbox" name="haveinsurance" id="haveinsurance" [value]="false"
                [(ngModel)]="newUser.haveinsurance" />
            <label style="padding-left: 10px; cursor: pointer" for="haveinsurance">I have insurance card.</label>
        </div>
        <div [ngClass]="newUser.haveinsurance ? '' : 'hide'">
            <input (change)="fileChangeEvent($event,'insuranceFile')" id="insuranceFile" name="insuranceFiles"
                type="file" multiple />
            <small id="insuranceFilesHelpBLock" class="form-text text-muted">
                To capture a clear and complete photo of the insurance card, make
                sure your insurance card is flat and well illuminated.
            </small>
        </div>
    </div>


    <div *ngIf="signupForm.form.invalid && signupForm.form.touched" class="form-label-group">
        <div style="padding: 4px 10px" class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>
                <i style="padding-right: 10px" class="fa fa fa-exclamation-triangle"></i>
            </strong>
            <label style="margin: 0; font-size: 0.9rem">
                Please fill all mandatory fields</label>
        </div>
    </div>
    <!--<p *ngFor="let storehour of this.selectedPharmacy.storeHours">
                  {{storehour.weekDayName}} : {{storehour.startTime}} - {{storehour.endTime}} 
                </p>-->
    <button class="btn btn-primary mt-3 btn-block" type="submit">
        Save
    </button>
</form>