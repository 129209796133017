import { Component, Input, OnInit } from '@angular/core';
import { tr } from 'date-fns/locale';
import { ProductService } from 'src/app/services/product.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { CartVM } from 'src/app/shared/models/cart.model';
import { ProductVM } from 'src/app/shared/models/product.modal';
declare var $: any;
@Component({
  selector: 'app-home-list-products',
  templateUrl: './home-list-products.component.html',
  styleUrls: ['./home-list-products.component.scss']
})
export class HomeListProductsComponent implements OnInit {
  @Input() isHomePage: boolean = false;
  constructor(private product: ProductService) { }
  listProducts: ProductVM[] = [];
  listCart: CartVM[] = [];
  ngOnInit(): void {
    this.product.getAllProducts().subscribe(x => {
      this.listProducts = this.isHomePage ? x.splice(0, 4) : x;
      this.listProducts.forEach(ele => {
        if (ele.discount && ele.discount > 0) {
          console.log(ele.price * (ele.discount / 100));
          ele.discountPrice = ele.price - (ele.price * (ele.discount / 100));
        }
        else {
          ele.discountPrice = 0;
        }
        ele.name = ele.productName.replace(/\s/g, '-').toLowerCase();
      });


      this.voidIsProductAddedToCart();
    });

    this.product.currentCart.subscribe(x => {
      this.listCart = x;
      this.voidIsProductAddedToCart();
    });
    this.getCurrentCart();
  }
  private getCurrentCart() {
    this.product.getMyCart().subscribe(x => {
      GlobalConstants.cartList = x;
      this.product.currentCart.next(x);
    });
  }

  private voidIsProductAddedToCart() {
    if (this.listCart && this.listProducts) {
      this.listProducts.forEach(element => {
        var cart = this.listCart.find(x => x.productId == element.id);
        if (cart != null) {
          element.isAddedToCart = true;
          element.qty = cart.qty;
        }
        else {
          element.isAddedToCart = false;
          element.qty = 0;
        }
      });
    }
  }

  addToCart(item: ProductVM) {
    $('.csLoader').css({ display: 'flex' });
    var cart = { productId: item.id, qty: 1, userId: "", userUID: "" };
    this.product.addToCart(cart).subscribe(x => {
      $('.csLoader').css({ display: 'none' });
      this.getCurrentCart();
      console.log(x);
    }, err => {
      $('.csLoader').css({ display: 'none' });
      alert("Error");
    });
  }
  updateQty(item: ProductVM, inc: number) {

    var cart = this.listCart.find(x => x.productId == item.id)!;

    $('.csLoader').css({ display: 'flex' });
    cart.qty += inc;
    if (cart.qty <= 0) {
      this.product.deleteCart(cart.id).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
    else {
      this.product.updateCart(cart.id, cart.qty).subscribe(x => {
        $('.csLoader').css({ display: 'none' });
        this.getCurrentCart();
      }, er => {
        $('.csLoader').css({ display: 'none' });
      });
    }
  }

}
