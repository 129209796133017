<div class="container mt-5 mb-10" *ngIf="userModel">
  <div class="row">
    <div class="col-md-4 ">
      <div class="mybox">
        <div style="position: relative;" class="mb-3 text-center">
          <i (click)="loadImage()" [ngClass]="hasUserImage?'hasUserImage':''" class="fa fa-edit"></i>
          <i *ngIf="hasUserImage" (click)="removeImage()" class="fa fa-remove hasUserImage"></i>
          <img style="    border-radius: 50%;margin: 15px;width: 140px; height: 140px;" [src]="profileImage" width="50%"
            height="100%" />
          <h5>{{userModel.firstName}} {{userModel.lastName}}</h5>
          <label style="width: 100%; font-size: 1rem;"><i class="fa fa-phone"></i>
            {{userModel.phoneNumber}}</label>
          <label *ngIf="userModel.email" style="width: 100%;font-size: 1.1rem;"><i style="font-size: 0.9rem;"
              class="fa fa-envelope"></i>
            {{userModel.email}}</label>
        </div>
        <div style="width: 100%; margin-top: 10px;">
          <span *ngIf="!isEditing" class="btn btn-primary mt-3 btn-block" data-toggle="modal"
            data-target="#deActivateMyAccount">De-Register My Self</span>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="mybox">
        <form [ngClass]="!isEditing?'editing':''" class="user-form" #userForm="ngForm" (ngSubmit)="saveData(userForm)">
          <div *ngIf="isEditing" class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="lbl">First Name</label>
                <input [disabled]="!isEditing" id="firstName" name="firstName" #firstName
                  [(ngModel)]="userModel.firstName" class="form-control form-control-sm" required pattern=".{2,}" />
                <span>*</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="lbl">Last Name</label>
                <input [disabled]="!isEditing" id="lastName" name="lastName" #lastName [(ngModel)]="userModel.lastName"
                  class="form-control form-control-sm" required pattern=".{1,}" />
                <span>*</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="lbl">Date of birth</label>
                <input appInputMask [regx]="'int'" [format]="'##/##/####'" data-date-autoclose="true"
                  [disabled]="!isEditing" data-provide="datepicker" data-date-format="mm/dd/yyyy"
                  data-date-end-date="-6570d" id="dateOfBirth" name="dateOfBirth" #dateOfBirth
                  [(ngModel)]="userModel.dateOfBirth" class="form-control form-control-sm agedatepicker" required
                  minlength="10" maxlength="10" />
                <label style="width: 15px;" *ngIf="isEditing" class="agedatepickerIcon" for="dateOfBirth"><i
                    style="float: right;" class="fa fa-calendar"></i></label>
                <span>*</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label style="width: 100%;" class="lbl">Gender</label>
                <label *ngIf="!isEditing"
                  style="width: 100%;color: black; font-size: 1rem; font-weight: 500;margin-top: -6px; display: block;"
                  [innerHtml]="userModel.sex == 0?'Male':'Female'"></label>
                <div *ngIf="isEditing">
                  <input required [disabled]="!isEditing" type="radio" name="sex" id="male" [value]="0"
                    [(ngModel)]="userModel.sex" />
                  <label style="padding-left: 5px;padding-right: 30px;" for="male">Male</label>

                  <input required [disabled]="!isEditing" type="radio" name="sex" id="female" [value]="1"
                    [(ngModel)]="userModel.sex" />
                  <label style="padding-left: 5px;" for="female">Female</label>
                  <span>*</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEditing" class="row">
            <div class="col-md-6">
              <div class="form-check">
                <input type="checkbox" name="allowSMSNotication" id="allowSMSNotication"
                  [(ngModel)]="userModel.allowSMSNotification" class="form-check-input" />
                <label style="  cursor: pointer;" class="form-check-label" for="allowSMSNotication">
                  Allow SMS Notication
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-check">
                <input type="checkbox" name="allowEmailNotication" id="allowEmailNotication"
                  [(ngModel)]="userModel.allowEmailNotification" class="form-check-input" />
                <label style="  cursor: pointer;" class="form-check-label" for="allowEmailNotication">
                  Allow E-mail Notication
                </label>
              </div>
            </div>
          </div>

          <div *ngIf="isEditing" class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="lbl">Mobile Number</label>
                <input [disabled]="!isEditing" id="phoneNumber" type="tel" name="phoneNumber" #phoneNumber
                  [(ngModel)]="userModel.phoneNumber" class="form-control form-control-sm" appPhoneMask required
                  minlength="16" maxlength="17" />
                <span>*</span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label class="lbl">Insurance Card</label>
                <div style="    padding: 0;" class="form-check">
                  <input style="cursor: pointer" type="checkbox" name="haveinsurance" id="haveinsurance" [value]="false"
                    [(ngModel)]="haveinsurance" />
                  <label style="padding-left: 10px; cursor: pointer" for="haveinsurance">I have insurance card</label>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isEditing" style="margin-top: 10px;" class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div [ngClass]="haveinsurance ? '' : 'hide'">
                  <input (change)="fileChangeEvent($event,'insuranceFile')" id="insuranceFile" name="insuranceFiles"
                    type="file" multiple />
                  <small id="insuranceFilesHelpBLock" class="form-text text-muted">
                    To capture a clear and complete photo of the insurance card, make
                    sure your insurance card is flat and well illuminated.
                  </small>
                </div>
              </div>
            </div>
            <div *ngIf="isEditing" class="col-md-6" [ngClass]="haveinsurance ? '' : 'hide'">
              <div style="width: 90px; float: left; margin-right: 5px; position: relative;margin-bottom: 10px;"
                *ngFor="let img of insuranceFileList">
                <img *ngIf="img.type == 3" [src]="img.url" style="width: 100%;" />
                <img *ngIf="img.type == 2" src="../../assets/images/no.jpg" style="width: 100%;" />
                <iframe *ngIf="img.type == 1" [src]="img.url" frameBorder="0" scrolling="auto" height="auto"
                  width="100%"></iframe>
                <!-- <i class="fa fa-remove"
                  style="position: absolute;top: 0;right: 0;padding: 5px;cursor: pointer; color: var(--primary);"></i> -->
              </div>
            </div>
          </div>


          <div *ngIf="isEditing || userModel.ohip" class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="lbl">OHIP Number (Optional)</label>
                <input (change)="onChangeOHIP($event.target)" [disabled]="!isEditing" id="ohip" name="ohip" #ohip
                  appInputMask [format]="'####-###-###-##'" [(ngModel)]="userModel.ohip"
                  class="form-control form-control-sm" maxlength="15" />
              </div>
              <div [ngClass]="isEditing?'show':'hide'">
                <input id="ohipFile" (change)="fileChangeEvent($event,'ohipFile')" name="ohipFiles" type="file"
                  multiple />
              </div>
            </div>
            <div *ngIf="!isEditing" class="col-md-6">
              <div *ngFor="let img of userModel.ohippath">
                <i class="fa fa-image"></i>
              </div>
            </div>

            <div *ngIf="isEditing" class="col-md-6">
              <div style="width: 90px; float: left; margin-right: 5px; position: relative;"
                *ngFor="let img of ohipFileList">
                <img *ngIf="img.type == 3" [src]="img.url" style="width: 100%;" />
                <img *ngIf="img.type == 2" src="../../assets/images/no.jpg" style="width: 100%;" />
                <iframe *ngIf="img.type == 1" [src]="img.url" frameBorder="0" scrolling="auto" height="auto"
                  width="100%"></iframe>
                <!-- <i class="fa fa-remove"
                  style="position: absolute;top: 0;right: 0;padding: 5px;cursor: pointer; color: var(--primary);"></i> -->
              </div>
            </div>

          </div>




          <div *ngIf="isEditing || userModel.preferredPharmacy" class="form-group">
            <label class="lbl">Preferred pharmacy</label>
            <select (ngModelChange)="onChangePharmacy($event)" required class="form-control" [disabled]="!isEditing"
              name="preferredPharmacy" id="preferredPharmacy" [(ngModel)]="userModel.preferredPharmacy">
              <option [selected]="!userModel.preferredPharmacy" value="" disabled>Select your preferred pharmacy
              </option>
              <option *ngFor="let item of pharmacyList" [value]="item.id">{{item.name}}</option>
            </select>
            <i *ngIf="!isEditing"
              style="background-color: #fafafa;position: absolute;right: 0; top: 25px;padding: 10px;"></i>
            <span>*</span>
            <label style="position: relative;" class="lbl" *ngIf="farmacyAddress">{{farmacyAddress}}</label>
          </div>
          <div *ngIf="userForm.form.invalid && userForm.form.touched" class="form-label-group">
            <div style="padding: 4px 10px;" class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>
                <i style="padding-right: 10px;" class="fa fa fa-exclamation-triangle"></i>
              </strong>
              <label style="margin: 0; font-size: 0.9rem;"> Please fill all mandatory fields</label>
            </div>
          </div>
          <div *ngIf="isEditing" class="row">
            <div class="col-md-6">
              <button class="btn btn-primary mt-3 btn-block" type="submit">
                Save Changes
              </button>
            </div>

            <div class="col-md-6">
              <span (click)="isEditing = false" class="btn btn-secondary mt-3 btn-block">
                Cancel
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span *ngIf="!isEditing" class="btn btn-primary mt-3 btn-block" (click)="EditClick()">Edit
                profile</span>
            </div>
            <div class="col-md-6">
              <span *ngIf="!isEditing" class="btn btn-primary mt-3 btn-block" data-toggle="modal"
                data-target="#changePasswordModal">Change
                password</span>
            </div>
          </div>


          <input type="hidden" id="id" name="id" [(ngModel)]="userModel.id" />
        </form>


      </div>
    </div>
  </div>
</div>

<input style="display: none;" id="profileImage" type='file' (change)="fileChangeEvent($event,'profileImage')">




<div class="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="singupSuccessMessage"
  aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="
          margin-bottom: 0 !important;
          padding: 10px !important;
        ">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Change password
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="user-form" #passwordForm="ngForm">
          <div class="row">

            <div class="col-md-12">
              <div class="form-group">
                <input id="oldPassword" type="password" name="oldPassword" [(ngModel)]="passModel.oldPassword"
                  class="form-control form-control-sm" required minlength="8" maxlength="14" />
                <i (click)="Showpassword(passModel.oldPassword,$event.target)"
                  style="position: absolute;  right: 1px; bottom: 2px; padding: 8px; cursor: pointer"
                  class="fa fa-eye"></i>
                <label class="lbl">Enter your current password</label>
                <span>*</span>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">

                <input id="newPassword" type="password" name="newPassword" [(ngModel)]="passModel.newPassword"
                  class="form-control form-control-sm" required minlength="8" maxlength="14" />
                <i (click)="Showpassword(passModel.newPassword,$event.target)"
                  style="position: absolute;  right: 1px; bottom: 2px; padding: 8px; cursor: pointer"
                  class="fa fa-eye"></i> <label class="lbl">Enter your new password</label>
                <span>*</span>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <input id="confirmPassword" type="password" name="confirmPassword" #confirmPassword
                  [(ngModel)]="passModel.confirmPassword" class="form-control form-control-sm" required pattern=".{4,}"
                  validateEqual="newPassword" minlength="8" maxlength="14" />
                <i (click)="Showpassword(passModel.confirmPassword,$event.target)"
                  style="position: absolute;  right: 1px; bottom: 2px; padding: 8px; cursor: pointer"
                  class="fa fa-eye"></i>
                <span>*</span>
                <label class="lbl">Confirm your new password</label>
              </div>
            </div>
            <div class="col-md-12">
              <small style="margin-bottom: 15px;" id="passwordHelpBlock" class="form-text text-muted">
                Your password must be 8-14 characters long and must not contain
                spaces.
              </small>
            </div>
          </div>
          <div *ngIf="resetPasswordError" class="col-md-12">
            <div class="form-label-group">
              <div style="padding: 4px 10px" class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>
                  <i style="padding-right: 10px" class="fa fa fa-exclamation-triangle"></i>
                </strong>
                <label style="margin: 0; font-size: 0.9rem">Some thing went wrong. Please try again.</label>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <button (click)="changePassword(passwordForm)" type="button" class="btn btn-primary">
              Update Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="changePasswordModalSuccess" tabindex="-1" role="dialog"
  aria-labelledby="singupSuccessMessage" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="
          margin-bottom: 0 !important;
          padding: 10px !important;
        ">
        <h5 class="modal-title">
          Success
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Your password has been changed successfully
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-primary">
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deActivateMyAccount" tabindex="-1" role="dialog" aria-labelledby="deActivateMyAccount"
  aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="
          margin-bottom: 0 !important;
          padding: 10px !important;
        ">
        <h5 class="modal-title">
          Confirmation
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to de-register yourself? All of your personal data will be removed permanently from our system within 30 days.
      </div>
      <div class="modal-footer">
        <button type="button" on-click="deRegister()" class="btn btn-primary" data-dismiss="modal">
          Yes
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="deRegisterSuccess" tabindex="-1" role="dialog" aria-labelledby="deRegisterSuccess"
  aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="
          margin-bottom: 0 !important;
          padding: 10px !important;
        ">
        <h5 class="modal-title">
          Information
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{deRegisterMsg}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>