import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { GlobalConstants } from '../shared/global-constants';
declare var $: any;
@Component({
  selector: 'app-family-members',
  templateUrl: './family-members.component.html',
  styleUrls: ['./family-members.component.scss']
})
export class FamilyMembersComponent implements OnInit {

  constructor(private auth: AuthenticationService) { }

  currentUser = GlobalConstants.getcurrentUser();
  listUser = null;
  ngOnInit(): void {
    // $('#addNewMemberModal').modal('toggle');
    this.getData();
  }
  private getData() {
    this.auth.findByParentId(GlobalConstants.getcurrentUser()?.id).subscribe(x => {
      console.log(x);
      this.listUser = x;
    });
  }

  dataSavedEvent(data: any) {
    this.getData();
    $('#addNewMemberModal').modal('toggle');
    this.newUser = null;
  }
  newUser: any;
  addUser(user: any) {
    if (user == null) {
      this.newUser = {
        id: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        email: "",
        phoneNumber: "",
        token: "",
        dateOfBirth: "",
        sex: 0,
        preferredPharmacy: this.currentUser?.preferredPharmacy,
        ohip: "",
        allowSMSNotification: true,
        allowEmailNotification: true,
        parentId: this.currentUser?.id
      }
    }
    else {
      this.newUser = user;
    }
    setTimeout(() => {
      $('#addNewMemberModal').modal('toggle');
    }, 100);
  }

}
