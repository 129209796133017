import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebQueryService {

  public WEBQUERY_API = environment.baseUrl  + '/webquery';
  constructor(private http: HttpClient) { }

  save(webQuery: any): Observable<any> {
    let result: Observable<any>;
    result = this.http.post<any>(this.WEBQUERY_API, webQuery);
    
    return result;
  }

}
