<div class="container">
    <div class="row" style="margin-top: 30px;" *ngIf="listCart.length > 0">
        <div class="col-md-12">
            <h2 class="chkPr">My Cart<button [routerLink]="['/checkout']"
                    style="width: 50%; max-width: 220px; float: right;margin-bottom: 20px;"
                    class="btn btn-primary mr-1">Checkout</button></h2>

        </div>
    </div>
    <div class="row" *ngIf="listCart.length == 0">
        <a style="margin: 0 auto; padding: 50px;" [routerLink]="['/our-products']">Your cart is empty. Shop now</a>
    </div>
    <div class="row" *ngIf="listCart.length > 0">
        <div *ngFor="let item of listCart" style="margin-top: 10px; padding: 0; 
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;" class="col-md-12">
            <div style="width: 100%; background: #fdfdfd; padding: 10px; display: table;">
                <div class="prImage">
                    <img style="width: 100%;" src="{{item.product.productImages[0]}}" />
                </div>

                <div class="myProduct">
                    <div style="float: left; margin-top: 0;" class="pQty">
                        <span (click)="updateQty(item.product,-1)"
                            style="border-bottom-right-radius: 0; border-top-right-radius: 0;">-</span>
                        <label>{{item.qty}}</label>
                        <span (click)="updateQty(item.product,1)"
                            style="border-top-left-radius: 0;border-bottom-left-radius: 0;">+</span>
                    </div>
                    <span
                        style="float: left; margin-left: 10px;">{{(item.qty*item.product.discountPrice)|currency}}</span>
                    <span (click)="updateQty(item.product,(-1 * item.qty))" class="pRem fa fa-close"></span>
                    <label class="cpName form-control-sm">{{item.product.productName}} <span
                            style="color:var(--primary)">@ {{item.product.discountPrice|currency}} </span></label>
                </div>
                <p class="pDesc" [innerHTML]="item.product.longDesc"></p>

            </div>
        </div>
        <label style="margin-bottom:0; border: none;    color: #302f2f; font-size: 1rem" class="ttlAmount">
            <span>Sub Total :</span> <span
                style="margin-left: 15px; font-weight: 500; font-size: 1rem;    width: 120px;
                display: inline-block;">{{subtotal|currency}}</span>
        </label>
        <label  style="margin:0; border: none;   color: #302f2f; font-size: 1rem"  class="ttlAmount">
            <span>{{taxRate}}% HST :</span> <span
                style="margin-left: 15px; font-weight: 500; font-size: 1rem;    width: 120px;
                display: inline-block;">{{taxtAmount|currency}}</span>
        </label>
        <label  style="margin:0; border: none;   color: #302f2f; font-size: 1rem"  class="ttlAmount">
            <span>Delivery Charges :</span> <span
                style="margin-left: 15px; font-weight: 500; font-size: 1rem;    width: 120px;
                display: inline-block;">{{deliveryCharge|currency}}</span>
        </label>
        <label style="margin-top:0" class="ttlAmount">
            <b>Total Amount :</b> <span
                style="margin-left: 15px; font-weight: 500; font-size: 1.2rem;    width: 120px;
                display: inline-block;">{{totalAmount|currency}}</span>
        </label>
        <div class="col-md-12" style="    padding: 0;">
            <button [routerLink]="['/checkout']" style="width: 220px; float: right;margin-bottom: 20px;"
                class="btn btn-primary mr-1">Checkout</button>
        </div>
    </div>
</div>